export const inboxes = {
  all: {
    label: "All messages",
    filters: [
      {
        path: "guest_tags",
        value: "archived",
        operator: "!=",
      },
    ],
  },
  inquiry: {
    label: "Inquiries",
    filters: [
      {
        path: "guest_tags",
        value: "inquiry",
        operator: "==",
      },
      {
        path: "guest_tags",
        value: "archived",
        operator: "!=",
      },
    ],
    total_count: 0,
  },
  priority: {
    label: "Priority",
    filters: [
      {
        path: "guest_tags",
        value: "priority",
        operator: "==",
      },
      {
        path: "guest_tags",
        value: "archived",
        operator: "!=",
      },
    ],
    total_count: 0,
  },
  guest: {
    label: "Reservations",
    filters: [
      {
        path: "guest_type",
        value: "guest",
        operator: "==",
      },
      {
        path: "guest_bookings",
        value: 0,
        operator: ">",
      },
      {
        path: "guest_tags",
        value: "archived",
        operator: "!=",
      },
    ],
    total_count: 0,
  },
  owner: {
    label: "Owners",
    filters: [
      {
        path: "guest_type",
        value: "owner",
        operator: "==",
      },
      {
        path: "guest_tags",
        value: "archived",
        operator: "!=",
      },
    ],
    total_count: 0,
  },
  maintenance: {
    label: "Maintenance",
    filters: [
      {
        path: "guest_type",
        value: "maintenance",
        operator: "==",
      },
      {
        path: "guest_tags",
        value: "archived",
        operator: "!=",
      },
    ],
    total_count: 0,
  },
  cleaner: {
    label: "Cleaners",
    filters: [
      {
        path: "guest_type",
        value: "cleaner",
        operator: "==",
      },
      {
        path: "guest_tags",
        value: "archived",
        operator: "!=",
      },
    ],
    total_count: 0,
  },
  archived: {
    label: "Archived",
    filters: [
      {
        path: "guest_tags",
        value: "archived",
        operator: "==",
      },
    ],
    total_count: 0,
  },
};

export const scopeLabels = {
  reports: {
    hide: "Hide reports",
    read: "View reports",
    write: "View & edit reports",
  },
  crm: {
    hide: "Hide CRM page",
    read: "View CRM page",
    write: "View CRM page & send campaigns",
  },
  listings: {
    hide: "View listing content in messaging page only",
    read: "View listing content in messaging & listings page",
    write: "View & edit listing content",
  },
  accounts: {
    hide: "Hide users page",
    read: "View & invite new users",
    write: "View, invite & delete users & manage user permissions",
  },
  messaging: {
    hide: "Hide messaging page",
    read: "View messaging page",
    write: "View & send messages",
  },
  smart_devices: {
    hide: "View smart devices",
    write: "View & manage smart devices",
  },
  billing: {
    hide: "Hide billing",
    write: "Manage billing & enable addon subscriptions",
  },
};

export const experienceComponentEnum = {
  contact: "contact",
  branding: "branding",
  boarding_pass: "boarding_pass",
  upsells: "upsells",
  fees: "fees",
  local_services: "local_services",
  experiences: "experiences",
  checkin_steps: "checkin_steps",
  checkout_steps: "checkout_steps",
  wifi: "wifi",
  guidebooks: "guidebooks",
};

export const fieldEnum = {
  device_id: "device_id",
  notification_type: "notification_type",
  sent: "sent",
  booking_post_stay_gap: "booking_post_stay_gap",
  booking_star_rating: "booking_star_rating",
  booking_created_at: "booking_created_at",
  booking_action: "booking_action",
  booking_channel: "booking_channel",
  booking_status: "booking_status",
  booking_payout: "booking_payout",
  booking_total_guest_paid: "total_guest_paid",
  booking_payment_currency: "booking_payment_currency",
  booking_payment_status: "booking_payment_status",
  booking_payment_amount_remaining: "booking_payment_amount_remaining",
  booking_nightly_rate: "booking_nightly_rate",
  booking_listing: "booking_listing",
  booking_listing_name: "booking_listing_name",
  booking_guest_name: "booking_guest_name",
  booking_tags: "booking_tags",
  booking_checkin_dt: "booking_checkin_dt",
  booking_checkout_dt: "booking_checkout_dt",
  booking_lead_time: "booking_lead_time",
  booking_time_until_checkin: "time_until_checkin",
  booking_time_until_checkout: "time_until_checkout",
  booking_purchased_upsells: "booking_purchased_upsells",
  booking_purchased_fees: "booking_purchased_fees",
  checkin_date: "checkin_date",
  checkout_date: "checkout_date",
  checkin_time: "checkin_time",
  checkout_time: "checkout_time",
  booking_id: "booking_id",
  integration_booking_id: "integration_booking_id",
  keycard_link: "keycard_link",
  keycard_code: "keycard_code",
  total_people_booked: "total_people_booked",
  total_revenue_by_guest: "total_rev",
  num_nights: "num_nights",
  num_adult: "num_adult",
  num_child: "num_child",
  num_pets: "num_pets",
  booking_listing_group_id: "booking_listing_group_id",
  month: "month",
  time_of_day: "time_of_day",
  guest_group_id: "guest_group_id",
  guest_updated_at: "guest_updated_at",
  guest_integration: "guest_integration",
  guest_stage: "guest_stage",
  guest_bookings: "guest_bookings",
  guest_sentiment: "rating",
  guest_inquiry: "guest_inquiry",
  guest_id: "guest_id",
  guest_language: "guest_language",
  guest_channel: "guest_channel",
  guest_destination: "guest_destination",
  guest_listing: "guest_listing",
  guest_country: "guest_country",
  guest_location: "guest_location",
  guest_status: "guest_status",
  guest_dob: "guest_dob",
  guest_age: "guest_age",
  guest_email: "guest_email",
  guest_travel_reason: "guest_travel_reason",
  guest_address: "guest_address",
  guest_first_name: "guest_first_name",
  guest_last_name: "guest_last_name",
  guest_tags: "guest_tags",
  guest_name: "guest_name",
  guest_phone: "guest_phone",
  reply_phone: "reply_phone",
  user_name: "reply_phone",
  assigned_to: "assigned_to",
  unanswered_messages: "unanswered_messages",
  unread_messages: "unread_messages",
  guest_type: "guest_type",
  guest_ai_summary: "guest_ai_summary",
  guest_purchased_product_types: "guest_purchased_product_types",
  guest_purchased_product_names: "guest_purchased_product_names",
  guest_enso_total_revenue: "enso_total_revenue",
  guest_bill_title: "guest_bill_title",
  guest_bill_total: "guest_bill_total",
  guest_bill_session_id: "guest_bill_session_id",
  guest_bill_created_at: "guest_bill_created_at",
  guest_bill_updated_at: "guest_bill_updated_at",
  guest_bill_booking_id: "guest_bill_booking_id",
  guest_bill_listing_group_id: "guest_bill_listing_group_id",
  guest_bill_listing_resource: "guest_bill_listing_resource",
  guest_bill_product_type: "guest_bill_product_type",
  guest_bill_currency: "guest_bill_currency",
  guest_bill_status: "guest_bill_status",
  message_channel: "message_channel",
  message_sent_at: "message_sent_at",
  message_sender: "message_sender",
  message_topics: "message_topics",
  guest_selfie: "guest_selfie",
  guest_govt_id_front: "guest_govt_id_front",
  guest_govt_id_back: "guest_govt_id_back",
  guest_other_id: "guest_other_id",
  deposit_transaction_id: "deposit",
  fee_transaction_id: "fee",
  pet_transaction_id: "pet_fee",
  listing_id: "listing_id",
  listing_checkin_time: "listing_checkin_time",
  listing_checkout_time: "listing_checkout_time",
  listing_type: "listing_type",
  listing_channels: "listing_channels",
  listing_active: "listing_active",
  listing_name: "listing_name",
  listing_nickname: "listing_nickname",
  listing_address: "listing_address",
  listing_currency: "listing_currency",
  listing_longitude: "listing_longitude",
  listing_latitude: "listing_latitude",
  listing_state: "listing_state",
  listing_city: "listing_city",
  listing_photo: "listing_photo",
  listing_street: "listing_street",
  listing_country: "country",
  listing_rules: "listing_rules",
  listing_num_bathrooms: "listing_num_bathrooms",
  listing_num_bedrooms: "listing_num_bedrooms",
  listing_tags: "listing_tags",
  listing_group_id: "listing_group_id",
  listing_errors: "listing_errors",
  checkin_directions: "checkin_directions",
  checkout_directions: "checkout_directions",
  smoking_policy: "smoking_policy",
  max_kids: "max_kids",
  max_adults: "max_adults",
  zip_code: "zip_code",
  wifi_network: "wifi_network",
  wifi_password: "wifi_password",
  pet_policy: "pet_policy",
  sort_id: "sort_id",
  branding_logo: "branding_logo",
  listing_keycard_link: "listing_keycard_link",
  question_type: "question_type",
  lock_access_code: "lock_access_code",
  message: "message",
  sent_at: "sent_at",
  delivered: "delivered",
  payment: "payment",
  checkin_raw: "checkin_raw",
  checkout_raw: "checkout_raw",
  response_time: "response_time",
  num_messages: "num_messages",
  sentiment: "sentiment",
  attachments: "attachments",
  notification_message: "notification_message",
  viewed: "viewed",
  is_error: "is_error",
  device_trigger: "device_trigger",
  num_listings: "num_listings",
  message_stage_tags: "message_stage_tags",
  messages_guest_id: "messages_guest_id",
  start: "start",
  page: "page",
  guest_dob_dt: "guest_dob_dt",
};

export const ErrorSeverityEnum = {
  info: "info",
  warning: "warning",
  critical: "critical",
};

export const metricEnum = {
  bookings: "bookings",
  booking_status: "booking_status",
  booking_channel: "booking_channel",
  booking_revenue: "revenue",
  guests: "guests",
  guest_stage: "guest_stage",
  guest_ratings: "guest_ratings",
  messages: "messages",
  question_type: "question_type",
  message_automation: "message_automation",
  message_channel: "message_channel",
  sentiment: "sentiment",
  time_saved: "time_saved",
  response_time: "response_time",
  recurrent_booking: "recurrent_booking",
  multi_guest_booking: "multi_guest_booking",
  top_revenue_guest: "top_revenue_guest",
  inquiry: "inquiry",
  multiple_inquiry: "multiple_inquiry",
  guest_demographics: "guest_demographics",
  multiple_listings_visited: "multiple_listings_visited",
  notification: "notification",
  conversion_from_inquiry: "conversion_from_inquiry",
  messages_with_inquiry: "messages_with_inquiry",
  booking_from_inquiry: "booking_from_inquiry",
};

export const contentTypeEnum = {
  guidebook: "guidebook",
  upsell: "upsell",
  fee: "fee",
  checkin_step: "checkin_step",
  checkout_step: "checkout_step",
  experience: "experience",
  agreement: "agreement",
  refine_chat: "refine_chat",
  google_guidebook: "google_guidebook",
  guidebook_components: "guidebook_components",
};

export const channelIntegrationTypeEnum = {
  leavetown_airbnb: "ab",
  airbnb: "abnb",
  vrbo: "vrbo",
  guesty: "gy",
  bookingsync: "bks",
  mews: "mews",
  hostaway: "ha",
  rentalsunited: "ru",
  beds24: "b24",
  booking_automation: "ba",
  cloudbeds: "cb",
  hostfully: "hfl",
  inforhms: "ifr",
  impala: "impl",
  lavanda: "lvnd",
  myvr: "myvr",
  opera: "opra",
  smartbnb: "smrt",
  stayntouch: "stay",
  streamline: "st",
  track: "trk",
  lodgify: "lg",
  lmpm: "lmpm",
  leavetown: "lt",
  hostify: "htf",
  custom: "cs",
  ownerrez: "ownrz",
  v365: "v365",
  avantio: "avt",
};

export const channelIntegrationList = [
  {
    integration_type: "gy",
    name: "guesty",
    provider: "gy",
    service: "pms",
    title: "Guesty",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/guesty.svg",
    signup_type: "api_key",
    signup_fields: {
      gy_jwt_token: {
        name: "Guesty Access Token",
      },
    },
    details:
      "Your Guesty account allows you to manage your Guesty guests & listings in Enso Connect.",
    experience_name: "guesty",
    signup_text:
      '\nYou can connect with your Guesty account and import all of your listings (Rooms) and bookings (Reservations).\nFirst you must connect EnsoConnect\'s app in <a href="https://www.guesty.com" target="_blank">Guesty\'s markeplace</a>.\nOnce it\'s connected, go to <b>Marketplace</b> and find <b>EnsoConnect</b>, then click <b>it</b>.\nIn the top right corner you will see an <b>API Key rectangle</b>, that contains your <b>Access token</b>.\nCopy and paste the token in the following field:        \n',
  },
  {
    integration_type: "ha",
    name: "hostaway",
    provider: "ha",
    service: "pms",
    title: "Hostaway",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/hostaway.svg",
    signup_type: "api_key",
    signup_fields: {
      ha_client_secret: {
        name: "Hostaway API Key",
      },
      ha_host_id: {
        name: "Hostaway Account ID",
      },
    },
    details:
      "Your Hostaway account allows you to manage your Hostaway guests & listings in Enso Connect.",
    signup_text:
      '\nYou can connect with your Hostaway account and import all of your listings (Rooms) and bookings (Reservations).\nFirst you must connect EnsoConnect\'s app in <a href="https://dashboard.hostaway.com/" target="_blank">Hostaway\'s markeplace</a>.\nOnce it\'s connected, go to <b>Settings/Hostaway API</b> and click on the <b>Create</b> Button.\nIn the name field put <b>EnsoConnect</b> and click <b>Create</bAT\nCopy and paste both <b>Account ID</b> and <b>API Key</b> into the corresponding following fields \n        ',
  },
  {
    integration_type: "trk",
    name: "track",
    provider: "trk",
    service: "pms",
    title: "Track",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/track_logo.svg",
    signup_type: "api_key",
    signup_fields: {
      trk_customer_domain: {
        name: "Track Customer Domain",
      },
      trk_server_key: {
        name: "Track Server Key",
      },
      trk_secret_key: {
        name: "Track Secret Key",
      },
    },
    details:
      "Your Track account allows you to manage your Track guests & listings in Enso Connect.",
    signup_text:
      '\nYou can connect with your TRACK account and import all of your listings (Units) and bookings (Reservations).\nCopy the URL you use to sign-into to your TRACK account and append it with "/api/" (e.g., https://*test*.trackhs.com/api/). \nEnter it in "Track Customer Domain." Then in your TRACK dashboard, go to your Configuration page \n(the gear icon in the top right of the screen) and under the Company Setup tab, click API keys. \nThen create a new API key, copy the key and secret and paste them here.\n<strong>IMPORTANT: Channel keys are not supported</strong>.\n',
  },
  {
    integration_type: "mews",
    name: "mews",
    provider: "mews",
    service: "pms",
    title: "Mews",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/mews-logo.png",
    signup_type: "api_key",
    signup_fields: {
      mews_access_token: {
        name: "Mews Access Token",
      },
    },
    details:
      "Your Mews account allows you to manage your Mews guests & listings in Enso Connect.",
    signup_text:
      '\nYou can connect with your Mews account and import all of your listings (Rooms) and bookings (Reservations).\nFirst you must install EnsoConnect\'s app in <a href="https://www.mews.com/en/products/marketplace" target="_blank">Mews\' marketplace</a>.\nOnce it\'s installed, go to <b>Marketplace &gt; My Subscriptions</b> and find <b>EnsoConnect</b>, then click <b>Edit</b>.\nIn the top right corner you will see a <b>Key Icon</b>, click on it to reveal your <b>Access token</b>.\nCopy and paste the token in the following field: \n        ',
  },
  {
    integration_type: "bks",
    name: "bookingsync",
    provider: "bks",
    service: "pms",
    title: "Bookingsync",
    details:
      "Your Bookingsync account allows you to manage your Bookingsync guests & listings in Enso Connect.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/bookingsync.svg",
    signup_type: "redirect",
  },
  {
    integration_type: "lmpm",
    name: "lmpm",
    service: "pms",
    title: "LMPM",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/lmpm_logo.svg",
    signup_type: "api_key",
    signup_fields: {
      lmpm_company_id: {
        name: "LMPM Company ID",
      },
    },
    details:
      "Your Lmpm account allows you to manage your Lmpm guests & listings in Enso Connect.",
    signup_text:
      "\nConnect your LMPM account by enabling the <b>Enso {\"integration_type\": Integration<b> in the integrations section of your <b>LMPM Dashboard<b> \n(click the 3 bars at the top right then click 'Settings' > '{\"integration_type\": Integrations') and provide us your <b>Company ID<b>\n        ",
  },
  {
    integration_type: "lg",
    name: "lodgify",
    service: "pms",
    title: "Lodgify",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/lodgify.png",
    signup_type: "api_key",
    signup_fields: {
      api_key: {
        name: "Lodgify API Key",
      },
    },
    details:
      "Your Lodgify account allows you to manage your Lodgify guests & listings in Enso Connect.",
    signup_text:
      "\nYou can connect with your Lodgify account and import all of your listings (Rooms) and bookings (Reservations).\nLog into Lodgify, go to <b>Settings &gt; Public API</b> and find the <b>Personal API key</b>.\nCopy and paste this into the below field: \n        ",
  },
  {
    integration_type: "hfl",
    name: "hostfully",
    service: "pms",
    title: "Hostfully",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/hostfully_logo.png",
    signup_type: "api_key",
    signup_fields: {
      hostfully_agency_uid: {
        name: "Hostfully Agency UID",
      },
    },
    details:
      "Your Hostfully account allows you to manage your Hostfully guests & listings in Enso Connect.",
    signup_text:
      "\nYou can connect with your Hostfully account and import all of your listings (Properties) and bookings (Leads).\nLog into Hostfully, go to <b>{\"integration_type\": Integrations</b> and find the <b>Enso Connect</b> integration and enable it.\nNext, <b>select</b> all the Hostfully listings you would like synced and select the <b>enable</b> option.\nOnce complete, please paste your Hostfully <a target='_blank' href='https://help.hostfully.com/en/articles/4112286-find-your-agency-uid'>Agency UID</a> into the below field: \n        ",
  },
  {
    integration_type: "st",
    sleeptime_milliseconds: 250,
    name: "streamline",
    service: "pms",
    title: "Streamline",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/streamline-logo.png",
    details:
      "Your Streamline account allows you to manage your Streamline guests & listings in Enso Connect.",
    signup_text:
      '\nConnect with your Streamline account and import all of your listings (Properties) and bookings (Reservations).\nFirst, find the token key and secret key in Streamline by looking under the Third Party {"integration_type": Integrations section in the Main Menu on Streamline. \nOnce you click "Self-Sevice Onboarding" then you will need to click "Get Tokens for Enso Connect". Please fill these in below.\n        ',
    signup_fields: {
      streamline_token_key: {
        name: "Streamline token key",
      },
      streamline_token_secret: {
        name: "Streamline token secret",
      },
    },
    signup_type: "api_key",
  },
  {
    integration_type: "htf",
    name: "hostify",
    service: "pms",
    title: "Hostify",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/hostify-logo.png",
    signup_type: "api_key",
    signup_fields: {
      api_key: {
        name: "Hostify API Key",
      },
    },
    details:
      "Your Hostify account allows you to manage your Hostify guests & listings in Enso Connect.",
    signup_text:
      '\n        <img width="100%" src="https://enso-image-assets.s3.amazonaws.com/brandicons/hostify-screenshot.png" alt="api key location">\nYou can connect with your Hostify account and import all of your listings and bookings (Reservations).\nLog into Hostify, go to <b>Settings</b> and find the <b>API key</b>.\nCopy and paste this into the below field: \n        ',
  },
  {
    integration_type: "ru",
    name: "rentalsunited",
    service: "pms",
    title: "Rentals United",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/rentalsunited.svg",
    signup_type: "api_key",
    signup_fields: {
      username: {
        name: "Rentals United User Name",
      },
    },
    signup_text:
      "\nYou can connect with your Rentals United account and import all of your listings and bookings (Reservations) by entering your username/email below.\n        ",
  },
  {
    integration_type: "b24",
    name: "beds24",
    service: "pms",
    title: "Beds24",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/beds24-logo.png",
    signup_type: null,
  },
  {
    integration_type: "ba",
    name: "booking_automation",
    service: "pms",
    title: "Booking Automation",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/booking-automation-logo.png",
    signup_type: null,
  },
  {
    integration_type: "cb",
    name: "cloudbeds",
    service: "pms",
    title: "Cloudbeds",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/cloudbeds-logo.png",
    signup_type: null,
  },
  {
    integration_type: "ifr",
    name: "inforhms",
    service: "pms",
    title: "Infor HMS",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/infor-logo.png",
    signup_type: null,
  },
  {
    integration_type: "myvr",
    name: "myvr",
    service: "pms",
    title: "MyVR",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/myvr-logo.png",
    signup_type: null,
  },
  {
    integration_type: "opra",
    name: "opera",
    service: "pms",
    title: "Oracle Opera",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/oracle-logo.png",
    signup_type: null,
  },
  {
    integration_type: "stay",
    name: "stayntouch",
    service: "pms",
    title: "StayNTouch",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/stayntouch-logo.png",
    signup_type: null,
  },
  {
    integration_type: "vrbo",
    name: "vrbo",
    service: "pms",
    title: "VRBO",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/vrbo.svg",
    signup_type: null,
  },
  {
    integration_type: "ab",
    name: "leavetown",
    service: "pms",
    title: "Leavetown AirBnB",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/airbnb.svg",
    signup_type: null,
  },
  {
    integration_type: "abnb",
    name: "airbnb",
    service: "pms",
    title: "AirBnB",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/airbnb.svg",
    signup_type: null,
  },
  {
    integration_type: "ownrz",
    name: "ownerrez",
    service: "pms",
    title: "OwnerRez",
    sleeptime_milliseconds: 4000,
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/ownerrez_title_logo.webp",
    details:
      "Your Ownerrez account allows you to manage your Ownerrez guests & listings in Enso Connect.",
    signup_type: "redirect",
  },
  {
    integration_type: "v365",
    service: "pms",
    title: "365 Villas",
    name: "365v",
    icon: "https://secure.365villas.com/images/logo/4.0.365villas.logo.svg",
    signup_type: "api_key",
    signup_fields: {
      key: {
        name: "365 Villas API Key",
      },
      pass: {
        name: "365 Villas API Pass",
      },
      owner_token: {
        name: "365 Owner Token",
      },
    },
    signup_text:
      "\n        To add your 365 Villas account to Enso Connect please enter your 365 Villas Credentials. To obtain these\n        first login to 365 Villas Portal. In the top right corner, click your account name acronym.\n        \n        <img src='https://enso-image-assets.s3.amazonaws.com/brandicons/365villa-home.png' width='100%' />\n        \n        Then click \"API & Integrations\". Here you will find the credentials you need.\n        \n        <img src='https://enso-image-assets.s3.amazonaws.com/brandicons/365villa-credentials.png' height=\"250px\" style=\"display: block; margin-left: auto; margin-right: auto;\" />\n        ",
  },
  {
    integration_type: "avt",
    service: "pms",
    title: "Avantio",
    name: "avantio",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/avantio.webp",
    signup_type: "api_key",
    signup_fields: {
      avt_provider_id: {
        name: "Avantio Provider ID",
      },
      avt_username: {
        name: "Avantio Username",
      },
      avt_password: {
        name: "Avantio Password",
      },
    },
    details:
      "Your Avantio account allows you to manage your Avantio guests & listings in Enso Connect.",
    signup_text:
      "\n        Please raise a ticket with the Customer Care team on Avantio and ask for a Provider ID, username and password.\n        After you have received these details, please enter them in the fields below.\n        ",
    delete_text: "",
  },
];

export const channelIntegrationTypeMap = {
  gy: {
    integration_type: "gy",
    name: "guesty",
    provider: "gy",
    service: "pms",
    title: "Guesty",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/guesty.svg",
    signup_type: "api_key",
    signup_fields: {
      gy_jwt_token: {
        name: "Guesty Access Token",
      },
    },
    details:
      "Your Guesty account allows you to manage your Guesty guests & listings in Enso Connect.",
    experience_name: "guesty",
    signup_text:
      '\nYou can connect with your Guesty account and import all of your listings (Rooms) and bookings (Reservations).\nFirst you must connect EnsoConnect\'s app in <a href="https://www.guesty.com" target="_blank">Guesty\'s markeplace</a>.\nOnce it\'s connected, go to <b>Marketplace</b> and find <b>EnsoConnect</b>, then click <b>it</b>.\nIn the top right corner you will see an <b>API Key rectangle</b>, that contains your <b>Access token</b>.\nCopy and paste the token in the following field:        \n',
  },
  ha: {
    integration_type: "ha",
    name: "hostaway",
    provider: "ha",
    service: "pms",
    title: "Hostaway",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/hostaway.svg",
    signup_type: "api_key",
    signup_fields: {
      ha_client_secret: {
        name: "Hostaway API Key",
      },
      ha_host_id: {
        name: "Hostaway Account ID",
      },
    },
    details:
      "Your Hostaway account allows you to manage your Hostaway guests & listings in Enso Connect.",
    signup_text:
      '\nYou can connect with your Hostaway account and import all of your listings (Rooms) and bookings (Reservations).\nFirst you must connect EnsoConnect\'s app in <a href="https://dashboard.hostaway.com/" target="_blank">Hostaway\'s markeplace</a>.\nOnce it\'s connected, go to <b>Settings/Hostaway API</b> and click on the <b>Create</b> Button.\nIn the name field put <b>EnsoConnect</b> and click <b>Create</bAT\nCopy and paste both <b>Account ID</b> and <b>API Key</b> into the corresponding following fields \n        ',
  },
  trk: {
    integration_type: "trk",
    name: "track",
    provider: "trk",
    service: "pms",
    title: "Track",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/track_logo.svg",
    signup_type: "api_key",
    signup_fields: {
      trk_customer_domain: {
        name: "Track Customer Domain",
      },
      trk_server_key: {
        name: "Track Server Key",
      },
      trk_secret_key: {
        name: "Track Secret Key",
      },
    },
    details:
      "Your Track account allows you to manage your Track guests & listings in Enso Connect.",
    signup_text:
      '\nYou can connect with your TRACK account and import all of your listings (Units) and bookings (Reservations).\nCopy the URL you use to sign-into to your TRACK account and append it with "/api/" (e.g., https://*test*.trackhs.com/api/). \nEnter it in "Track Customer Domain." Then in your TRACK dashboard, go to your Configuration page \n(the gear icon in the top right of the screen) and under the Company Setup tab, click API keys. \nThen create a new API key, copy the key and secret and paste them here.\n<strong>IMPORTANT: Channel keys are not supported</strong>.\n',
  },
  mews: {
    integration_type: "mews",
    name: "mews",
    provider: "mews",
    service: "pms",
    title: "Mews",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/mews-logo.png",
    signup_type: "api_key",
    signup_fields: {
      mews_access_token: {
        name: "Mews Access Token",
      },
    },
    details:
      "Your Mews account allows you to manage your Mews guests & listings in Enso Connect.",
    signup_text:
      '\nYou can connect with your Mews account and import all of your listings (Rooms) and bookings (Reservations).\nFirst you must install EnsoConnect\'s app in <a href="https://www.mews.com/en/products/marketplace" target="_blank">Mews\' marketplace</a>.\nOnce it\'s installed, go to <b>Marketplace &gt; My Subscriptions</b> and find <b>EnsoConnect</b>, then click <b>Edit</b>.\nIn the top right corner you will see a <b>Key Icon</b>, click on it to reveal your <b>Access token</b>.\nCopy and paste the token in the following field: \n        ',
  },
  bks: {
    integration_type: "bks",
    name: "bookingsync",
    provider: "bks",
    service: "pms",
    title: "Bookingsync",
    details:
      "Your Bookingsync account allows you to manage your Bookingsync guests & listings in Enso Connect.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/bookingsync.svg",
    signup_type: "redirect",
  },
  lmpm: {
    integration_type: "lmpm",
    name: "lmpm",
    service: "pms",
    title: "LMPM",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/lmpm_logo.svg",
    signup_type: "api_key",
    signup_fields: {
      lmpm_company_id: {
        name: "LMPM Company ID",
      },
    },
    details:
      "Your Lmpm account allows you to manage your Lmpm guests & listings in Enso Connect.",
    signup_text:
      "\nConnect your LMPM account by enabling the <b>Enso {\"integration_type\": Integration<b> in the integrations section of your <b>LMPM Dashboard<b> \n(click the 3 bars at the top right then click 'Settings' > '{\"integration_type\": Integrations') and provide us your <b>Company ID<b>\n        ",
  },
  lg: {
    integration_type: "lg",
    name: "lodgify",
    service: "pms",
    title: "Lodgify",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/lodgify.png",
    signup_type: "api_key",
    signup_fields: {
      api_key: {
        name: "Lodgify API Key",
      },
    },
    details:
      "Your Lodgify account allows you to manage your Lodgify guests & listings in Enso Connect.",
    signup_text:
      "\nYou can connect with your Lodgify account and import all of your listings (Rooms) and bookings (Reservations).\nLog into Lodgify, go to <b>Settings &gt; Public API</b> and find the <b>Personal API key</b>.\nCopy and paste this into the below field: \n        ",
  },
  hfl: {
    integration_type: "hfl",
    name: "hostfully",
    service: "pms",
    title: "Hostfully",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/hostfully_logo.png",
    signup_type: "api_key",
    signup_fields: {
      hostfully_agency_uid: {
        name: "Hostfully Agency UID",
      },
    },
    details:
      "Your Hostfully account allows you to manage your Hostfully guests & listings in Enso Connect.",
    signup_text:
      "\nYou can connect with your Hostfully account and import all of your listings (Properties) and bookings (Leads).\nLog into Hostfully, go to <b>{\"integration_type\": Integrations</b> and find the <b>Enso Connect</b> integration and enable it.\nNext, <b>select</b> all the Hostfully listings you would like synced and select the <b>enable</b> option.\nOnce complete, please paste your Hostfully <a target='_blank' href='https://help.hostfully.com/en/articles/4112286-find-your-agency-uid'>Agency UID</a> into the below field: \n        ",
  },
  st: {
    integration_type: "st",
    sleeptime_milliseconds: 250,
    name: "streamline",
    service: "pms",
    title: "Streamline",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/streamline-logo.png",
    details:
      "Your Streamline account allows you to manage your Streamline guests & listings in Enso Connect.",
    signup_text:
      '\nConnect with your Streamline account and import all of your listings (Properties) and bookings (Reservations).\nFirst, find the token key and secret key in Streamline by looking under the Third Party {"integration_type": Integrations section in the Main Menu on Streamline. \nOnce you click "Self-Sevice Onboarding" then you will need to click "Get Tokens for Enso Connect". Please fill these in below.\n        ',
    signup_fields: {
      streamline_token_key: {
        name: "Streamline token key",
      },
      streamline_token_secret: {
        name: "Streamline token secret",
      },
    },
    signup_type: "api_key",
  },
  htf: {
    integration_type: "htf",
    name: "hostify",
    service: "pms",
    title: "Hostify",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/hostify-logo.png",
    signup_type: "api_key",
    signup_fields: {
      api_key: {
        name: "Hostify API Key",
      },
    },
    details:
      "Your Hostify account allows you to manage your Hostify guests & listings in Enso Connect.",
    signup_text:
      '\n        <img width="100%" src="https://enso-image-assets.s3.amazonaws.com/brandicons/hostify-screenshot.png" alt="api key location">\nYou can connect with your Hostify account and import all of your listings and bookings (Reservations).\nLog into Hostify, go to <b>Settings</b> and find the <b>API key</b>.\nCopy and paste this into the below field: \n        ',
  },
  ru: {
    integration_type: "ru",
    name: "rentalsunited",
    service: "pms",
    title: "Rentals United",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/rentalsunited.svg",
    signup_type: "api_key",
    signup_fields: {
      username: {
        name: "Rentals United User Name",
      },
    },
    signup_text:
      "\nYou can connect with your Rentals United account and import all of your listings and bookings (Reservations) by entering your username/email below.\n        ",
  },
  b24: {
    integration_type: "b24",
    name: "beds24",
    service: "pms",
    title: "Beds24",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/beds24-logo.png",
    signup_type: null,
  },
  ba: {
    integration_type: "ba",
    name: "booking_automation",
    service: "pms",
    title: "Booking Automation",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/booking-automation-logo.png",
    signup_type: null,
  },
  cb: {
    integration_type: "cb",
    name: "cloudbeds",
    service: "pms",
    title: "Cloudbeds",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/cloudbeds-logo.png",
    signup_type: null,
  },
  ifr: {
    integration_type: "ifr",
    name: "inforhms",
    service: "pms",
    title: "Infor HMS",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/infor-logo.png",
    signup_type: null,
  },
  myvr: {
    integration_type: "myvr",
    name: "myvr",
    service: "pms",
    title: "MyVR",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/myvr-logo.png",
    signup_type: null,
  },
  opra: {
    integration_type: "opra",
    name: "opera",
    service: "pms",
    title: "Oracle Opera",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/oracle-logo.png",
    signup_type: null,
  },
  stay: {
    integration_type: "stay",
    name: "stayntouch",
    service: "pms",
    title: "StayNTouch",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/stayntouch-logo.png",
    signup_type: null,
  },
  vrbo: {
    integration_type: "vrbo",
    name: "vrbo",
    service: "pms",
    title: "VRBO",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/vrbo.svg",
    signup_type: null,
  },
  ab: {
    integration_type: "ab",
    name: "leavetown",
    service: "pms",
    title: "Leavetown AirBnB",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/airbnb.svg",
    signup_type: null,
  },
  abnb: {
    integration_type: "abnb",
    name: "airbnb",
    service: "pms",
    title: "AirBnB",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/airbnb.svg",
    signup_type: null,
  },
  ownrz: {
    integration_type: "ownrz",
    name: "ownerrez",
    service: "pms",
    title: "OwnerRez",
    sleeptime_milliseconds: 4000,
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/ownerrez_title_logo.webp",
    details:
      "Your Ownerrez account allows you to manage your Ownerrez guests & listings in Enso Connect.",
    signup_type: "redirect",
  },
  v365: {
    integration_type: "v365",
    service: "pms",
    title: "365 Villas",
    name: "365v",
    icon: "https://secure.365villas.com/images/logo/4.0.365villas.logo.svg",
    signup_type: "api_key",
    signup_fields: {
      key: {
        name: "365 Villas API Key",
      },
      pass: {
        name: "365 Villas API Pass",
      },
      owner_token: {
        name: "365 Owner Token",
      },
    },
    signup_text:
      "\n        To add your 365 Villas account to Enso Connect please enter your 365 Villas Credentials. To obtain these\n        first login to 365 Villas Portal. In the top right corner, click your account name acronym.\n        \n        <img src='https://enso-image-assets.s3.amazonaws.com/brandicons/365villa-home.png' width='100%' />\n        \n        Then click \"API & Integrations\". Here you will find the credentials you need.\n        \n        <img src='https://enso-image-assets.s3.amazonaws.com/brandicons/365villa-credentials.png' height=\"250px\" style=\"display: block; margin-left: auto; margin-right: auto;\" />\n        ",
  },
  avt: {
    integration_type: "avt",
    service: "pms",
    title: "Avantio",
    name: "avantio",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/avantio.webp",
    signup_type: "api_key",
    signup_fields: {
      avt_provider_id: {
        name: "Avantio Provider ID",
      },
      avt_username: {
        name: "Avantio Username",
      },
      avt_password: {
        name: "Avantio Password",
      },
    },
    details:
      "Your Avantio account allows you to manage your Avantio guests & listings in Enso Connect.",
    signup_text:
      "\n        Please raise a ticket with the Customer Care team on Avantio and ask for a Provider ID, username and password.\n        After you have received these details, please enter them in the fields below.\n        ",
    delete_text: "",
  },
};

export const messageTypes = {
  COMPLAINT: {
    description: "The guest is complaining about their stay.",
    default_saved_reply:
      "Thank you for bringing this to our attention. We sincerely apologize for the inconvenience. Please let us know if there is anything we can do to assist you or make your stay more comfortable.",
    name: "Complaint",
  },
  AMENITY: {
    description: "Questions regarding amenities offered.",
    default_saved_reply:
      "Do your best to provide releveant information about amenities, but don't include anything not provided in this article.",
    name: "Amenity Question",
  },
  REFUND: {
    description: "Requests for refunds.",
    default_saved_reply:
      "We have received your refund request. We will review it and get back to you shortly.",
    name: "Refund Request",
  },
  CHECK_IN_OUT: {
    description: "Requests for early check-in or late check-out.",
    default_saved_reply:
      "Thank you for reaching out. Please give us your booking details so we can accommodate your request.",
    name: "Check-in/out Request",
  },
  PROCESS: {
    description: "Questions about the check-in and check-out process.",
    default_saved_reply:
      "Your check-in time is <checkin_time> on <checkin_date>. You can access step by-step check-in instructions using your Boarding Pass: <keycard_link>.\n\n Your check-out time is <checkout_time> on <checkout_date>. We kindly ask you to follow the check-out instructions that will be provided to you through your Boarding Pass.",
    name: "Check-in/Check-out question",
  },
  SPECIALS: {
    description:
      "Requests related to special accommodations, e.g., for pets, children, disabilities.",
    default_saved_reply:
      "Thank you for contacting us about special accommodations. To better assist you, could you please provide us with more details regarding your specific request? This will allow us to tailor our accommodations to best suit your needs.",
    name: "Special Accommodations Request",
  },
  ALTERATION: {
    description: "Requests to alter stay dates or check availability.",
    default_saved_reply:
      "In order to request an alteration, please do so through <booking_channel>'s website. We are unfortunately unable to process stay alteration requests on our end",
    name: "Stay Alteration Request",
  },
  FEEDBACK: {
    description: "Positive feedback about the stay.",
    default_saved_reply:
      "We're delighted to hear you enjoyed your stay! Your feedback means a lot to us, thank you for sharing your experience.",
    name: "Positive Feedback",
  },
  TRANSPORT: {
    description: "Questions or requests related to transportation.",
    default_saved_reply: "The listing is located at <listing_address>.",
    name: "Transportation",
  },
  RECOMMENDATIONS: {
    description: "Asking for local recommendations.",
    default_saved_reply:
      "Provide local recommendation close to the listing, located at <listing_address>.",
    name: "Local Recommendations",
  },
  VERIFICATION: {
    description: "Verification related questions.",
    default_saved_reply:
      "Verification happens through the boarding pass, which you can access using your boarding pass link: <keycard_link>. If you're having trouble uploading your ID, please check to ensure camera permissions are enabled.",
    name: "Verification Question",
  },
  OTHER: {
    description: "The message does not fall into the above categories.",
    name: "Other",
  },
};

export const productEnum = {
  flex: "flex",
  deposit: "deposit",
  fee: "fee",
  pet_fee: "pet_fee",
  custom_fee: "custom_fee",
  custom_upsell: "custom_upsell",
  early_checkin: "checkin",
  late_checkout: "checkout",
  local_service: "local_service",
  gap_night: "gap_night",
  viator: "viator",
};

export const productFeeExemptions = ["tax", "vat"];

export const templateImages = {
  up: [
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Baked+Goods+on+Cutting+Board.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Bedding.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Birds+Eye+Boat+in+Dark+Water.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Birds+Eye+Boat+in+Open+Water.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Birds+Eye+City+at+Night.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Birds+Eye+City+Day.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Blurred+TV+with+Remote.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Cat+Napping+in+Bed.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Chef+Chopping+Veggies.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Chef+Cooking+with+Open+Flame.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Compost+with+Food+Scraps.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Cute+Upside+Down+Cat.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Disco+Ball+for+Parties.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Fireplace.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Folded+Sweaters.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Garbage+Cans.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Goldie+Dog+with+Flower.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Group+Hike.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Heart+Hands.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Inside+a+Parking+Garage.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Inside+Local+Resturant.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Kneading+Dough.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Laptop+on+a+Desk+with+Large+Monitor+Behind.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Laptop+on+a+Desk.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Laundramat.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Laundry+Bin.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Nespresso+Pod+on+Coffee+Beans.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Nespresso+Setup+on+Counter.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Netflix+on+TV+with+Feet+Up.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Netflix+open+on+TV+with+Remote+Up.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Nice+Espresso+on+White+Background.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Old+Phone.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Outdoor+Parking.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Outside+a+House+Street+Parking.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Petting+Horses.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Pink+Flowers+on+a+White+Background.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Recycling+Bin.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Snowboarder+on+Snowy+Peak.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Social+Drinking.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Solo+Hike.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Steaming+Coffee+Outside.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Winter+Gondela.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Working+on+Laptop+with+Phone+in+Hand.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Yellow+Flowers+on+a+Blue+Sky.webp",
  ],
  gb: [
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Baked+Goods+on+Cutting+Board.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Bedding.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Birds+Eye+Boat+in+Dark+Water.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Birds+Eye+Boat+in+Open+Water.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Birds+Eye+City+at+Night.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Birds+Eye+City+Day.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Blurred+TV+with+Remote.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Cat+Napping+in+Bed.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Chef+Chopping+Veggies.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Chef+Cooking+with+Open+Flame.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Compost+with+Food+Scraps.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Cute+Upside+Down+Cat.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Disco+Ball+for+Parties.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Fireplace.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Folded+Sweaters.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Garbage+Cans.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Goldie+Dog+with+Flower.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Group+Hike.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Heart+Hands.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Inside+a+Parking+Garage.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Inside+Local+Resturant.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Kneading+Dough.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Laptop+on+a+Desk+with+Large+Monitor+Behind.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Laptop+on+a+Desk.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Laundramat.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Laundry+Bin.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Nespresso+Pod+on+Coffee+Beans.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Nespresso+Setup+on+Counter.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Netflix+on+TV+with+Feet+Up.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Netflix+open+on+TV+with+Remote+Up.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Nice+Espresso+on+White+Background.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Old+Phone.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Outdoor+Parking.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Outside+a+House+Street+Parking.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Petting+Horses.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Pink+Flowers+on+a+White+Background.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Recycling+Bin.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Snowboarder+on+Snowy+Peak.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Social+Drinking.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Solo+Hike.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Steaming+Coffee+Outside.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Winter+Gondela.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Working+on+Laptop+with+Phone+in+Hand.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Yellow+Flowers+on+a+Blue+Sky.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/shopping-mall-overhead.webp",
    "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/family-park-playing.webp",
    "https://enso-image-assets.s3.amazonaws.com/train-station.webp",
  ],
};

export const PropertyTypesEnum = {
  apart_hotel: "Apart hotel",
  apartment: "Apartment",
  bed_and_breakfast: "Bed and breakfast",
  boatel: "Boatel",
  boutique: "Boutique",
  bungalow: "Bungalow",
  cabin_or_bungalow: "Cabin or bungalow",
  campground: "Campground",
  castle: "Castle",
  chalet: "Chalet",
  charm_hotel: "Charm hotel",
  condominium: "Condominium",
  cottage: "Cottage",
  cruise: "Cruise",
  double_room: "Double room",
  efficiency_or_studio: "Efficiency / Studio",
  family: "Family",
  ferry: "Ferry",
  guest_farm: "Guest farm",
  guest_house_limited_service: "Guest house limited service",
  holiday_resort: "Holiday resort",
  hostel: "Hostel",
  hotel: "Hotel",
  inn: "Inn",
  lodge: "Lodge",
  log_cabin: "Log cabin",
  manor: "Manor",
  meeting_resort: "Meeting resort",
  mobile_home: "Mobile home",
  monastery: "Monastery",
  motel: "Motel",
  narrow_boat: "Narrow boat",
  pension: "Pension",
  penthouse: "Penthouse",
  private_room: "Private room",
  quadruple: "Quadruple",
  ranch: "Ranch",
  recreational_vehicle_park: "Recreational vehicle park",
  residential_apartment: "Residential apartment",
  resort: "Resort",
  riad: "Riad",
  sailing_ship: "Sailing ship",
  self_catering_accommodation: "Self catering accommodation",
  shared_room: "Shared room",
  shepherd_hut: "Shepherd hut",
  single_room: "Single room",
  ski_chalet: "Ski chalet",
  studio: "Studio",
  suite: "Suite",
  tent: "Tent",
  tipi: "Tipi",
  tower: "Tower",
  townhome: "Townhome",
  tree_house: "Tree house",
  triple_room: "Triple room",
  trullo: "Trullo",
  twin: "Twin",
  twin_or_double: "Twin / Double",
  vacation_home: "Vacation home",
  villa: "Villa",
  watermill: "Watermill",
  wildlife_reserve: "Wildlife reserve",
  windmill: "Windmill",
  yacht: "Yacht",
  yurt: "Yurt",
  barn: "Barn",
  boat: "Boat",
  building: "Building",
  cabin: "Cabin",
  caravan: "Caravan",
  chacara: "Chacara",
  chateau: "Chateau",
  corporate_apartment: "Corporate apartment",
  estate: "Estate",
  farmhouse: "Farmhouse",
  guesthouse: "Guesthouse",
  house: "House",
  house_boat: "House boat",
  mas: "Mas",
  mill: "Mill",
  recreational_vehicle: "Recreational vehicle",
  serviced_apartment: "Serviced apartment",
};

export const AmenityTypes = {
  child: {
    toddler_bed: [],
    playground_nearby: [],
    child_toddler_seat: [],
    children_s_books_and_toys: [],
    children_s_dinnerware: [],
    children_s_play_area: ["cost", "hours"],
    children_s_playpen: [],
    children_s_pool: ["cost", "hours"],
  },
  baby: {
    changing_table: [],
    baby_bath: [],
    baby_crib: [],
    baby_listening_device: [],
    baby_safety_gates: [],
    baby_sitting: [],
  },
  bathroom: {
    bathrobe: [],
    bath_tub_with_spray_jets: [],
    bathtub: [],
    shower: [],
    hot_tub: [],
    combo_tub_shower: [],
    bathtub_and_whirlpool_separate: [],
    bidet: [],
    towels: [],
    hairdryer: [],
    steam_room: [],
    steam_bath: [],
    slippers: [],
    sauna: [],
    spa_bathroom: [],
    premium_linens_and_towels: [],
  },
  kitchen: {
    plates_and_bowls: [],
    oven: [],
    freezer: [],
    ice_maker: [],
    dishwasher: [],
    kitchen: [],
    kitchenette: [],
    pizza_oven: [],
    raclette: [],
    electric_kettle: [],
    grill: [],
    refrigerator: [],
    mini_refrigerator: [],
    coffee_maker: [],
    coffee_or_tea_maker: [],
    microwave: [],
    stove: [],
    bar: [],
  },
  services: {
    airport_shuttle: [],
    luggage_service: [],
    grocery_service: [],
    room_service: [],
    newspaper: [],
    bartender_on_request: [],
    butler: [],
    laundry_service: [],
    pant_press: [],
    catering_services: [],
    photographer: [],
    door_man: [],
    car_available: [],
    concierge: [],
    staffed_property: [],
    private_chef: [],
    dry_cleaning: [],
    breakfast: [],
    valet_parking: [],
    welcome_drink: [],
    welcome_gift: [],
    wake_up_calls: [],
    chauffeur: [],
    events_ticket_service: [],
    emergency_support: [],
    laundry_services: [],
    house_cleaning_included: [],
    tour_or_sightseeing_desk: [],
  },
  office: {
    business_center: [],
    computer: [],
    desk: [],
    ethernet_connection: [],
    fax_machine: [],
    meeting_rooms: [],
    soundproofed_room: [],
  },
  local_services: {
    atm_nearby: [],
    car_rental_desk: [],
    hospital: [],
    near_a_train_or_subway_station: [],
    market: [],
    coin_operated_laundry: [],
    currency_exchange: [],
    ski_rental: [],
    electric_car_charging_stations: [],
    bicycle_rentals: [],
    gift_or_news_stand: [],
    grocery_store: [],
  },
  parking: {
    indoor_parking: [],
    outdoor_parking: [],
    ev_charger: [],
  },
  basic: {
    wifi: ["password"],
    instant_hot_water: [],
    heating: [],
    air_conditioning: [],
    hangers: [],
    smoke_detectors: [],
    private_entrance: [],
    private_bathroom: [],
    cooking_basics: [],
    silverware_or_utensils: [],
    telephone: [],
    soap_or_shampoo: [],
    breakfast_included_in_price: [],
  },
  safety: {
    fire_extinguishers: [],
    lock_on_bedroom_door: [],
    carbon_monoxide_detector: [],
    carbon_monoxide_detector_and_smoke_detector: [],
    security_system: [],
    table_corner_guards: [],
    tables_and_chairs: [],
    telephone_for_hearing_impaired: [],
    outlet_covers: [],
    first_aid_kit: [],
    medical_facilities_service: [],
  },
  indoor_amenities: {
    dining_area: [],
    ceiling_fan: [],
    closets_in_room: [],
    dart_board: [],
    radio: [],
    fitness_center: [],
    home_theatre_system: [],
    safe: ["password"],
    futon: [],
    snack_bar: [],
    courtyard: [],
    gym: [],
    cd_player: [],
    couch: [],
    billiards: [],
    solarium: [],
    table_tennis: [],
    fireplace: [],
    foosball: [],
    television: [],
    game_console: [],
    video_games: [],
    tiled_floor: [],
    stereo: [],
    toaster: [],
    tourist_attractions: [],
    trampoline: [],
    vending_machines: [],
    pool_table: [],
    library: [],
    satellite_or_cable: [],
    music_library: [],
  },
  cleaning: {
    iron: [],
    washer: [],
    dryer: [],
    ironing_board: [],
  },
  view: {
    ocean: [],
    ocean_view: [],
    park_view: [],
    harbor_view: [],
    mountain_view: [],
    beach_view: [],
    water_view: [],
    garden_view: [],
    lake_view: [],
    river_view: [],
    waterfront: [],
    riverfront: [],
    beachfront: [],
    oceanfront: [],
    lakefront: [],
    seafront: [],
    fishing_bay: [],
    reef: [],
    downtown: [],
    city_view: [],
  },
  accessibility: {
    flat_smooth_pathway_to_front_door: [],
    home_step_free_access: [],
    carpeted_floor: [],
    parquet_floor: [],
    path_to_entrance_lit_at_night: [],
    hypoallergenic_bed: [],
    hypoallergenic_rooms: [],
    handheld_shower_hear: [],
    grab_rails_in_shower_and_toilet: [],
    bathroom_wide_doorway: [],
    bedroom_step_free_access: [],
    bedroom_wide_doorway: [],
    home_wide_doorway: [],
    upper_floor_reachable_by_stairs_only: [],
    bathroom_step_free_access: [],
    single_level_home: [],
    elevator: [],
    wheelchair_access: [],
    wheelchair_inaccessible: [],
    wide_clearance_to_bed: [],
    wide_clearance_to_shower_and_toilet: [],
    wide_hallway_clearance: [],
    window_guards: [],
    rollin_shower_with_bench: [],
  },
  gear: {
    beach_essentials: [],
    locker_room: [],
    ski_storage: [],
    bike: [],
    water_sports_gear: [],
    snow_sports_gear: [],
  },
  bedroom: {
    luxury_linen_type: [],
    foam_pillows: [],
    room_darkening_shades: [],
    extra_pillows_and_blankets: [],
    alarm_clock: [],
    linens: [],
  },
  outdoor_amenities: {
    patio: [],
    amenity_outdoor_shower: [],
    outdoor_dining_area: [],
    squash_court: [],
    outdoor_furniture: [],
    hammock: [],
    fire_pit: [],
    private_yard: [],
    garden: [],
    summer_terrace: [],
    plunge_pool: [],
    pool: [],
    barbeque: [],
    gazebo: [],
    wood_stove: [],
    balcony: [],
    dishes_utensils: [],
    fitness_room: [],
    veranda: [],
  },
  religious: {
    synagogue: [],
    church: [],
  },
  activities: {
    boating: [],
    nearby_local_nightlife: [],
    hunting: [],
    ice_skating: [],
    restaurant: [],
    hot_air_ballooning: [],
    sailing: [],
    miniature_golf: [],
    gambling: [],
    racquetball: [],
    hiking_trail: [],
    movie_theater: [],
    lounges_or_bars: [],
    live_entertainment: [],
    jacuzzi: [],
    jet_ski: [],
    kayaking: [],
    canoeing: [],
    tennis: [],
    museum: [],
    ruins: [],
    picnic_area: [],
    walking_track: [],
    horseback_riding: [],
    casino: [],
    resort: [],
    cave: [],
    bowling_alley: [],
    diving: [],
    tennis_court: [],
    basketball_court: [],
    rain_forest: [],
    disco: [],
    recreation_center: [],
    bird_watching: [],
    fishing: [],
    hiking: [],
    antiquing: [],
    zoo: [],
    health_beauty_spa: [],
    marina: [],
    mountaineering: [],
    museums: [],
    windsurfing: [],
    water_skiing: [],
    surfing: [],
    shopping: [],
    cross_country_skiing: [],
    massage: [],
    nude_beach: [],
    nightclub: [],
    kayak: [],
    water_parks: [],
    whitewater_rafting: [],
    wildlife_viewing: [],
    winery_tours: [],
    volcano: [],
    theme_parks: [],
    skiing: [],
    cycling: [],
    spelunking: [],
    swimming: [],
    rafting: [],
    scuba_or_snorkeling: [],
    thalassotherapy: [],
    tubing: [],
    whale_watching: [],
    golfing: [],
    snow_boarding: [],
    snowmobiling: [],
    river_rafting: [],
    sledding: [],
    rock_climbing: [],
    paddle_boating: [],
    parasailing: [],
    mountain_biking_trail: [],
    "mounta in_climbing": [],
    water_park: [],
  },
};

export const DefaultAmenityFields = {
  child: ["location", "instructions"],
  baby: ["location", "instructions"],
  bathroom: ["location", "instructions"],
  kitchen: ["location", "instructions"],
  services: ["location", "instructions"],
  office: ["location", "instructions"],
  local_services: ["location", "instructions", "cost", "hours", "restrictions"],
  parking: ["location", "instructions", "cost", "hours", "restrictions"],
  basic: ["location", "instructions"],
  safety: ["location", "instructions"],
  indoor_amenities: ["location", "instructions"],
  cleaning: ["location", "instructions"],
  view: ["location", "instructions"],
  accessibility: ["location", "instructions"],
  gear: ["location", "instructions", "cost", "restrictions"],
  bedroom: ["location", "instructions"],
  outdoor_amenities: ["location", "instructions", "cost", "restrictions"],
  religious: ["location", "instructions", "restrictions"],
  activities: ["location", "instructions", "cost", "hours", "restrictions"],
};

export const AmenityTypeEnum = {
  child: "child",
  baby: "baby",
  bathroom: "bathroom",
  kitchen: "kitchen",
  services: "services",
  office: "office",
  local_services: "local_services",
  parking: "parking",
  basic: "basic",
  safety: "safety",
  indoor_amenities: "indoor_amenities",
  cleaning: "cleaning",
  view: "view",
  accessibility: "accessibility",
  gear: "gear",
  bedroom: "bedroom",
  outdoor_amenities: "outdoor_amenities",
  religious: "religious",
  activities: "activities",
};

export const ListingRoomAmenityTypeEnum = {
  king_bed: "King bed",
  queen_bed: "Queen bed",
  double_bed: "Double bed",
  single_bed: "Single bed",
  sofa_bed: "Sofa bed",
  couch: "Couch",
  air_mattress: "Air mattress",
  bunk_bed: "Bunk bed",
  floor_mattress: "Floor mattress",
  toddler_bed: "Toddler bed",
  crib: "Crib",
  water_bed: "Water bed",
  hammock: "Hammock",
};

export const ImageTagsEnum = {
  activities: "Activities",
  adam: "ADAM",
  alcoholic_drinks: "Alcoholic drinks",
  american_breakfast: "American breakfast",
  animals: "Animals",
  aqua_park: "Aqua park",
  area_and_facilities: "Area and facilities",
  asian_breakfast: "Asian breakfast",
  autumn: "Autumn",
  balcony_or_terrace: "Balcony / Terrace",
  banquet_or_function_facilities: "Banquet/Function Facilities",
  bathroom: "Bathroom",
  bbq_facilities: "BBQ Facilities",
  beach: "Beach",
  bed: "Bed",
  bedroom: "Bedroom",
  billiard: "Billiard",
  bird_s_eye_view: "Bird's Eye View",
  bowling: "Bowling",
  breakfast: "Breakfast",
  buffet_breakfast: "Buffet breakfast",
  bunk_bed: "Bunk bed",
  business_facilities: "Business facilities",
  canoeing: "Canoeing",
  casino: "Casino",
  certificate_or_award: "Certificate / Award",
  children: "Children",
  children_s_play_ground: "Children s play ground",
  city_view: "City view",
  coffee_or_tea_facilities: "Coffee/tea Facilities",
  communal_kitchen: "Communal kitchen",
  communal_lounge_or_tv_room: "Communal Lounge / TV Room",
  continental_breakfast: "Continental breakfast",
  cot: "Cot",
  cycling: "Cycling",
  darts: "Darts",
  day: "Day",
  decorative_detail: "Decorative detail",
  dining_area: "Dining area",
  dinner: "Dinner",
  diving: "Diving",
  drinks: "Drinks",
  english_or_irish_breakfast: "English/Irish Breakfast",
  entertainment: "Entertainment",
  evening_entertainment: "Evening entertainment",
  facade_or_entrance: "Facade / Entrance",
  family: "Family",
  fishing: "Fishing",
  fitness_centre_or_facilities: "Fitness Centre/Facilities",
  floor_plan: "Floor plan",
  food: "Food",
  food_and_drinks: "Food and drinks",
  food_close_up: "Food Close-up",
  game_room: "Game room",
  garden: "Garden",
  garden_view: "Garden view",
  golf_course: "Golf course",
  group_of_guests: "Group of guests",
  guests: "Guests",
  hiking: "Hiking",
  horse_riding: "Horse-riding",
  hot_spring_bath: "Hot spring bath",
  hot_tub: "Hot tub",
  italian_breakfast: "Italian breakfast",
  karaoke: "Karaoke",
  kids_s_club: "Kids's Club",
  kitchen_or_kitchenette: "Kitchen or kitchenette",
  lake_view: "Lake view",
  landmark_view: "Landmark view",
  library: "Library",
  living_room: "Living room",
  lobby_or_reception: "Lobby or reception",
  location: "Location",
  logo_or_certificate_or_sign: "Logo / Certificate / Sign",
  lounge_or_bar: "Lounge or bar",
  lunch: "Lunch",
  massage: "Massage",
  meals: "Meals",
  meeting_or_conference_room: "Meeting/Conference Room",
  minigolf: "Minigolf",
  mountain_view: "Mountain view",
  natural_landscape: "Natural landscape",
  nearby_landmark: "Nearby landmark",
  neighbourhood: "Neighbourhood",
  night: "Night",
  nightclub_or_dj: "Nightclub / DJ",
  non_alcoholic_drinks: "Non alcoholic drinks",
  off_site: "Off site",
  older_children: "Older children",
  on_site: "On site",
  on_site_shops: "On-site Shops",
  open_air_bath: "Open air bath",
  other: "Other",
  patio: "Patio",
  people: "People",
  pets: "Pets",
  photo_of_the_whole_room: "Photo of the whole room",
  place_of_worship: "Place of worship",
  pool_view: "Pool view",
  property_building: "Property building",
  property_logo_or_sign: "Property logo or sign",
  public_bath: "Public bath",
  restaurant_or_places_to_eat: "Restaurant / Places to Eat",
  river_view: "River view",
  sauna: "Sauna",
  sea_view: "Sea view",
  seasons: "Seasons",
  seating_area: "Seating area",
  shopping_area: "Shopping area",
  shower: "Shower",
  ski_school: "Ski school",
  skiing: "Skiing",
  snorkeling: "Snorkeling",
  solarium: "Solarium",
  spa_and_wellness_centre_or_facilities: "Spa and Wellness Centre/Facilities",
  sports: "Sports",
  spring: "Spring",
  squash: "Squash",
  staff: "Staff",
  steam_room: "Steam room",
  street_view: "Street view",
  summer: "Summer",
  sunrise: "Sunrise",
  sunset: "Sunset",
  supermarket_or_grocery_shop: "Supermarket/Grocery Shop",
  swimming_pool: "Swimming pool",
  table_tennis: "Table tennis",
  tennis_court: "Tennis court",
  text_overlay: "Text overlay",
  time_of_day: "Time of day",
  toilet: "Toilet",
  tv_and_multimedia: "TV and Multimedia",
  view_from_property_or_room: "View (from Property/Room)",
  windsurfing: "Windsurfing",
  winter: "Winter",
  young_children: "Young children",
};

export const FeeValueTypeEnum = {
  flat: "Flat",
  percent: "Percent",
};

export const FeeTaxTypeEnum = {
  taxable: "Taxable",
  not_taxable: "Not taxable",
};

export const GuestTravelReasonEnum = {
  business: "Business",
  pleasure: "Pleasure",
  event: "Event",
  digital_nomad: "Digital Nomad",
  family: "Family",
  quarantine: "Quarantine",
  wellness: "Wellness & Health",
  long_term: "Long Term Stay",
};

export const PaymentStatusEnum = {
  scheduled: "Scheduled",
  requires_action: "Requires action",
  overpaid: "Overpaid",
  paid: "Paid",
  partial_paid: "Partial paid",
  unpaid: "Unpaid",
  refunded: "Refunded",
  cancelled: "Cancelled",
  failed: "Failed",
  on_hold: "On hold",
};

export const BookingTagEnum = {
  checkin: "Early check-in",
  checkout: "Late check-out",
  bp_revoked: "Revoked",
  verified: "Verified",
  deposit_collected: "Deposit collected",
  deposit_paid: "Deposit paid",
  last_minute_traveler: "Last Minute",
  inquiry: "Inquiry",
  bypass_checkin_restrictions: "Bypass Checkin Restrictions",
  bypass_checkout_restrictions: "Bypass Checkout Restrictions",
  bp_opened: "Boarding Pass Opened",
  bp_checkedin: "Checked-In Through Boarding Pass",
  automatic_verification: "Automatic Verification",
  manual_verification: "Manual Verification",
};

export const serviceEnum = {
  objects: "obj",
  accounts: "act",
  pms: "pms",
  chatbot: "cb",
  messaging: "msg",
  phone_call: "phn",
  device: "sl",
  keycard: "kc",
  assistant: "ast",
  payment: "pay",
  verification: "ver",
  product: "pr",
  bookings: "bk",
  listings: "ls",
  sms: "sms",
  billing: "billing",
  groups: "groups",
  experiences: "exp",
};

export const serviceProviderEnum = {
  partee: "partee",
  flexipass: "flexipass",
  autohost: "autohost",
  superhog: "superhog",
  chekin: "chekin",
  salto: "salto",
  interphone: "interphone",
  august: "august",
  schlage: "schlage",
  smartthings: "smart",
  amazon: "amazon",
  keycafe: "keycafe",
  stripe: "stripe",
  twilio: "twilio",
  whatsapp: "whatsapp",
  nuki: "nuki",
  igloo: "igloo",
  yale: "yale",
  minut: "minut",
  ttlock: "ttlock",
  kwikset: "kwikset",
  chatgpt: "chatgpt",
  four_suites: "4suites",
  wyze: "wyze",
  dormakaba_oracode: "dormakaba_oracode",
  lockly: "lockly",
  noiseaware: "noiseaware",
  brivo: "brivo",
  tedee: "tedee",
  latch: "latch",
  viator: "viator",
  leavetown_airbnb: "ab",
  airbnb: "abnb",
  vrbo: "vrbo",
  guesty: "gy",
  bookingsync: "bks",
  mews: "mews",
  hostaway: "ha",
  rentalsunited: "ru",
  beds24: "b24",
  booking_automation: "ba",
  cloudbeds: "cb",
  hostfully: "hfl",
  inforhms: "ifr",
  impala: "impl",
  lavanda: "lvnd",
  myvr: "myvr",
  opera: "opra",
  smartbnb: "smrt",
  stayntouch: "stay",
  streamline: "st",
  track: "trk",
  lodgify: "lg",
  lmpm: "lmpm",
  leavetown: "lt",
  hostify: "htf",
  custom: "cs",
  ownerrez: "ownrz",
  avantio: "avt",
};

export const addonServices = {
  airbnb: [
    {
      Details:
        "Connect your Airbnb account to start messaging your guests via Airbnb\u2019s messaging inbox. Respond to inquiries & messages directly from the Enso connect desktop & mobile app.\n\nNOTE: \n- Co-host accounts & listings imported from other accounts are not supported. Please ensure you are signed into the primary host\u2019s account before connecting.\n- Bookings that have already checked are not supported.\n- Bookings at deleted/deactivated listings are not supported.",
      "How it works":
        "Once you\u2019ve signed in to your Airbnb account, click \u201cpair listings\u201d under Business Settings > Connected Accounts > Airbnb to connect your Airbnb listings to Enso.\n\nAfter your listings are paired, click \u201csync bookings\u201d under Business Settings > Connected Accounts > Airbnbto connect your Airbnb bookings to Enso. Once the Airbnb icon appears in the message thread, you\u2019re all set to start messaging.",
      Includes: [
        {
          header: "Send Airbnb Messages",
        },
        {
          header: "Receive Airbnb Messages",
        },
      ],
      Name: "Airbnb",
      Provider: "airbnb",
      "Subscription ID": null,
      Verifier: null,
      cover_photo_url:
        "https://enso-image-assets.s3.amazonaws.com/banners/airbnb_banner.jpg",
      icon_url:
        "https://enso-image-assets.s3.amazonaws.com/brandicons/airbnb.png",
    },
  ],
  autohost: [
    {
      Details:
        "Autohost independently verifies guests during the booking process through advanced screening technology, minimizing risk and chargebacks by preventing troublesome guests from booking properties.",
      "How it works":
        "Your guests' ID, date of birth, address & other information will collected in the Boarding Pass and sent to Autohost. If a guest does not meet Autohost's risk assessement criteria, you will be prompted to manually approve or deny them.",
      Includes: [
        {
          header: "Advanced ID verification ",
        },
        {
          header: "Full Background Check ",
          subheader:
            " Perform a deeper historical analysis of the guest, searching criminal databases and records.",
        },
        {
          header: "Sanctions Search ",
          subheader:
            " OFAC publishes lists of individuals and companies owned or controlled by targeted countries. It also lists individuals, groups, and entities, such as terrorists and narcotics traffickers.",
        },
        {
          header: "Sex Offender Search ",
          subheader:
            " Search sex offender databases using the guest's personal information to see if there's a match.",
        },
        {
          header: "Fraud detection ",
        },
        {
          header: "Advanced risk assessment score ",
        },
      ],
      Name: "Autohost - Background Check",
      Provider: "autohost",
      "Subscription ID": "ahc",
      Verifier: "autohost_check",
      cover_photo_url:
        "https://enso-image-assets.s3.amazonaws.com/banners/autohost_banner.png",
      icon_url:
        "https://enso-image-assets.s3.amazonaws.com/brandicons/autohost.png",
    },
    {
      Details:
        "Autohost independently verifies guests during the booking process through advanced screening technology, minimizing risk and chargebacks by preventing troublesome guests from booking properties.",
      "How it works":
        "Your guests' ID, date of birth, address & other information will collected in the Boarding Pass and sent to Autohost. If a guest does not meet Autohost's risk assessment criteria, you will be prompted to manually approve or deny their access to the check-in instructions.",
      Includes: [
        {
          header: "Guest screening AI",
        },
        {
          header: "Fraud detection",
        },
        {
          header: "ID collection ",
        },
        {
          header: "Advanced ID verification ",
        },
      ],
      Name: "Autohost - Fraud Prevention",
      Provider: "autohost",
      "Subscription ID": "ahp",
      Verifier: "autohost_plus",
      cover_photo_url:
        "https://enso-image-assets.s3.amazonaws.com/banners/autohost_banner.png",
      icon_url:
        "https://enso-image-assets.s3.amazonaws.com/brandicons/autohost.png",
    },
    {
      Details:
        "Autohost independently verifies guests during the booking process through advanced screening technology, minimizing risk and chargebacks by preventing troublesome guests from booking properties.",
      "How it works":
        "Your guests' ID, date of birth, address & other information will collected in the Boarding Pass and sent to Autohost. If a guest does not meet Autohost's risk assessment criteria, you will be prompted to manually approve or deny their access to the check-in instructions.",
      Includes: [
        {
          header: "Guest screening AI",
        },
        {
          header: "Fraud detection",
        },
        {
          header: "ID collection ",
        },
      ],
      Name: "Autohost - ID Collection",
      Provider: "autohost",
      "Subscription ID": "ah",
      Verifier: "autohost",
      cover_photo_url:
        "https://enso-image-assets.s3.amazonaws.com/banners/autohost_banner.png",
      icon_url:
        "https://enso-image-assets.s3.amazonaws.com/brandicons/autohost.png",
    },
  ],
  enso: [
    {
      Details:
        'Bring AI to the forefront of your hospitality business - save time creating content and responding to guests. \n\nWith Enso\u2019s direct integration to GPT 4, you can now easily translate, write, summarize any upsell, guidebook, agreement or other piece of content, with one single click. EnsoAI will even draft responses for you when a guest asks a question directly in our Unified Inbox - for SMS and Airbnb messages. \n\nWatch a quick demo about EnsoAI <a href="https://www.youtube.com/watch?v=D8bt9mHwSlk&ab_channel=EnsoConnect">here</a>',
      "How it works":
        "Enabling this feature will activate the EnsoAI buttons in the platform. This functionality will help you speed up your workflow and streamline your business operations.",
      Includes: [
        {
          header: "AI Messaging",
        },
        {
          header: "AI Content Generation",
        },
        {
          header: "AI Sentiment Analysis",
        },
      ],
      Name: "EnsoAI",
      Provider: "enso",
      "Subscription ID": "ai",
      Verifier: null,
      cover_photo_url:
        "https://enso-image-assets.s3.amazonaws.com/banners/logos_background.png",
      icon_url: "https://enso-image-assets.s3.amazonaws.com/enso_logo.png",
    },
  ],
  seam: [
    {
      Details:
        "Connect your smart devices and manage your smart devices directly from one single dashboard. From battery status, to remote controls, or automatic settings of code for your guests - create a digital experience with ease.",
      "How it works":
        "After connecting your devices, pair them with your listings. \n\nFor smart locks, simply add them to your Check-In Steps and Enso will automatically generate codes for your guests. \n\nFor smart sensors, you will receive alerts, and create notifications using the guest journey editor messaging functionalities. ",
      Includes: [
        {
          header: "Smart Lock Management ",
        },
        {
          header: "Smart Sensor Management",
        },
        {
          header: "Instant Lock/Unlock",
        },
        {
          header: "Automatic Code Generation Recovery Code Generation",
        },
        {
          header: "Custom Code Generation",
        },
        {
          header: "Device Alerts",
        },
        {
          header: "Battery Status",
        },
        {
          header: "Triggers & Warnings",
        },
      ],
      Name: "Devices",
      Provider: "seam",
      "Subscription ID": "dvc",
      Verifier: null,
      cover_photo_url:
        "https://enso-image-assets.s3.amazonaws.com/banners/smart_locks_banner.png",
      icon_url:
        "https://enso-image-assets.s3.amazonaws.com/brandicons/seam_logo.png",
    },
  ],
  superhog: [
    {
      Details:
        "Superhog collect guest IDs during the booking process, providing you with visibility on potential risks to prevent fraud and chargebacks in your properties. For every validated booking, Superhog guarantees that guests will meet their liability to hosts for property damage caused during that booking. For all validated bookings where a guest fails to meet their liability for property damage, Superhog will reimburse hosts through its insurance-backed Host Guarantee up to $5,000,000.",
      "How it works":
        "The Boarding Pass will collect your guests' ID, date of birth, address, and additional details, which will be forwarded to Superhog. Subsequently, you can check the Superhog verification status within the Enso Connect dashboard.\u00a0\nTo file a coverage claim, please contact Superhog directly.",
      Includes: [
        {
          header: "ID Collection",
        },
        {
          header: "ID Verification",
        },
        {
          header: "Biometric Validation",
        },
        {
          header: "Database Check",
        },
        {
          header: "$5,000,000 Coverage",
        },
      ],
      Name: "Superhog - ID Collection & Guarantee",
      Provider: "superhog",
      "Subscription ID": "shp",
      Verifier: "superhog_plus",
      cover_photo_url:
        "https://enso-image-assets.s3.amazonaws.com/banners/superhog_id_collection_guarantee.png",
      icon_url:
        "https://enso-image-assets.s3.amazonaws.com/banners/superhog_logo.png",
    },
    {
      Details:
        "Superhog collect guest IDs during the booking process, providing you with visibility on potential risks, to prevent fraud and chargebacks in your properties. ",
      "How it works":
        "The Boarding Pass will collect your guests' ID, date of birth, address, and additional details, which will be forwarded to Superhog. Subsequently, you can check the Superhog verification status within the Enso Connect dashboard.",
      Includes: [
        {
          header: "ID Collection",
        },
        {
          header: "ID Verification",
        },
        {
          header: "Biometric Validation",
        },
        {
          header: "Database Check",
        },
      ],
      Name: "Superhog - ID Collection ",
      Provider: "superhog",
      "Subscription ID": "sh",
      Verifier: "superhog",
      cover_photo_url:
        "https://enso-image-assets.s3.amazonaws.com/banners/superhog_id_collection.png",
      icon_url:
        "https://enso-image-assets.s3.amazonaws.com/banners/superhog_logo.png",
    },
  ],
  twilio: [
    {
      Details:
        'Send, receive and automate standard text messages (SMS) in your Unified Inbox.\n\nUpon activation of SMS in your Enso Connect account, you will be charged for each message segment, as per Twilio\'s pricing structure. You will be assigned a unique number that will be used for all SMS messaging within your Enso Connect Inbox.\n\n* Pricing is determined on a per-message segment basis and differs according to the country and carrier. The cost for each SMS message is rounded up to the nearest cent. For detailed pricing information, please <a href="https://www.twilio.com/en-us/sms/pricing/us">click here</a>',
      "How it works": null,
      Includes: [
        {
          header: "Send SMS messages",
        },
        {
          header: "Receive SMS messages",
        },
      ],
      Name: "SMS",
      Provider: "twilio",
      "Subscription ID": "sms",
      Verifier: null,
      cover_photo_url:
        "https://enso-image-assets.s3.amazonaws.com/banners/logos_background.png",
      icon_url:
        "https://enso-image-assets.s3.amazonaws.com/brandicons/twilio_logo.png",
    },
  ],
  whatsapp: [
    {
      Details:
        "Send, receive and automate WhatsApp messages in your Unified Inbox. Upon activation of WhatsApp in your Enso Connect account, you will be charged in accordance with \nMeta pricing\n.\u00a0\n\nTo enable WhatsApp messaging, you will need to have a WhatsApp business account, and to ensure you have your guest\u2019s phone number. All WhatsApp messages will be sent using Enso Connect from your business account number.\u00a0\n\nTo initiate a conversation with a guest, you\u2019ll need to use one of the templates, created in your WhatsApp business account. Once a guest responds, you can continue the conversation freely.\u00a0\n\nUpon receiving a message from a guest, you\u2019ll have 24 hours to respond, per WhatsApp\u2019s rules for business accounts. The timer is reset with each new guest message received.\n\nNOTE:\n- We currently only support plain text whatsapp templates. Please update your templates if they contain buttons, images or other dynamic content.\n- We currently only support whatsapp templates in English. Please remove  other language templates before connecting.",
      "How it works": null,
      Includes: [
        {
          header: "Send WhatsApp messages",
        },
        {
          header: "Receive WhatsApp messages",
        },
      ],
      Name: "WhatsApp",
      Provider: "whatsapp",
      "Subscription ID": null,
      Verifier: null,
      cover_photo_url: null,
      icon_url: null,
    },
  ],
  chekin: [
    {
      Details:
        "Chekin is a guest verification solution that enables you to collect details for each traveler booking a stay & automatically comply with regulation by sending guest data to local authorities. For a full list of countries supported, see https://chekin.com/en/legal-compliance-map/",
      "How it works":
        "Enabling Chekin will automatically present the Checkin portal to guests in the Boarding Pass. In order to enable automatic authoriry reporting & other features for your listings, head to Listing Content > Policies & Custom Fields > See Chekin Details and complete the configuration process. Guests will only be verified once all details are submitted to Checkin. Guest information collected by Chekin will show up in the 'Custom Fields' section of booking details.",
      Includes: [
        {
          header: "Send guest data to local authorities",
        },
        {
          header: "Collect information for multiple guests",
        },
      ],
      Name: "Chekin - Authority reporting",
      Provider: "chekin",
      "Subscription ID": "chk",
      Verifier: "chekin",
      cover_photo_url:
        "https://enso-image-assets.s3.amazonaws.com/banners/chekin_banner.png",
      icon_url:
        "https://chekin.com/wp-content/uploads/2021/05/chekin-logo-new.svg",
    },
  ],
};

export const serviceProviderMap = {
  yale: {
    provider: "yale",
    name: "smartlock",
    service: "sl",
    title: "Yale",
    details:
      "Your Yale account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/yale_logo.png",
  },
  igloo: {
    provider: "igloo",
    name: "smartlock",
    service: "sl",
    title: "Igloohome",
    details:
      "Your Igloo account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/igloohome_logo.jpg",
  },
  nuki: {
    provider: "nuki",
    name: "smartlock",
    service: "sl",
    title: "Nuki",
    details:
      "Your Nuki account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/nuki_logo.png",
  },
  salto: {
    provider: "salto",
    name: "smartlock",
    service: "sl",
    title: "Salto",
    details:
      "Your Salto account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/salto_logo.png",
  },
  partee: {
    provider: "partee",
    name: "verification",
    title: "Partee",
    url: "https://partee.es",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/partee.png",
    policies: [
      ["terms & conditions", "https://partee.es/condiciones-del-servicio/"],
      ["privacy policy", "https://partee.es/politica-de-privacidad/"],
    ],
    service: "ver",
  },
  autohost: {
    provider: "autohost",
    name: "autohost",
    service: "ver",
    title: "Autohost",
    details:
      "Autohost independently verifies guests during the booking process through advanced screening technology, minimizing risk and chargebacks by preventing troublesome guests from booking properties.",
    url: "https://autohost.ai",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/autohost.png",
    policies: [
      ["SaaS agreement", "https://www.autohost.ai/saas-agreement"],
      ["privacy policy", "https://www.autohost.ai/en/privacy"],
    ],
  },
  chekin: {
    provider: "chekin",
    name: "chekin",
    service: "ver",
    title: "Chekin",
    details:
      "Chekin independently verifies guests during the booking process through advanced screening technology, minimizing risk and chargebacks by preventing troublesome guests from booking properties and reports to local autorities.",
    url: "https://chekin.com/",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/chekin.png",
    policies: [
      ["Terms of Use", "https://chekin.com/en/terms/"],
      ["Privacy Policy (Hosts)", "https://chekin.com/en/privacy/"],
      [
        "Privacy Policy (Guests)",
        "https://chekin.com/en/generic-privacy-policy-of-accommodations/",
      ],
    ],
  },
  superhog: {
    provider: "superhog",
    name: "superhog",
    service: "ver",
    title: "Superhog",
    details:
      "Superhog collect guest IDs during the booking process, providing you with visibility on potential risks to prevent fraud and chargebacks in your properties. Superhog Guarantee will reimburse hosts through its insurance-backed Host Guarantee up to $5,000,000.",
    url: "https://superhog.com/",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/superhog.svg",
    policies: [
      [
        "terms of use",
        "https://superhog.com/wp-content/themes/superhog/guarantees/SUPERHOG-Terms-of-Use-v4.pdf",
      ],
      [
        "privacy policy",
        "https://superhog.com/privacy-policydata-protection-policy/",
      ],
    ],
  },
  august: {
    provider: "august",
    name: "smartlock",
    service: "sl",
    title: "August",
    details:
      "Your August account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/august.webp",
  },
  schlage: {
    provider: "schlage",
    name: "smartlock",
    service: "sl",
    title: "Schlage",
    details:
      "Your Schlage account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/schlage.svg",
  },
  smart: {
    provider: "smart",
    name: "smartlock",
    service: "sl",
    title: "Smart Things",
    details:
      "Your Smartthings account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/smart.png",
  },
  amazon: {
    provider: "amazon",
    name: "device",
    service: "sl",
    title: "Amazon",
    details:
      "Your Amazon account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/amazon.svg",
  },
  whatsapp: {
    provider: "whatsapp",
    name: "whatsapp",
    service: "msg",
    title: "Whatsapp",
    details:
      "Your Whatsapp account allows you to message your guests via Whatsapp in the Enso Connect unified inbox",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/whatsapp.png",
  },
  stripe: {
    provider: "stripe",
    name: "stripe",
    service: "pay",
    title: "Stripe",
    details:
      "Your Stripe account allows you to create upsells & fees and receive payouts for your guests' purchases.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/stripe.png",
  },
  minut: {
    provider: "minut",
    name: "noisesensor",
    title: "Minut",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/minut.png",
    details:
      "Your Minut account allows you to manage your smart noise sensor in Enso Connect and be alerted when guests are noisy.",
    service: "sl",
  },
  ttlock: {
    provider: "ttlock",
    name: "smartlock",
    service: "sl",
    title: "TTLock",
    details:
      "Your TTLock account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/ttlock.webp",
  },
  kwikset: {
    provider: "kwikset",
    name: "smartlock",
    service: "sl",
    title: "Kwikset",
    details:
      "Your Qwikset account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/kwikset.png",
  },
  "4suites": {
    provider: "4suites",
    name: "smartlock",
    service: "sl",
    title: "4Suites",
    details:
      "Your 4Suites account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/4suites.png",
  },
  wyze: {
    provider: "wyze",
    name: "smartlock",
    service: "sl",
    title: "Wyze",
    details:
      "Your Wyze account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/wyze.png",
  },
  brivo: {
    provider: "brivo",
    name: "smartlock",
    service: "sl",
    title: "Brivo",
    details:
      "Your Brivo account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/brivo.png",
  },
  tedee: {
    provider: "tedee",
    name: "smartlock",
    service: "sl",
    title: "Tedee",
    details:
      "Your Tedee account allows you to manage your devices in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/tedee.png",
  },
  latch: {
    provider: "latch",
    name: "smartlock",
    service: "sl",
    title: "Latch",
    details:
      "Your Latch account allows you to manage your devices in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/latch.png",
  },
  dormakaba_oracode: {
    provider: "dormakaba_oracode",
    name: "smartlock",
    service: "sl",
    title: "Dormakaba Oracode",
    details:
      "Your Oracode account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/dormakaba_oracode.png",
  },
  lockly: {
    provider: "lockly",
    name: "smartlock",
    service: "sl",
    title: "Lockly",
    details:
      "Your Lockly account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/lockly.jpg",
  },
  noiseaware: {
    provider: "noiseaware",
    name: "noisesensor",
    service: "sl",
    title: "NoiseAware",
    details:
      "Your Noiseaware account allows you to manage your smart noise sensor in Enso Connect and be alerted when guests are noisy.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/noiseaware.png",
  },
};

export const integrationSpecs = [
  {
    integration_type: "gy",
    name: "guesty",
    provider: "gy",
    service: "pms",
    title: "Guesty",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/guesty.svg",
    signup_type: "api_key",
    signup_fields: {
      gy_jwt_token: {
        name: "Guesty Access Token",
      },
    },
    details:
      "Your Guesty account allows you to manage your Guesty guests & listings in Enso Connect.",
    experience_name: "guesty",
    signup_text:
      '\nYou can connect with your Guesty account and import all of your listings (Rooms) and bookings (Reservations).\nFirst you must connect EnsoConnect\'s app in <a href="https://www.guesty.com" target="_blank">Guesty\'s markeplace</a>.\nOnce it\'s connected, go to <b>Marketplace</b> and find <b>EnsoConnect</b>, then click <b>it</b>.\nIn the top right corner you will see an <b>API Key rectangle</b>, that contains your <b>Access token</b>.\nCopy and paste the token in the following field:        \n',
  },
  {
    integration_type: "ha",
    name: "hostaway",
    provider: "ha",
    service: "pms",
    title: "Hostaway",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/hostaway.svg",
    signup_type: "api_key",
    signup_fields: {
      ha_client_secret: {
        name: "Hostaway API Key",
      },
      ha_host_id: {
        name: "Hostaway Account ID",
      },
    },
    details:
      "Your Hostaway account allows you to manage your Hostaway guests & listings in Enso Connect.",
    signup_text:
      '\nYou can connect with your Hostaway account and import all of your listings (Rooms) and bookings (Reservations).\nFirst you must connect EnsoConnect\'s app in <a href="https://dashboard.hostaway.com/" target="_blank">Hostaway\'s markeplace</a>.\nOnce it\'s connected, go to <b>Settings/Hostaway API</b> and click on the <b>Create</b> Button.\nIn the name field put <b>EnsoConnect</b> and click <b>Create</bAT\nCopy and paste both <b>Account ID</b> and <b>API Key</b> into the corresponding following fields \n        ',
  },
  {
    integration_type: "trk",
    name: "track",
    provider: "trk",
    service: "pms",
    title: "Track",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/track_logo.svg",
    signup_type: "api_key",
    signup_fields: {
      trk_customer_domain: {
        name: "Track Customer Domain",
      },
      trk_server_key: {
        name: "Track Server Key",
      },
      trk_secret_key: {
        name: "Track Secret Key",
      },
    },
    details:
      "Your Track account allows you to manage your Track guests & listings in Enso Connect.",
    signup_text:
      '\nYou can connect with your TRACK account and import all of your listings (Units) and bookings (Reservations).\nCopy the URL you use to sign-into to your TRACK account and append it with "/api/" (e.g., https://*test*.trackhs.com/api/). \nEnter it in "Track Customer Domain." Then in your TRACK dashboard, go to your Configuration page \n(the gear icon in the top right of the screen) and under the Company Setup tab, click API keys. \nThen create a new API key, copy the key and secret and paste them here.\n<strong>IMPORTANT: Channel keys are not supported</strong>.\n',
  },
  {
    integration_type: "mews",
    name: "mews",
    provider: "mews",
    service: "pms",
    title: "Mews",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/mews-logo.png",
    signup_type: "api_key",
    signup_fields: {
      mews_access_token: {
        name: "Mews Access Token",
      },
    },
    details:
      "Your Mews account allows you to manage your Mews guests & listings in Enso Connect.",
    signup_text:
      '\nYou can connect with your Mews account and import all of your listings (Rooms) and bookings (Reservations).\nFirst you must install EnsoConnect\'s app in <a href="https://www.mews.com/en/products/marketplace" target="_blank">Mews\' marketplace</a>.\nOnce it\'s installed, go to <b>Marketplace &gt; My Subscriptions</b> and find <b>EnsoConnect</b>, then click <b>Edit</b>.\nIn the top right corner you will see a <b>Key Icon</b>, click on it to reveal your <b>Access token</b>.\nCopy and paste the token in the following field: \n        ',
  },
  {
    integration_type: "bks",
    name: "bookingsync",
    provider: "bks",
    service: "pms",
    title: "Bookingsync",
    details:
      "Your Bookingsync account allows you to manage your Bookingsync guests & listings in Enso Connect.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/bookingsync.svg",
    signup_type: "redirect",
  },
  {
    integration_type: "lmpm",
    name: "lmpm",
    service: "pms",
    title: "LMPM",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/lmpm_logo.svg",
    signup_type: "api_key",
    signup_fields: {
      lmpm_company_id: {
        name: "LMPM Company ID",
      },
    },
    details:
      "Your Lmpm account allows you to manage your Lmpm guests & listings in Enso Connect.",
    signup_text:
      "\nConnect your LMPM account by enabling the <b>Enso {\"integration_type\": Integration<b> in the integrations section of your <b>LMPM Dashboard<b> \n(click the 3 bars at the top right then click 'Settings' > '{\"integration_type\": Integrations') and provide us your <b>Company ID<b>\n        ",
  },
  {
    integration_type: "lg",
    name: "lodgify",
    service: "pms",
    title: "Lodgify",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/lodgify.png",
    signup_type: "api_key",
    signup_fields: {
      api_key: {
        name: "Lodgify API Key",
      },
    },
    details:
      "Your Lodgify account allows you to manage your Lodgify guests & listings in Enso Connect.",
    signup_text:
      "\nYou can connect with your Lodgify account and import all of your listings (Rooms) and bookings (Reservations).\nLog into Lodgify, go to <b>Settings &gt; Public API</b> and find the <b>Personal API key</b>.\nCopy and paste this into the below field: \n        ",
  },
  {
    integration_type: "hfl",
    name: "hostfully",
    service: "pms",
    title: "Hostfully",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/hostfully_logo.png",
    signup_type: "api_key",
    signup_fields: {
      hostfully_agency_uid: {
        name: "Hostfully Agency UID",
      },
    },
    details:
      "Your Hostfully account allows you to manage your Hostfully guests & listings in Enso Connect.",
    signup_text:
      "\nYou can connect with your Hostfully account and import all of your listings (Properties) and bookings (Leads).\nLog into Hostfully, go to <b>{\"integration_type\": Integrations</b> and find the <b>Enso Connect</b> integration and enable it.\nNext, <b>select</b> all the Hostfully listings you would like synced and select the <b>enable</b> option.\nOnce complete, please paste your Hostfully <a target='_blank' href='https://help.hostfully.com/en/articles/4112286-find-your-agency-uid'>Agency UID</a> into the below field: \n        ",
  },
  {
    integration_type: "st",
    sleeptime_milliseconds: 250,
    name: "streamline",
    service: "pms",
    title: "Streamline",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/streamline-logo.png",
    details:
      "Your Streamline account allows you to manage your Streamline guests & listings in Enso Connect.",
    signup_text:
      '\nConnect with your Streamline account and import all of your listings (Properties) and bookings (Reservations).\nFirst, find the token key and secret key in Streamline by looking under the Third Party {"integration_type": Integrations section in the Main Menu on Streamline. \nOnce you click "Self-Sevice Onboarding" then you will need to click "Get Tokens for Enso Connect". Please fill these in below.\n        ',
    signup_fields: {
      streamline_token_key: {
        name: "Streamline token key",
      },
      streamline_token_secret: {
        name: "Streamline token secret",
      },
    },
    signup_type: "api_key",
  },
  {
    integration_type: "htf",
    name: "hostify",
    service: "pms",
    title: "Hostify",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/hostify-logo.png",
    signup_type: "api_key",
    signup_fields: {
      api_key: {
        name: "Hostify API Key",
      },
    },
    details:
      "Your Hostify account allows you to manage your Hostify guests & listings in Enso Connect.",
    signup_text:
      '\n        <img width="100%" src="https://enso-image-assets.s3.amazonaws.com/brandicons/hostify-screenshot.png" alt="api key location">\nYou can connect with your Hostify account and import all of your listings and bookings (Reservations).\nLog into Hostify, go to <b>Settings</b> and find the <b>API key</b>.\nCopy and paste this into the below field: \n        ',
  },
  {
    integration_type: "ru",
    name: "rentalsunited",
    service: "pms",
    title: "Rentals United",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/rentalsunited.svg",
    signup_type: "api_key",
    signup_fields: {
      username: {
        name: "Rentals United User Name",
      },
    },
    signup_text:
      "\nYou can connect with your Rentals United account and import all of your listings and bookings (Reservations) by entering your username/email below.\n        ",
  },
  {
    integration_type: "b24",
    name: "beds24",
    service: "pms",
    title: "Beds24",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/beds24-logo.png",
    signup_type: null,
  },
  {
    integration_type: "ba",
    name: "booking_automation",
    service: "pms",
    title: "Booking Automation",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/booking-automation-logo.png",
    signup_type: null,
  },
  {
    integration_type: "cb",
    name: "cloudbeds",
    service: "pms",
    title: "Cloudbeds",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/cloudbeds-logo.png",
    signup_type: null,
  },
  {
    integration_type: "ifr",
    name: "inforhms",
    service: "pms",
    title: "Infor HMS",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/infor-logo.png",
    signup_type: null,
  },
  {
    integration_type: "myvr",
    name: "myvr",
    service: "pms",
    title: "MyVR",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/myvr-logo.png",
    signup_type: null,
  },
  {
    integration_type: "opra",
    name: "opera",
    service: "pms",
    title: "Oracle Opera",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/oracle-logo.png",
    signup_type: null,
  },
  {
    integration_type: "stay",
    name: "stayntouch",
    service: "pms",
    title: "StayNTouch",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/stayntouch-logo.png",
    signup_type: null,
  },
  {
    integration_type: "vrbo",
    name: "vrbo",
    service: "pms",
    title: "VRBO",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/vrbo.svg",
    signup_type: null,
  },
  {
    integration_type: "ab",
    name: "leavetown",
    service: "pms",
    title: "Leavetown AirBnB",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/airbnb.svg",
    signup_type: null,
  },
  {
    integration_type: "abnb",
    name: "airbnb",
    service: "pms",
    title: "AirBnB",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/airbnb.svg",
    signup_type: null,
  },
  {
    integration_type: "ownrz",
    name: "ownerrez",
    service: "pms",
    title: "OwnerRez",
    sleeptime_milliseconds: 4000,
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/ownerrez_title_logo.webp",
    details:
      "Your Ownerrez account allows you to manage your Ownerrez guests & listings in Enso Connect.",
    signup_type: "redirect",
  },
  {
    integration_type: "v365",
    service: "pms",
    title: "365 Villas",
    name: "365v",
    icon: "https://secure.365villas.com/images/logo/4.0.365villas.logo.svg",
    signup_type: "api_key",
    signup_fields: {
      key: {
        name: "365 Villas API Key",
      },
      pass: {
        name: "365 Villas API Pass",
      },
      owner_token: {
        name: "365 Owner Token",
      },
    },
    signup_text:
      "\n        To add your 365 Villas account to Enso Connect please enter your 365 Villas Credentials. To obtain these\n        first login to 365 Villas Portal. In the top right corner, click your account name acronym.\n        \n        <img src='https://enso-image-assets.s3.amazonaws.com/brandicons/365villa-home.png' width='100%' />\n        \n        Then click \"API & Integrations\". Here you will find the credentials you need.\n        \n        <img src='https://enso-image-assets.s3.amazonaws.com/brandicons/365villa-credentials.png' height=\"250px\" style=\"display: block; margin-left: auto; margin-right: auto;\" />\n        ",
  },
  {
    integration_type: "avt",
    service: "pms",
    title: "Avantio",
    name: "avantio",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/avantio.webp",
    signup_type: "api_key",
    signup_fields: {
      avt_provider_id: {
        name: "Avantio Provider ID",
      },
      avt_username: {
        name: "Avantio Username",
      },
      avt_password: {
        name: "Avantio Password",
      },
    },
    details:
      "Your Avantio account allows you to manage your Avantio guests & listings in Enso Connect.",
    signup_text:
      "\n        Please raise a ticket with the Customer Care team on Avantio and ask for a Provider ID, username and password.\n        After you have received these details, please enter them in the fields below.\n        ",
    delete_text: "",
  },
  {
    provider: "yale",
    name: "smartlock",
    service: "sl",
    title: "Yale",
    details:
      "Your Yale account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/yale_logo.png",
  },
  {
    provider: "igloo",
    name: "smartlock",
    service: "sl",
    title: "Igloohome",
    details:
      "Your Igloo account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/igloohome_logo.jpg",
  },
  {
    provider: "nuki",
    name: "smartlock",
    service: "sl",
    title: "Nuki",
    details:
      "Your Nuki account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/nuki_logo.png",
  },
  {
    provider: "salto",
    name: "smartlock",
    service: "sl",
    title: "Salto",
    details:
      "Your Salto account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/salto_logo.png",
  },
  {
    provider: "partee",
    name: "verification",
    title: "Partee",
    url: "https://partee.es",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/partee.png",
    policies: [
      ["terms & conditions", "https://partee.es/condiciones-del-servicio/"],
      ["privacy policy", "https://partee.es/politica-de-privacidad/"],
    ],
    service: "ver",
  },
  {
    provider: "autohost",
    name: "autohost",
    service: "ver",
    title: "Autohost",
    details:
      "Autohost independently verifies guests during the booking process through advanced screening technology, minimizing risk and chargebacks by preventing troublesome guests from booking properties.",
    url: "https://autohost.ai",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/autohost.png",
    policies: [
      ["SaaS agreement", "https://www.autohost.ai/saas-agreement"],
      ["privacy policy", "https://www.autohost.ai/en/privacy"],
    ],
  },
  {
    provider: "chekin",
    name: "chekin",
    service: "ver",
    title: "Chekin",
    details:
      "Chekin independently verifies guests during the booking process through advanced screening technology, minimizing risk and chargebacks by preventing troublesome guests from booking properties and reports to local autorities.",
    url: "https://chekin.com/",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/chekin.png",
    policies: [
      ["Terms of Use", "https://chekin.com/en/terms/"],
      ["Privacy Policy (Hosts)", "https://chekin.com/en/privacy/"],
      [
        "Privacy Policy (Guests)",
        "https://chekin.com/en/generic-privacy-policy-of-accommodations/",
      ],
    ],
  },
  {
    provider: "superhog",
    name: "superhog",
    service: "ver",
    title: "Superhog",
    details:
      "Superhog collect guest IDs during the booking process, providing you with visibility on potential risks to prevent fraud and chargebacks in your properties. Superhog Guarantee will reimburse hosts through its insurance-backed Host Guarantee up to $5,000,000.",
    url: "https://superhog.com/",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/superhog.svg",
    policies: [
      [
        "terms of use",
        "https://superhog.com/wp-content/themes/superhog/guarantees/SUPERHOG-Terms-of-Use-v4.pdf",
      ],
      [
        "privacy policy",
        "https://superhog.com/privacy-policydata-protection-policy/",
      ],
    ],
  },
  {
    provider: "august",
    name: "smartlock",
    service: "sl",
    title: "August",
    details:
      "Your August account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/august.webp",
  },
  {
    provider: "schlage",
    name: "smartlock",
    service: "sl",
    title: "Schlage",
    details:
      "Your Schlage account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/schlage.svg",
  },
  {
    provider: "smart",
    name: "smartlock",
    service: "sl",
    title: "Smart Things",
    details:
      "Your Smartthings account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/smart.png",
  },
  {
    provider: "amazon",
    name: "device",
    service: "sl",
    title: "Amazon",
    details:
      "Your Amazon account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/amazon.svg",
  },
  {
    provider: "whatsapp",
    name: "whatsapp",
    service: "msg",
    title: "Whatsapp",
    details:
      "Your Whatsapp account allows you to message your guests via Whatsapp in the Enso Connect unified inbox",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/whatsapp.png",
  },
  {
    provider: "stripe",
    name: "stripe",
    service: "pay",
    title: "Stripe",
    details:
      "Your Stripe account allows you to create upsells & fees and receive payouts for your guests' purchases.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/stripe.png",
  },
  {
    provider: "minut",
    name: "noisesensor",
    title: "Minut",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/minut.png",
    details:
      "Your Minut account allows you to manage your smart noise sensor in Enso Connect and be alerted when guests are noisy.",
    service: "sl",
  },
  {
    provider: "ttlock",
    name: "smartlock",
    service: "sl",
    title: "TTLock",
    details:
      "Your TTLock account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/ttlock.webp",
  },
  {
    provider: "kwikset",
    name: "smartlock",
    service: "sl",
    title: "Kwikset",
    details:
      "Your Qwikset account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/kwikset.png",
  },
  {
    provider: "4suites",
    name: "smartlock",
    service: "sl",
    title: "4Suites",
    details:
      "Your 4Suites account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/4suites.png",
  },
  {
    provider: "wyze",
    name: "smartlock",
    service: "sl",
    title: "Wyze",
    details:
      "Your Wyze account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/wyze.png",
  },
  {
    provider: "brivo",
    name: "smartlock",
    service: "sl",
    title: "Brivo",
    details:
      "Your Brivo account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/brivo.png",
  },
  {
    provider: "tedee",
    name: "smartlock",
    service: "sl",
    title: "Tedee",
    details:
      "Your Tedee account allows you to manage your devices in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/tedee.png",
  },
  {
    provider: "latch",
    name: "smartlock",
    service: "sl",
    title: "Latch",
    details:
      "Your Latch account allows you to manage your devices in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-image-assets.s3.amazonaws.com/brandicons/latch.png",
  },
  {
    provider: "dormakaba_oracode",
    name: "smartlock",
    service: "sl",
    title: "Dormakaba Oracode",
    details:
      "Your Oracode account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/dormakaba_oracode.png",
  },
  {
    provider: "lockly",
    name: "smartlock",
    service: "sl",
    title: "Lockly",
    details:
      "Your Lockly account allows you to manage your smart locks in Enso Connect and provide seamless access to your guests.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/lockly.jpg",
  },
  {
    provider: "noiseaware",
    name: "noisesensor",
    service: "sl",
    title: "NoiseAware",
    details:
      "Your Noiseaware account allows you to manage your smart noise sensor in Enso Connect and be alerted when guests are noisy.",
    icon: "https://enso-icons.s3.amazonaws.com/title_logos/noiseaware.png",
  },
];

export const guestStage = {
  inquiry: "inquiry",
  lead: "lead",
  opportunity: "opportunity",
  closed_won: "closed_won",
  closed_lost: "closed_lost",
  waiting_lost: "waiting_lost",
};

export const guestTagEnum = {
  last_minute_traveler: "last_minute",
  holiday_traveler: "holiday",
  thanksgiving_traveler: "thanksgiving",
};

export const guestTypeEnum = {
  guest: "guest",
  inquiry: "inquiry",
  owner: "owner",
  maintenance: "maintenance",
  cleaner: "cleaner",
};

export const guidebookTypeEnum = {
  listing_guide: "Listing guide",
  place: "Place",
  dining: "Dining",
  attractions: "Attractions",
  work: "Work",
  transport: "Transport",
};

export const localServiceTypeEnum = {
  transportation: "Transportation",
  luggage: "Luggage",
  food_and_beverage: "Food and beverage",
  experiences: "Experiences",
  guidebooks: "Guidebooks",
  healthcare: "Healthcare",
  other: "Other",
  car_rentals: "Car rentals",
  airlines: "Airlines",
  tickets: "Tickets",
  groceries: "Groceries",
  laundry: "Laundry",
  guides: "Guides",
  gifts: "Gifts",
  infants_and_children: "Infants and children",
  flowers: "Flowers",
  activity_equipment: "Activity equipment",
  classes: "Classes",
  shopping: "Shopping",
};

export const bookingStatusEnum = {
  inquiry: "inquiry",
  pending: "pending",
  confirmed: "confirmed",
  declined: "declined",
  checked_in: "checked_in",
  checked_out: "checked_out",
  cancelled: "cancelled",
  expired_guest: "expired_guest",
  expired_host: "expired_host",
  pending_guest: "pending_guest",
};

export const monthEnum = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

export const languageEnum = {
  Abkhazian: "ab",
  Afar: "aa",
  Afrikaans: "af",
  Akan: "ak",
  Albanian: "sq",
  Amharic: "am",
  Arabic: "ar",
  Armenian: "hy",
  Aymara: "ay",
  Azerbaijani: "az",
  Bambara: "bm",
  Bashkir: "ba",
  Basque: "eu",
  Belarusian: "be",
  Bengali: "bn",
  Bihari: "bh",
  Bislama: "bi",
  Bosnian: "bs",
  Breton: "br",
  Bulgarian: "bg",
  Burmese: "my",
  Catalan: "ca",
  Chamorro: "ch",
  Chechen: "ce",
  Chinese: "zh",
  Chuvash: "cv",
  Cornish: "kw",
  Corsican: "co",
  Cree: "cr",
  Croatian: "hr",
  Czech: "cs",
  Danish: "da",
  Maldivian: "dv",
  Dutch: "nl",
  Dzongkha: "dz",
  English: "en",
  Esperanto: "eo",
  Estonian: "et",
  Ewe: "ee",
  Faroese: "fo",
  Fijian: "fj",
  Finnish: "fi",
  French: "fr",
  Fulah: "ff",
  Galician: "gl",
  Georgian: "ka",
  German: "de",
  Greek: "el",
  Guarani: "gn",
  Gujarati: "gu",
  Haitian: "ht",
  Hausa: "ha",
  Hebrew: "he",
  Herero: "hz",
  Hindi: "hi",
  Hungarian: "hu",
  Indonesian: "id",
  Irish: "ga",
  Igbo: "ig",
  Inupiaq: "ik",
  Ido: "io",
  Icelandic: "is",
  Italian: "it",
  Inuktitut: "iu",
  Japanese: "ja",
  Javanese: "jv",
  Greenlandic: "kl",
  Kannada: "kn",
  Kanuri: "kr",
  Kashmiri: "ks",
  Kazakh: "kk",
  Khmer: "km",
  Kikuyu: "ki",
  Kinyarwanda: "rw",
  Kirghiz: "ky",
  Komi: "kv",
  Kongo: "kg",
  Korean: "ko",
  Kurdish: "ku",
  Kuanyama: "kj",
  Luxembourgish: "lb",
  Ganda: "lg",
  Limburgan: "li",
  Lingala: "ln",
  Lao: "lo",
  Lithuanian: "lt",
  LubaKatanga: "lu",
  Latvian: "lv",
  Manx: "gv",
  Macedonian: "mk",
  Malagasy: "mg",
  Malay: "ms",
  Malayalam: "ml",
  Maltese: "mt",
  Maori: "mi",
  Marathi: "mr",
  Marshallese: "mh",
  Mongolian: "mn",
  Nauru: "na",
  Navajo: "nv",
  North: "nd",
  Nepali: "ne",
  Bokm: "nb",
  Norwegian: "no",
  Nuosu: "ii",
  Ndebele: "nr",
  Occitan: "oc",
  Ojibwa: "oj",
  Slavonic: "cu",
  Oromo: "om",
  Oriya: "or",
  Ossetic: "os",
  Punjabi: "pa",
  Pali: "pi",
  Persian: "fa",
  Polish: "pl",
  Portuguese: "pt",
  Quechua: "qu",
  Romansh: "rm",
  Rundi: "rn",
  Romanian: "ro",
  Russian: "ru",
  Sanskrit: "sa",
  Sindhi: "sd",
  Sami: "se",
  Samoan: "sm",
  Sango: "sg",
  Serbian: "sr",
  Gaelic: "gd",
  Shona: "sn",
  Sinhalese: "si",
  Slovak: "sk",
  Slovenian: "sl",
  Somali: "so",
  Sotho: "st",
  Spanish: "es",
  Sundanese: "su",
  Swahili: "sw",
  Swati: "ss",
  Swedish: "sv",
  Tamil: "ta",
  Telugu: "te",
  Tajik: "tg",
  Thai: "th",
  Tigrinya: "ti",
  Tibetan: "bo",
  Turkmen: "tk",
  Tagalog: "tl",
  Tswana: "tn",
  Tonga: "to",
  Turkish: "tr",
  Tatar: "tt",
  Twi: "tw",
  Tahitian: "ty",
  Uighur: "ug",
  Ukrainian: "uk",
  Urdu: "ur",
  Uzbek: "uz",
  Venda: "ve",
  Vietnamese: "vi",
  Walloon: "wa",
  Welsh: "cy",
  Wolof: "wo",
  Frisian: "fy",
  Xhosa: "xh",
  Yiddish: "yi",
  Yoruba: "yo",
  Zhuang: "za",
  Zulu: "zu",
};

export const currencyEnum = {
  USD: "USD",
  AED: "AED",
  AFN: "AFN",
  ALL: "ALL",
  AMD: "AMD",
  ANG: "ANG",
  AOA: "AOA",
  ARS: "ARS",
  AUD: "AUD",
  AWG: "AWG",
  AZN: "AZN",
  BAM: "BAM",
  BBD: "BBD",
  BDT: "BDT",
  BGN: "BGN",
  BHD: "BHD",
  BIF: "BIF",
  BMD: "BMD",
  BND: "BND",
  BOB: "BOB",
  BOV: "BOV",
  BRL: "BRL",
  BSD: "BSD",
  BTN: "BTN",
  BWP: "BWP",
  BYN: "BYN",
  BZD: "BZD",
  CAD: "CAD",
  CDF: "CDF",
  CHE: "CHE",
  CHF: "CHF",
  CHW: "CHW",
  CLF: "CLF",
  CLP: "CLP",
  CNY: "CNY",
  COP: "COP",
  COU: "COU",
  CRC: "CRC",
  CUC: "CUC",
  CUP: "CUP",
  CVE: "CVE",
  CZK: "CZK",
  DJF: "DJF",
  DKK: "DKK",
  DOP: "DOP",
  DZD: "DZD",
  EGP: "EGP",
  ERN: "ERN",
  ETB: "ETB",
  EUR: "EUR",
  FJD: "FJD",
  FKP: "FKP",
  GBP: "GBP",
  GEL: "GEL",
  GHS: "GHS",
  GIP: "GIP",
  GMD: "GMD",
  GNF: "GNF",
  GTQ: "GTQ",
  GYD: "GYD",
  HKD: "HKD",
  HNL: "HNL",
  HRK: "HRK",
  HTG: "HTG",
  HUF: "HUF",
  IDR: "IDR",
  ILS: "ILS",
  INR: "INR",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "ISK",
  JMD: "JMD",
  JOD: "JOD",
  JPY: "JPY",
  KES: "KES",
  KGS: "KGS",
  KHR: "KHR",
  KMF: "KMF",
  KPW: "KPW",
  KRW: "KRW",
  KWD: "KWD",
  KYD: "KYD",
  KZT: "KZT",
  LAK: "LAK",
  LBP: "LBP",
  LKR: "LKR",
  LRD: "LRD",
  LSL: "LSL",
  LYD: "LYD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MNT: "MNT",
  MOP: "MOP",
  MRU: "MRU",
  MUR: "MUR",
  MVR: "MVR",
  MWK: "MWK",
  MXN: "MXN",
  MXV: "MXV",
  MYR: "MYR",
  MZN: "MZN",
  NAD: "NAD",
  NGN: "NGN",
  NIO: "NIO",
  NOK: "NOK",
  NPR: "NPR",
  NZD: "NZD",
  OMR: "OMR",
  PAB: "PAB",
  PEN: "PEN",
  PGK: "PGK",
  PHP: "PHP",
  PKR: "PKR",
  PLN: "PLN",
  PYG: "PYG",
  QAR: "QAR",
  RON: "RON",
  RSD: "RSD",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SAR",
  SBD: "SBD",
  SCR: "SCR",
  SDG: "SDG",
  SEK: "SEK",
  SGD: "SGD",
  SHP: "SHP",
  SLL: "SLL",
  SOS: "SOS",
  SRD: "SRD",
  SSP: "SSP",
  STN: "STN",
  SVC: "SVC",
  SYP: "SYP",
  SZL: "SZL",
  THB: "THB",
  TJS: "TJS",
  TMT: "TMT",
  TND: "TND",
  TOP: "TOP",
  TRY: "TRY",
  TTD: "TTD",
  TWD: "TWD",
  TZS: "TZS",
  UAH: "UAH",
  UGX: "UGX",
  ZAR: "ZAR",
};

export const productsFees = {
  flex: {
    product_type: "flex",
    name: "Damage Waiver or Safety Deposit",
    header: "Offer guests a choice of insurance.",
    color: "#5E8CF4",
    description:
      "<p>We know accidents may happen. This covers any property damage that occurred during your stay. </p><p><br></p><p>You choose between two options:</p><ul><li>Security Deposit - Pay a larger fee that is refundableif the property was not damaged in any way. Please note that the refund may take up to 14 days depending on your credit card company.</li><li>Damage Waiver - Pay a smaller one-time non-refundable fee that can help us cover small damages and wear and tear.</li></ul>",
    fields: [
      {
        path: "charge_card",
        type: "charge_card",
        label: "How would you like to collect your deposit?",
      },
    ],
    skus: [
      {
        name: "Damage Waiver",
        unit: "1",
        value: 10,
        flex_product_id: "fee",
      },
      {
        name: "Safety Deposit",
        unit: "1",
        value: 100,
        charge_card: false,
        flex_product_id: "deposit",
      },
    ],
  },
  fee: {
    product_type: "fee",
    name: "Damage Waiver",
    header: "Self-insure with a non-refundable damage waiver",
    color: "#5E8CF4",
    requires_stripe: true,
    description:
      "<p><span>We know accidents may happen. This is a non-refundable fee to cover any small damages and wear and tear that may have occurred during your stay.</span></p>",
    fields: [],
    skus: [
      {
        unit: "1",
        value: 10,
      },
    ],
  },
  pet_fee: {
    product_type: "pet_fee",
    name: "Pet Fee",
    header: "Charge per pet, pet night, per pet per night & more",
    color: "#5E8CF4",
    description:
      "<p>We hope you enjoy your stay with your furry friends!</p><p><br></p><p>In order to provide the best experience for you and your petswe charge a pet fee to cover the additional cleaning costs.</p>",
    requires_stripe: true,
    conditions: [],
    fields: [],
    skus: [
      {
        unit: "1",
        value: 10,
      },
    ],
  },
  deposit: {
    product_type: "deposit",
    name: "Safety Deposit",
    header: "Place a hold on your guest's card or collect funds immediately",
    color: "#5E8CF4",
    description:
      "<p><span>We know accidents may happen. This covers any property damage that occurred during your stay. </span></p><p><br></p><p><span>The security deposit is refundable if the property was not damaged in any way. Please note that the refund may take up to 14 days depending on your credit card company.</span></p>",
    requires_stripe: true,
    fields: [
      {
        path: "charge_card",
        type: "charge_card",
        label: "How would you like to collect your deposit?",
      },
    ],
    skus: [
      {
        charge_card: false,
        unit: "1",
        value: 100,
      },
    ],
  },
};

export const productsUpsells = {
  gap_night: {
    product_type: "gap_night",
    name: "Extend your stay",
    header:
      "Offer stay extensions when your property is available past the check-out date",
    color: "#5E8CF4",
    description:
      "\ud83c\udf1f Extend Your Stay, Elevate Your Experience! \ud83c\udf1f<br><br>Enjoying your stay so far? Why not make the magic last a little longer? Our exclusive offer invites you to extend your stay and savor even more moments of relaxation and discovery.<br><br><p>Picture waking up to another morning of tranquil serenity, indulging in leisurely breakfasts, and embracing the day at your own pace. With an additional night, you have the luxury to explore the hidden gemsof our neighborhood, immerse yourself in local culture, or simply unwind in the cozy ambiance of your home away from home.</p></p><p><br></p><p>While we do our best to accommodate stay extensions, in the event that we are unable to accommodate your request, you will be notified and your card will not be charged.</p>",
    requires_stripe: true,
    fields: [
      {
        type: "number",
        label: "Number of extra nights",
        path: "extra_nights",
        info_text: [
          "This option will only be presented if there are no upcoming bookings within the specific number of nights. This does not take into account owner blocks, so we recommend manually approving this upsell.",
        ],
      },
    ],
    skus: [
      {
        name: "1 Extra Night",
        unit: "1",
        value: 100,
        properties: {
          extra_nights: 1,
        },
      },
      {
        name: "2 Extra Nights",
        unit: "1",
        value: 200,
        properties: {
          extra_nights: 2,
        },
      },
      {
        name: "3 Extra Nights",
        unit: "1",
        value: 300,
        properties: {
          extra_nights: 3,
        },
      },
    ],
  },
  checkin: {
    product_type: "checkin",
    name: "Arrange for an early check-in",
    header: "Earn money from every early check-in",
    color: "#5E8CF4",
    description:
      "<p>If you would like a head start on your vacation we offer early check-in. This can give you a chance to head down to the pool or site see and explore your home away from home.  </p><p><br></p><p>While we do our best to accommodate an early check-in for our guests, in the event that we are unable to accommodate your request, you will be notified and refunded.</p>",
    requires_stripe: true,
    fields: [
      {
        path: "checkin_time",
        type: "time",
        label: "Early Check-in Time",
      },
    ],
    skus: [
      {
        unit: "1",
        value: 30,
        checkin_time: "14:00",
      },
    ],
  },
  checkout: {
    product_type: "checkout",
    name: "Arrange for a late check-out",
    header: "Earn money from every late check-out",
    color: "#5E8CF4",
    description:
      "<p><span>Late check-out allows you to check out later than our standard check-out time, giving you the opportunity to take a shower after an early meeting, relax after a late night, or have some time to pack your suitcases at a leisurely pace. </span></p><p><br></p><p><span>While we do our best to accommodate a late check-out for our guests, in the event that we are unable to accommodate your request, you will be notified and refunded.</span></p>",
    requires_stripe: true,
    fields: [
      {
        path: "checkout_time",
        type: "time",
        label: "Late Check-out Time",
      },
    ],
    skus: [
      {
        unit: "1",
        value: 30,
        checkout_time: "13:00",
      },
    ],
  },
  stocked_fridge: {
    product_type: "custom_upsell",
    name: "Stocked Fridge",
    header: "Deliver food to your guest's fridge",
    color: "#5E8CF4",
    description:
      "<p><span>Enjoy the convenience of a fully stocked fridge! Choose from drinks & snacks and/or meals</span></p><p>Once you make your purchase, the items will be delivered directly to your unit & you will be notified when they arrive.</p><p>While we do our best to accommodate an early check-in for our guests, in the event that we are unable to accommodate your request, you will be notified and refunded.</p>",
    skus: [
      {
        unit: "1",
        name: "Drinks & Snacks",
        value: 30,
      },
      {
        unit: "1",
        name: "Continental Breakfast: (Eggs, bacon, bread, orange juice)",
        value: 30,
      },
    ],
  },
  mid_stay_clean: {
    product_type: "custom_upsell",
    description:
      "<p><span>Enjoy the feeling of freshly laundered & pressed linens!</span></p>We offer additional cleaning services that can be purchased in addition to the regular cleaning done before and after your stay. This is an optional service that you can choose if you desire more frequent cleaning during your stay.While we do our best to accommodate a mid-stay cleaning, in the event that we are unable to accommodate your request, you will be notified and refunded.",
    name: "Mid-stay Clean",
    header: "Clean the unit during the stay",
    color: "#5E8CF4",
    skus: [
      {
        unit: "1",
        name: "Change the sheets & towels",
        value: 80,
      },
      {
        unit: "1",
        name: "Full cleaning (sheets, towels, kitchen & living room)",
        value: 150,
      },
    ],
  },
  coffee_pods: {
    product_type: "custom_upsell",
    product_id: "coffee_pods",
    description:
      "<p><span>Start your day with a fresh cup of coffee! We will deliver coffee pods directly to your unit</span></p><p>While we do our best to accommodate an early check-in for our guests, in the event that we are unable to accommodate your request, you will be notified and refunded.</p>",
    name: "Coffee Pods",
    header: "Restock your guest's supply of coffee pods",
    color: "#5E8CF4",
    skus: [
      {
        unit: "1",
        name: "5 pods",
        value: 10,
      },
      {
        unit: "1",
        name: "20 pods",
        value: 25,
      },
    ],
  },
  welcome_basket: {
    product_type: "custom_upsell",
    description:
      "<p>Support the local community and discover the culture with a locally sourced welcome basket: a collection of items from local vendors and producers to get you started on your trip. </p><br><p>While we do our best to accommodate your welcome basket, in the event that we are unable to accommodate your request, you will be notified and refunded.</p>",
    name: "Welcome Basket",
    header: "Provide a welcome basket to your guests",
    color: "#5E8CF4",
    skus: [
      {
        unit: "1",
        value: 60,
      },
    ],
  },
  carbon_offset: {
    product_type: "custom_upsell",
    description:
      "Make your stay 100% carbon neutral by purchasing a carbon offset upgrade, with a portion of the fee going to a charity that works on carbon reduction initiatives!",
    name: "Carbon Offset Upgrade",
    manually_approve: false,
    header: "Make your guest's stay carbon neutral",
    color: "#5E8CF4",
    skus: [
      {
        unit: "1",
        value: 20,
      },
    ],
  },
  tip_staff: {
    product_type: "custom_upsell",
    description:
      "<p>We hope you are enjoying your stay with us and that our staff is providing you with exceptional service during your visit.</p><p><br>If you were pleased with your experience, we would greatly appreciate it if you could take a moment to leave a tip for our staff.Your generosity will help to ensure that our staff can continue to provide the high level of service that you have come to expect from us.</p><br/><p>You can leave a tip using your Boarding Pass - tips will be distributed fairly among the staff who contributed to your stay. </p><br>On behalf of our staff, we would like to thank you for your support and we hope to have the pleasure of welcoming you back soon!",
    name: "Tip the staff",
    header: "Receive digital tips for your business and staff",
    color: "#5E8CF4",
    skus: [
      {
        unit: "1",
        name: "Awesome service",
        value: 5,
      },
      {
        unit: "1",
        name: "Incredible service",
        value: 10,
      },
      {
        unit: "1",
        name: "Mega service",
        value: 30,
      },
    ],
  },
};

export const productsServices = {
  bird_scooters: {
    name: "Rent a Scooter",
    product_type: "bird_scooters",
    skus: [
      {
        url: "https://go.bird.co/",
      },
    ],
  },
  bolt: {
    name: "Get a ride in minutes.",
    product_type: "bolt",
    skus: [
      {
        url: "https://bolt.eu/",
      },
    ],
  },
  corner_shop: {
    name: "Get Groceries Delivered!!",
    product_type: "corner_shop",
    skus: [
      {
        url: "https://cornershopapp.com/",
      },
    ],
  },
  didi: {
    name: "Rent-a-car service",
    product_type: "didi",
    skus: [
      {
        url: "https://web.didiglobal.com/mx/",
      },
    ],
  },
  donkey_republic: {
    name: "Book a Donkey bike",
    product_type: "donkey_republic",
    skus: [
      {
        url: "https://www.donkey.bike/",
      },
    ],
  },
  drizly_alcohol_delivery_app: {
    name: "Order Alcohol deliveries!",
    product_type: "drizly_alcohol_delivery_app",
    skus: [
      {
        url: "https://drizly.com",
      },
    ],
  },
  flanders_bike_tours: {
    name: "Guided bike tours",
    product_type: "flanders_bike_tours",
    skus: [
      {
        url: "https://flandersbiketours.com/",
      },
    ],
  },
  free_now: {
    name: "Order a ride via the app.",
    product_type: "free_now",
    skus: [
      {
        url: "https://free-now.com/",
      },
    ],
  },
  "gett.": {
    name: "Gett. a taxi!",
    product_type: "gett.",
    skus: [
      {
        url: "https://b.gett.com/d/iPJlpemZBob",
      },
    ],
  },
  "go-jek": {
    name: "Your superapp",
    product_type: "go-jek",
    skus: [
      {
        url: "https://apps.apple.com/us/app/gojek/id944875099",
      },
    ],
  },
  grab: {
    name: "Order a ride via the app.",
    product_type: "grab",
    skus: [
      {
        url: "https://grab.onelink.me/2695613898?pid=PH-Website-QR_Code&c=Website_Download",
      },
    ],
  },
  hostfully: {
    name: "Learn more about your listing",
    product_type: "hostfully",
    skus: [
      {
        url: "https://hostfully.com",
      },
    ],
  },
  houstr: {
    name: "Shop your stay",
    product_type: "houstr",
    skus: [
      {
        url: "https://hstr.co/",
      },
    ],
  },
  hreyfill: {
    name: "Personalized tours & taxis",
    product_type: "hreyfill",
    skus: [
      {
        url: "https://www.hreyfill.is/en/",
      },
    ],
  },
  just_eat: {
    name: "Order from any restaurant!",
    product_type: "just_eat",
    skus: [
      {
        url: "https://app.adjust.com/oc511o_1455tm?utm_medium=internal&campaign=homepage",
      },
    ],
  },
  lime_scooters: {
    name: "Rent a Scooter",
    product_type: "lime_scooters",
    skus: [
      {
        url: "https://limebike.app.link/d/YQsZG161Boba",
      },
    ],
  },
  localbird: {
    name: "Your local fun navigator",
    product_type: "localbird",
    skus: [
      {
        url: "https://localbird.io/",
      },
    ],
  },
  lyft: {
    name: "Grab a Lyft!",
    product_type: "lyft",
    skus: [
      {
        url: "lyft://partner=YOUR_CLIENT_ID",
      },
    ],
  },
  minibar_delivery: {
    name: "Order Alcohol deliveries!",
    product_type: "minibar_delivery",
    skus: [
      {
        url: "https://minibardelivery.com/",
      },
    ],
  },
  mykonos_yacht_day_charter: {
    name: "Mykonos Yacht Day Charter",
    product_type: "mykonos_yacht_day_charter",
    skus: [
      {
        url: "https://cd-yachting.checkfront.com/reserve/",
      },
    ],
  },
  ok2charge: {
    name: "Local EV chargers",
    product_type: "ok2charge",
    skus: [
      {
        url: "http://www.ok2charge.com/electricvehiclechargingstations",
      },
    ],
  },
  radical_storage: {
    name: "Local luggage storage",
    product_type: "radical_storage",
    skus: [
      {
        url: "https://radicalstorage.com/",
      },
    ],
  },
  rappi: {
    name: "Local bars & restaurants",
    product_type: "rappi",
    skus: [
      {
        url: "https://www.rappi.com.mx/",
      },
    ],
  },
  sixt_rent_a_car: {
    name: "Rent a car in Belgium",
    product_type: "sixt_rent_a_car",
    skus: [
      {
        url: "https://www.sixt.com/car-rental/belgium/",
      },
    ],
  },
  skibutler: {
    name: "Ski/Snowboard Equipment Rental",
    product_type: "skibutler",
    skus: [
      {
        url: "https://www.skibutlers.com/",
      },
    ],
  },
  "stib/mivb": {
    name: "Take public transit",
    product_type: "stib/mivb",
    skus: [
      {
        url: "https://www.stib-mivb.be/index.htm?l=en",
      },
    ],
  },
  "taxi_&_mietwagen": {
    name: "Taxi in Straubing",
    product_type: "taxi_&_mietwagen",
    skus: [
      {
        url: "https://taxi-ostbayern.com/",
      },
    ],
  },
  the_goat: {
    name: "A place to connect, all day",
    product_type: "the_goat",
    skus: [
      {
        url: "https://www.lcgoat.com/mobile-app",
      },
    ],
  },
  tiqets: {
    name: "Tickets to local events",
    product_type: "tiqets",
    skus: [
      {
        url: "https://www.tiqets.com/en/",
      },
    ],
  },
  touchstay: {
    name: "Learn more about your listing",
    product_type: "touchstay",
    skus: [
      {
        url: "https://touchstay.com/",
      },
    ],
  },
  travelpayouts: {
    name: "Book an airport transfer",
    product_type: "travelpayouts",
    skus: [
      {
        url: "https://www.travelpayouts.com/en/offers/gettransfer-affiliate-program",
      },
    ],
  },
  uber_eats: {
    name: "Order from any restaurant!",
    product_type: "uber_eats",
    skus: [
      {
        url: "https://www.ubereats.com/",
      },
    ],
  },
  viator: {
    name: "Local sightseeing & tours",
    product_type: "viator",
    skus: [
      {
        url: "https://partnerresources.viator.com/",
      },
    ],
  },
  wind_scooters: {
    name: "Rent a Scooter",
    product_type: "wind_scooters",
    skus: [
      {
        url: "https://wind.yango.com/",
      },
    ],
  },
  wolt: {
    name: "Order from any restaurant!",
    product_type: "wolt",
    skus: [
      {
        url: "https://wolt.com/en",
      },
    ],
  },
  xplorie: {
    name: "Unleash local adventures",
    product_type: "xplorie",
    skus: [
      {
        url: "https://xplorie.com/partner/",
      },
    ],
  },
  babyquip: {
    name: "Rent baby equipment",
    product_type: "babyquip",
    skus: [
      {
        url: "https://www.babyquip.com",
      },
    ],
  },
  blablacar: {
    name: "Travel how you want",
    product_type: "blablacar",
    skus: [
      {
        url: "blablacar://search?from=Paris&to=Lyon&date=30/07/2018",
      },
    ],
  },
  cabify: {
    name: "Own the city",
    product_type: "cabify",
    skus: [
      {
        url: "https://cabify.com/en/riders",
      },
    ],
  },
  deliveroo: {
    name: "Get Groceries Delivered!!",
    product_type: "deliveroo",
    skus: [
      {
        url: "https://deliveroo.co.uk/",
      },
    ],
  },
  flink: {
    name: "Get Groceries Delivered!!",
    product_type: "flink",
    skus: [
      {
        url: "https://www.goflink.com/",
      },
    ],
  },
  instacart: {
    name: "Get Groceries Delivered!!",
    product_type: "instacart",
    skus: [
      {
        url: "https://www.instacart.com/grocery-delivery",
      },
    ],
  },
  stasher: {
    name: "Safe luggage storage",
    product_type: "stasher",
    skus: [
      {
        url: "https://stasher.com?ref=ngrhndh",
      },
    ],
  },
  hostco: {
    name: "Buy local products & services",
    product_type: "hostco",
    skus: [
      {
        url: "https://www.thehost.co/",
      },
    ],
  },
  holibob: {
    name: "Area activity explorer",
    product_type: "holibob",
    skus: [
      {
        url: "https://www.holibob.tech/",
      },
    ],
  },
  luggage_hero: {
    name: "Reliable luggage storage ",
    product_type: "luggage_hero",
    skus: [
      {
        url: "https://luggagehero.com/",
      },
    ],
  },
  aeroport: {
    name: "Airport taxi & limousine service",
    product_type: "aeroport",
    skus: [
      {
        url: "https://www.aeroporttaxi.com/online-booking/",
      },
    ],
  },
  airporttransfer: {
    name: "Friendly airport transfers",
    product_type: "airporttransfer",
    skus: [
      {
        url: "https://airporttransfer.com/",
      },
    ],
  },
  get_your_guide: {
    name: "Memories you\u2019ll never forget",
    product_type: "get_your_guide",
    skus: [
      {
        url: "https://www.getyourguide.com/",
      },
    ],
  },
  lieferando: {
    name: "Time to order food.",
    product_type: "lieferando",
    skus: [
      {
        url: "https://www.lieferando.de/",
      },
    ],
  },
  mevo_car_share: {
    name: "Find & unlock cars",
    product_type: "mevo_car_share",
    skus: [
      {
        url: "https://mevo.co.nz/auckland",
      },
    ],
  },
  mount: {
    name: "Explore local experiences",
    product_type: "mount",
    skus: [
      {
        url: "https://www.rentmount.com/",
      },
    ],
  },
  peerspace: {
    name: "Find a space",
    product_type: "peerspace",
    skus: [
      {
        url: "https://www.peerspace.com",
      },
    ],
  },
  revyoos: {
    name: "Review your stay",
    product_type: "revyoos",
    skus: [
      {
        url: "https://www.revyoos.com/",
      },
    ],
  },
  roam_around: {
    name: "Your AI travel guide",
    product_type: "roam_around",
    skus: [
      {
        url: "https://bit.ly/ensoconnectRA",
      },
    ],
  },
  swimply: {
    name: "Rent your own private pool",
    product_type: "swimply",
    skus: [
      {
        url: "https://swimply.com",
      },
    ],
  },
  uber: {
    name: "Get a ride in minutes",
    product_type: "uber",
    skus: [
      {
        url: "https://www.uber.com/ca/en/",
      },
    ],
  },
  zenpark: {
    name: "Rent parking spaces",
    product_type: "zenpark",
    skus: [
      {
        url: "https://zenpark.pro/en/",
      },
    ],
  },
  avis: {
    name: "Find your best car rental",
    product_type: "avis",
    skus: [
      {
        url: "https://www.avis.com/en/business-programs/affiliates",
      },
    ],
  },
  budget: {
    name: "Car rentals that fit your budget",
    product_type: "budget",
    skus: [
      {
        url: "https://www.budget.com/en/programs/affiliates#:~:text=The Budget affiliate program offers,cars%2C locations or driving dates",
      },
    ],
  },
  enterprise: {
    name: "Reserve a vehicle",
    product_type: "enterprise",
    skus: [
      {
        url: "https://www.enterprise.com/en/about/enterprise-affiliate.html",
      },
    ],
  },
  shopify: {
    name: "web storefront",
    product_type: "shopify",
    skus: [
      {
        url: "https://www.shopify.com/",
      },
    ],
  },
  skyscanner: {
    name: "Millions of cheap flights",
    product_type: "skyscanner",
    skus: [
      {
        url: "https://www.partners.skyscanner.net/product/affiliates",
      },
    ],
  },
  tourdesk_: {
    name: "Tours and Activities ",
    product_type: "tourdesk_",
    skus: [
      {
        url: "https://www.tourdesk.io/",
      },
    ],
  },
  christy_sports: {
    name: "Ski Gear Rental ",
    product_type: "christy_sports",
    skus: [
      {
        url: "https://www.christysports.com/",
      },
    ],
  },
  "durango_ski&patio": {
    name: "Ski Rental",
    product_type: "durango_ski&patio",
    skus: [
      {
        url: "https://durangoskiandpatio.com/",
      },
    ],
  },
  lets_ride: {
    name: "Airport Shuttles",
    product_type: "lets_ride",
    skus: [
      {
        url: "https://letsride.co/locations/steamboat/",
      },
    ],
  },
  steamboat_babysitting: {
    name: "Babysitting Company",
    product_type: "steamboat_babysitting",
    skus: [
      {
        url: "https://www.steamboatbabysitting.com/",
      },
    ],
  },
  steamboat_mountain_express: {
    name: "Local Transportation Provider ",
    product_type: "steamboat_mountain_express",
    skus: [
      {
        url: "https://stormmountainexpress.com/",
      },
    ],
  },
  welcome_pickups: {
    name: "Airport Pick up,Tours",
    product_type: "welcome_pickups",
    skus: [
      {
        url: "https://www.welcomepickups.com/",
      },
    ],
  },
  wheels_bike_shop: {
    name: "Bike Rental",
    product_type: "wheels_bike_shop",
    skus: [
      {
        url: "https://www.steamboatwheels.com/",
      },
    ],
  },
  aha: {
    name: "Food Delivery in Iceland",
    product_type: "aha",
    skus: [
      {
        url: "https://www.aha.is/",
      },
    ],
  },
  alfred_travel: {
    name: "Luggage Delivery in Paris",
    product_type: "alfred_travel",
    skus: [
      {
        url: "https://www.alfred.travel/paris",
      },
    ],
  },
  beam_mobility: {
    name: "Scooter rentals",
    product_type: "beam_mobility",
    skus: [
      {
        url: "https://www.ridebeam.com/",
      },
    ],
  },
  gorillas: {
    name: "Get Groceries Delivered!!",
    product_type: "gorillas",
    skus: [
      {
        url: "https://gorillas.io/",
      },
    ],
  },
  palladium_event_management_llc: {
    name: "Private Transportation & Private Security",
    product_type: "palladium_event_management_llc",
    skus: [
      {
        url: "https://palladiumeventmanagement.com",
      },
    ],
  },
  tomato: {
    name: "Order from any restaurant!",
    product_type: "tomato",
    skus: [
      {
        url: "Tomato.mx",
      },
    ],
  },
  turo: {
    name: "Skip the rental car counter",
    product_type: "turo",
    skus: [
      {
        url: "https://turo.com/ca/en",
      },
    ],
  },
  trainline: {
    name: "Explore Europe by train and bus",
    product_type: "trainline",
    skus: [
      {
        url: "https://www.shopper.com/p/lFXR/r",
      },
    ],
  },
  atg_tickets: {
    name: "Event Tickets ",
    product_type: "atg_tickets",
    skus: [
      {
        url: "https://www.atgtickets.com/",
      },
    ],
  },
  big_city_saver: {
    name: "Big City Saver",
    product_type: "big_city_saver",
    skus: [
      {
        url: "https://www.bigcitysaver.com/london/",
      },
    ],
  },
  kiwi_taxi: {
    name: "Taxi Services ",
    product_type: "kiwi_taxi",
    skus: [
      {
        url: "https://kiwitaxi.com/",
      },
    ],
  },
  "staylux_-_comestibles": {
    name: "Grocery Delivery Service",
    product_type: "staylux_-_comestibles",
    skus: [
      {
        url: "https://www.stayluxegroceries.com/",
      },
    ],
  },
  "staylux_-_four_seasons_concierge_transportation": {
    name: "Airport Transportation",
    product_type: "staylux_-_four_seasons_concierge_transportation",
    skus: [
      {
        url: "https://fspc.addons.la/m/portal/?k=2Z7PZpdsSau1EtKwQkkZ",
      },
    ],
  },
  bach_babes: {
    name: "Book your Party",
    product_type: "bach_babes",
    skus: [
      {
        url: "https://www.bachbabes.com/nashvilleform",
      },
    ],
  },
  cabana_boys: {
    name: "Bartending Service",
    product_type: "cabana_boys",
    skus: [
      {
        url: "https://fareharbor.com/embeds/book/cabana-boysscottsdale/items/?flow=829181&asn=goodnightstays&schedule-uuid=09d245de-8aa5-42eb-a0ce-faefb3016bf5&full-items=yes&ref=ASN",
      },
    ],
  },
  class_pass: {
    name: "Purchase Classes",
    product_type: "class_pass",
    skus: [
      {
        url: "https://classpass.com/partners?utm_feeditemid=&utm_device=c&utm_term=classpass partnerships&utm_source=google&utm_medium=cpc&utm_campaign=USCA+Brand&hsa_cam=11628688713&hsa_grp=112804450746&hsa_mt=b&hsa_src=g&hsa_ad=604035440330&hsa_acc=9403396696&hsa_net=adwords&hsa_kw=classpass partnerships&hsa_tgt=kwd-901942860866&hsa_ver=3&gad_source=1&gclid=Cj0KCQiAoKeuBhCoARIsAB4WxtekTIsNaUZ5yvw7qU--5j8CmczYr4h53Yuksm0btIZbFACsuxE2mc8aAvsTEALw_wcB",
      },
    ],
  },
  cocktail_cowboys: {
    name: "Bartending Service",
    product_type: "cocktail_cowboys",
    skus: [
      {
        url: "https://fareharbor.com/embeds/book/cocktailcowboys/items/?flow=846044&asn=goodnightstays&schedule-uuid=d013808e-ac90-4c4f-a591-e4aaeda09e60&full-items=yes&ref=ASN",
      },
    ],
  },
  desert_dog: {
    name: "Guided ATV Adventure",
    product_type: "desert_dog",
    skus: [
      {
        url: "https://www.azadventures.com/",
      },
    ],
  },
  "glovo_food_delivery_-_continental_europe": {
    name: "Food Delivery",
    product_type: "glovo_food_delivery_-_continental_europe",
    skus: [
      {
        url: "https://glovoapp.com/",
      },
    ],
  },
  got_your_bash: {
    name: "Get this party started",
    product_type: "got_your_bash",
    skus: [
      {
        url: "https://gotyourbash.com/contact",
      },
    ],
  },
  life_concierge_solutions: {
    name: "Travel Planning",
    product_type: "life_concierge_solutions",
    skus: [
      {
        url: "https://www.lifeconciergesolutions.com/nashville-travel-planning",
      },
    ],
  },
  luna_lotus_wellness: {
    name: "Private Yoga Sessions",
    product_type: "luna_lotus_wellness",
    skus: [
      {
        url: "https://fareharbor.com/embeds/book/lunalotuswellness/items/?flow=1124141&asn=goodnightstays&sheet-uuid=154afab9-32da-4ded-adf7-72586218df65&full-items=yes&ref=asn",
      },
    ],
  },
  matterport_virtual_tours: {
    name: "Virtual Tours",
    product_type: "matterport_virtual_tours",
    skus: [
      {
        url: "https://matterport.com/capture-services?utm_source=google&utm_campaign=EN_NORAM_CA_CSOD_tROAS&utm_medium=ppc&utm_content=EN_NORAM_US_CSOD-virtual-tour-services&utm_term=virtual tour provider&matchtype=p&device=c&gad_source=1&gclid=Cj0KCQiAoKeuBhCoARIsAB4Wxtd8GMMgseVgfRW9ZlYcXTZzQfnFxjFE1uOFPqttebDCdk2YnOcxF-UaApONEALw_wcB",
      },
    ],
  },
  north_shore_adventures: {
    name: "Kayak Rental",
    product_type: "north_shore_adventures",
    skus: [
      {
        url: "https://www.northshoreadventure.com/",
      },
    ],
  },
  phoenix_zoo: {
    name: "Phoenix Zoo Tickets",
    product_type: "phoenix_zoo",
    skus: [
      {
        url: "https://www.phoenixzoo.org/",
      },
    ],
  },
  smart_vet: {
    name: "Pet Telehealth",
    product_type: "smart_vet",
    skus: [
      {
        url: "https://smartvet.ca",
      },
    ],
  },
  take_a_chef: {
    name: "Your private chef experience is here!",
    product_type: "take_a_chef",
    skus: [
      {
        url: "https://partners.takeachef.com/en-en/agency/homepage/em1tYnJJSW44KioqKiplaDhjV25VZmJwdHRUT1Q4aHVSRlVVS0dMSUFDa3BRT3ZjMEE4bTBQeFFEUTlwa21FeGczSzBQalhaUkEyWnJNOFFUNUI2cjJIdjFORjJpRGp1R1ZYczlFTzgyc0ZESjJKaEk0amIyZlVqR0hBMktYTG5Mc0I1NiUyQkU5YlJVN3NWeGliVW5GdHlEcEQyOFZpSGVrRVF3OFp6dnhXTnluMkVjJTNE",
      },
    ],
  },
  waymo: {
    name: "Autonomous ride-hailing service",
    product_type: "waymo",
    skus: [
      {
        url: "https://waymo.com/",
      },
    ],
  },
  blys: {
    name: "Mobile Massage & Beauty",
    product_type: "blys",
    skus: [
      {
        url: "https://getblys.com.au/",
      },
    ],
  },
  gourmet_basket: {
    name: "Gourmet baskets",
    product_type: "gourmet_basket",
    skus: [
      {
        url: "https://gourmetbasket.com.au/",
      },
    ],
  },
  hire_a_chef: {
    name: "Personal Chefs",
    product_type: "hire_a_chef",
    skus: [
      {
        url: "https://www.eliadonati.com/",
      },
    ],
  },
  hire_for_baby: {
    name: "Your complete baby equipment hire service",
    product_type: "hire_for_baby",
    skus: [
      {
        url: "https://www.hireforbaby.com/",
      },
    ],
  },
  plenty_valley_florist: {
    name: "Order Flowers",
    product_type: "plenty_valley_florist",
    skus: [
      {
        url: "https://plentyvalleyflorist.com.au/",
      },
    ],
  },
  purely_gourmet: {
    name: "Gifts Delivered",
    product_type: "purely_gourmet",
    skus: [
      {
        url: "https://purelygourmet.com.au/",
      },
    ],
  },
  the_bach_bar: {
    name: "The best bartending service for bachelorettes!",
    product_type: "the_bach_bar",
    skus: [
      {
        url: "https://fareharbor.com/embeds/book/thebachbar/items/?flow=764535&language=en-us&a=yes&full-items=yes&back=https://bachbarnash.com/",
      },
    ],
  },
};

export const boardingPassFees = [
  {
    product_type: "fee",
    name: "Damage Waiver",
    header: "Self-insure with a non-refundable damage waiver",
    color: "#5E8CF4",
    requires_stripe: true,
    description:
      "<p><span>We know accidents may happen. This is a non-refundable fee to cover any small damages and wear and tear that may have occurred during your stay.</span></p>",
    fields: [],
    skus: [
      {
        unit: "1",
        value: 10,
      },
    ],
  },
  {
    product_type: "pet_fee",
    name: "Pet Fee",
    header: "Charge per pet, pet night, per pet per night & more",
    color: "#5E8CF4",
    description:
      "<p>We hope you enjoy your stay with your furry friends!</p><p><br></p><p>In order to provide the best experience for you and your petswe charge a pet fee to cover the additional cleaning costs.</p>",
    requires_stripe: true,
    conditions: [],
    fields: [],
    skus: [
      {
        unit: "1",
        value: 10,
      },
    ],
  },
  {
    product_type: "deposit",
    name: "Safety Deposit",
    header: "Place a hold on your guest's card or collect funds immediately",
    color: "#5E8CF4",
    description:
      "<p><span>We know accidents may happen. This covers any property damage that occurred during your stay. </span></p><p><br></p><p><span>The security deposit is refundable if the property was not damaged in any way. Please note that the refund may take up to 14 days depending on your credit card company.</span></p>",
    requires_stripe: true,
    fields: [
      {
        path: "charge_card",
        type: "charge_card",
        label: "How would you like to collect your deposit?",
      },
    ],
    skus: [
      {
        charge_card: false,
        unit: "1",
        value: 100,
      },
    ],
  },
];

export const boardingPassUpsells = [
  "bird_scooters",
  "bolt",
  "corner_shop",
  "didi",
  "donkey_republic",
  "drizly_alcohol_delivery_app",
  "flanders_bike_tours",
  "free_now",
  "gett.",
  "go-jek",
  "grab",
  "hostfully",
  "houstr",
  "hreyfill",
  "just_eat",
  "lime_scooters",
  "localbird",
  "lyft",
  "minibar_delivery",
  "mykonos_yacht_day_charter",
  "ok2charge",
  "radical_storage",
  "rappi",
  "sixt_rent_a_car",
  "skibutler",
  "stib/mivb",
  "taxi_&_mietwagen",
  "the_goat",
  "tiqets",
  "touchstay",
  "travelpayouts",
  "uber_eats",
  "viator",
  "wind_scooters",
  "wolt",
  "xplorie",
  "babyquip",
  "blablacar",
  "cabify",
  "deliveroo",
  "flink",
  "instacart",
  "stasher",
  "hostco",
  "holibob",
  "luggage_hero",
  "aeroport",
  "airporttransfer",
  "get_your_guide",
  "lieferando",
  "mevo_car_share",
  "mount",
  "peerspace",
  "revyoos",
  "roam_around",
  "swimply",
  "uber",
  "zenpark",
  "avis",
  "budget",
  "enterprise",
  "shopify",
  "skyscanner",
  "tourdesk_",
  "christy_sports",
  "durango_ski&patio",
  "lets_ride",
  "steamboat_babysitting",
  "steamboat_mountain_express",
  "welcome_pickups",
  "wheels_bike_shop",
  "aha",
  "alfred_travel",
  "beam_mobility",
  "gorillas",
  "palladium_event_management_llc",
  "tomato",
  "turo",
  "trainline",
  "atg_tickets",
  "big_city_saver",
  "kiwi_taxi",
  "staylux_-_comestibles",
  "staylux_-_four_seasons_concierge_transportation",
  "bach_babes",
  "cabana_boys",
  "class_pass",
  "cocktail_cowboys",
  "desert_dog",
  "glovo_food_delivery_-_continental_europe",
  "got_your_bash",
  "life_concierge_solutions",
  "luna_lotus_wellness",
  "matterport_virtual_tours",
  "north_shore_adventures",
  "phoenix_zoo",
  "smart_vet",
  "take_a_chef",
  "waymo",
  "blys",
  "gourmet_basket",
  "hire_a_chef",
  "hire_for_baby",
  "plenty_valley_florist",
  "purely_gourmet",
  "the_bach_bar",
];

export const boardingPassProducts = {
  gap_night: {
    product_type: "gap_night",
    name: "Extend your stay",
    header:
      "Offer stay extensions when your property is available past the check-out date",
    color: "#5E8CF4",
    description:
      "\ud83c\udf1f Extend Your Stay, Elevate Your Experience! \ud83c\udf1f<br><br>Enjoying your stay so far? Why not make the magic last a little longer? Our exclusive offer invites you to extend your stay and savor even more moments of relaxation and discovery.<br><br><p>Picture waking up to another morning of tranquil serenity, indulging in leisurely breakfasts, and embracing the day at your own pace. With an additional night, you have the luxury to explore the hidden gemsof our neighborhood, immerse yourself in local culture, or simply unwind in the cozy ambiance of your home away from home.</p></p><p><br></p><p>While we do our best to accommodate stay extensions, in the event that we are unable to accommodate your request, you will be notified and your card will not be charged.</p>",
    requires_stripe: true,
    fields: [
      {
        type: "number",
        label: "Number of extra nights",
        path: "extra_nights",
        info_text: [
          "This option will only be presented if there are no upcoming bookings within the specific number of nights. This does not take into account owner blocks, so we recommend manually approving this upsell.",
        ],
      },
    ],
    skus: [
      {
        name: "1 Extra Night",
        unit: "1",
        value: 100,
        properties: {
          extra_nights: 1,
        },
      },
      {
        name: "2 Extra Nights",
        unit: "1",
        value: 200,
        properties: {
          extra_nights: 2,
        },
      },
      {
        name: "3 Extra Nights",
        unit: "1",
        value: 300,
        properties: {
          extra_nights: 3,
        },
      },
    ],
  },
  checkin: {
    product_type: "checkin",
    name: "Arrange for an early check-in",
    header: "Earn money from every early check-in",
    color: "#5E8CF4",
    description:
      "<p>If you would like a head start on your vacation we offer early check-in. This can give you a chance to head down to the pool or site see and explore your home away from home.  </p><p><br></p><p>While we do our best to accommodate an early check-in for our guests, in the event that we are unable to accommodate your request, you will be notified and refunded.</p>",
    requires_stripe: true,
    fields: [
      {
        path: "checkin_time",
        type: "time",
        label: "Early Check-in Time",
      },
    ],
    skus: [
      {
        unit: "1",
        value: 30,
        checkin_time: "14:00",
      },
    ],
  },
  checkout: {
    product_type: "checkout",
    name: "Arrange for a late check-out",
    header: "Earn money from every late check-out",
    color: "#5E8CF4",
    description:
      "<p><span>Late check-out allows you to check out later than our standard check-out time, giving you the opportunity to take a shower after an early meeting, relax after a late night, or have some time to pack your suitcases at a leisurely pace. </span></p><p><br></p><p><span>While we do our best to accommodate a late check-out for our guests, in the event that we are unable to accommodate your request, you will be notified and refunded.</span></p>",
    requires_stripe: true,
    fields: [
      {
        path: "checkout_time",
        type: "time",
        label: "Late Check-out Time",
      },
    ],
    skus: [
      {
        unit: "1",
        value: 30,
        checkout_time: "13:00",
      },
    ],
  },
  stocked_fridge: {
    product_type: "custom_upsell",
    name: "Stocked Fridge",
    header: "Deliver food to your guest's fridge",
    color: "#5E8CF4",
    description:
      "<p><span>Enjoy the convenience of a fully stocked fridge! Choose from drinks & snacks and/or meals</span></p><p>Once you make your purchase, the items will be delivered directly to your unit & you will be notified when they arrive.</p><p>While we do our best to accommodate an early check-in for our guests, in the event that we are unable to accommodate your request, you will be notified and refunded.</p>",
    skus: [
      {
        unit: "1",
        name: "Drinks & Snacks",
        value: 30,
      },
      {
        unit: "1",
        name: "Continental Breakfast: (Eggs, bacon, bread, orange juice)",
        value: 30,
      },
    ],
  },
  mid_stay_clean: {
    product_type: "custom_upsell",
    description:
      "<p><span>Enjoy the feeling of freshly laundered & pressed linens!</span></p>We offer additional cleaning services that can be purchased in addition to the regular cleaning done before and after your stay. This is an optional service that you can choose if you desire more frequent cleaning during your stay.While we do our best to accommodate a mid-stay cleaning, in the event that we are unable to accommodate your request, you will be notified and refunded.",
    name: "Mid-stay Clean",
    header: "Clean the unit during the stay",
    color: "#5E8CF4",
    skus: [
      {
        unit: "1",
        name: "Change the sheets & towels",
        value: 80,
      },
      {
        unit: "1",
        name: "Full cleaning (sheets, towels, kitchen & living room)",
        value: 150,
      },
    ],
  },
  coffee_pods: {
    product_type: "custom_upsell",
    product_id: "coffee_pods",
    description:
      "<p><span>Start your day with a fresh cup of coffee! We will deliver coffee pods directly to your unit</span></p><p>While we do our best to accommodate an early check-in for our guests, in the event that we are unable to accommodate your request, you will be notified and refunded.</p>",
    name: "Coffee Pods",
    header: "Restock your guest's supply of coffee pods",
    color: "#5E8CF4",
    skus: [
      {
        unit: "1",
        name: "5 pods",
        value: 10,
      },
      {
        unit: "1",
        name: "20 pods",
        value: 25,
      },
    ],
  },
  welcome_basket: {
    product_type: "custom_upsell",
    description:
      "<p>Support the local community and discover the culture with a locally sourced welcome basket: a collection of items from local vendors and producers to get you started on your trip. </p><br><p>While we do our best to accommodate your welcome basket, in the event that we are unable to accommodate your request, you will be notified and refunded.</p>",
    name: "Welcome Basket",
    header: "Provide a welcome basket to your guests",
    color: "#5E8CF4",
    skus: [
      {
        unit: "1",
        value: 60,
      },
    ],
  },
  carbon_offset: {
    product_type: "custom_upsell",
    description:
      "Make your stay 100% carbon neutral by purchasing a carbon offset upgrade, with a portion of the fee going to a charity that works on carbon reduction initiatives!",
    name: "Carbon Offset Upgrade",
    manually_approve: false,
    header: "Make your guest's stay carbon neutral",
    color: "#5E8CF4",
    skus: [
      {
        unit: "1",
        value: 20,
      },
    ],
  },
  tip_staff: {
    product_type: "custom_upsell",
    description:
      "<p>We hope you are enjoying your stay with us and that our staff is providing you with exceptional service during your visit.</p><p><br>If you were pleased with your experience, we would greatly appreciate it if you could take a moment to leave a tip for our staff.Your generosity will help to ensure that our staff can continue to provide the high level of service that you have come to expect from us.</p><br/><p>You can leave a tip using your Boarding Pass - tips will be distributed fairly among the staff who contributed to your stay. </p><br>On behalf of our staff, we would like to thank you for your support and we hope to have the pleasure of welcoming you back soon!",
    name: "Tip the staff",
    header: "Receive digital tips for your business and staff",
    color: "#5E8CF4",
    skus: [
      {
        unit: "1",
        name: "Awesome service",
        value: 5,
      },
      {
        unit: "1",
        name: "Incredible service",
        value: 10,
      },
      {
        unit: "1",
        name: "Mega service",
        value: 30,
      },
    ],
  },
  flex: {
    product_type: "flex",
    name: "Damage Waiver or Safety Deposit",
    header: "Offer guests a choice of insurance.",
    color: "#5E8CF4",
    description:
      "<p>We know accidents may happen. This covers any property damage that occurred during your stay. </p><p><br></p><p>You choose between two options:</p><ul><li>Security Deposit - Pay a larger fee that is refundableif the property was not damaged in any way. Please note that the refund may take up to 14 days depending on your credit card company.</li><li>Damage Waiver - Pay a smaller one-time non-refundable fee that can help us cover small damages and wear and tear.</li></ul>",
    fields: [
      {
        path: "charge_card",
        type: "charge_card",
        label: "How would you like to collect your deposit?",
      },
    ],
    skus: [
      {
        name: "Damage Waiver",
        unit: "1",
        value: 10,
        flex_product_id: "fee",
      },
      {
        name: "Safety Deposit",
        unit: "1",
        value: 100,
        charge_card: false,
        flex_product_id: "deposit",
      },
    ],
  },
  fee: {
    product_type: "fee",
    name: "Damage Waiver",
    header: "Self-insure with a non-refundable damage waiver",
    color: "#5E8CF4",
    requires_stripe: true,
    description:
      "<p><span>We know accidents may happen. This is a non-refundable fee to cover any small damages and wear and tear that may have occurred during your stay.</span></p>",
    fields: [],
    skus: [
      {
        unit: "1",
        value: 10,
      },
    ],
  },
  pet_fee: {
    product_type: "pet_fee",
    name: "Pet Fee",
    header: "Charge per pet, pet night, per pet per night & more",
    color: "#5E8CF4",
    description:
      "<p>We hope you enjoy your stay with your furry friends!</p><p><br></p><p>In order to provide the best experience for you and your petswe charge a pet fee to cover the additional cleaning costs.</p>",
    requires_stripe: true,
    conditions: [],
    fields: [],
    skus: [
      {
        unit: "1",
        value: 10,
      },
    ],
  },
  deposit: {
    product_type: "deposit",
    name: "Safety Deposit",
    header: "Place a hold on your guest's card or collect funds immediately",
    color: "#5E8CF4",
    description:
      "<p><span>We know accidents may happen. This covers any property damage that occurred during your stay. </span></p><p><br></p><p><span>The security deposit is refundable if the property was not damaged in any way. Please note that the refund may take up to 14 days depending on your credit card company.</span></p>",
    requires_stripe: true,
    fields: [
      {
        path: "charge_card",
        type: "charge_card",
        label: "How would you like to collect your deposit?",
      },
    ],
    skus: [
      {
        charge_card: false,
        unit: "1",
        value: 100,
      },
    ],
  },
  bird_scooters: {
    id: "bird_scooters",
    name: "Rent a Scooter",
    url: "https://go.bird.co/",
    countries: ["INT"],
    category: "transportation",
  },
  bolt: {
    id: "bolt",
    name: "Get a ride in minutes.",
    url: "https://bolt.eu/",
    countries: ["EU"],
    category: "transportation",
  },
  corner_shop: {
    id: "corner_shop",
    name: "Get Groceries Delivered!!",
    url: "https://cornershopapp.com/",
    countries: ["INT"],
    category: "food_&_beverage",
  },
  didi: {
    id: "didi",
    name: "Rent-a-car service",
    url: "https://web.didiglobal.com/mx/",
    countries: ["Mexico"],
    category: "transportation",
  },
  donkey_republic: {
    id: "donkey_republic",
    name: "Book a Donkey bike",
    url: "https://www.donkey.bike/",
    countries: ["Germany"],
    category: "transportation",
  },
  drizly_alcohol_delivery_app: {
    id: "drizly_alcohol_delivery_app",
    name: "Order Alcohol deliveries!",
    url: "https://drizly.com",
    countries: ["US"],
    category: "food_&_beverage",
  },
  flanders_bike_tours: {
    id: "flanders_bike_tours",
    name: "Guided bike tours",
    url: "https://flandersbiketours.com/",
    countries: ["Belgium"],
    category: "experiences",
  },
  free_now: {
    id: "free_now",
    name: "Order a ride via the app.",
    url: "https://free-now.com/",
    countries: ["EU"],
    category: "transportation",
  },
  "gett.": {
    id: "gett.",
    name: "Gett. a taxi!",
    url: "https://b.gett.com/d/iPJlpemZBob",
    countries: ["IL"],
    category: "transportation",
  },
  "go-jek": {
    id: "go-jek",
    name: "Your superapp",
    url: "https://apps.apple.com/us/app/gojek/id944875099",
    countries: ["ID"],
    category: "transportation",
  },
  grab: {
    id: "grab",
    name: "Order a ride via the app.",
    url: "https://grab.onelink.me/2695613898?pid=PH-Website-QR_Code&c=Website_Download",
    countries: ["INT"],
    category: "transportation",
  },
  hostfully: {
    id: "hostfully",
    name: "Learn more about your listing",
    url: "https://hostfully.com",
    countries: [],
    category: "guidebooks",
  },
  houstr: {
    id: "houstr",
    name: "Shop your stay",
    url: "https://hstr.co/",
    countries: ["USA", " CAD"],
    category: "experiences",
  },
  hreyfill: {
    id: "hreyfill",
    name: "Personalized tours & taxis",
    url: "https://www.hreyfill.is/en/",
    countries: ["Iceland"],
    category: "experiences",
  },
  just_eat: {
    id: "just_eat",
    name: "Order from any restaurant!",
    url: "https://app.adjust.com/oc511o_1455tm?utm_medium=internal&campaign=homepage",
    countries: ["INT"],
    category: "food_&_beverage",
  },
  lime_scooters: {
    id: "lime_scooters",
    name: "Rent a Scooter",
    url: "https://limebike.app.link/d/YQsZG161Boba",
    countries: ["INT"],
    category: "transportation",
  },
  localbird: {
    id: "localbird",
    name: "Your local fun navigator",
    url: "https://localbird.io/",
    countries: [],
    category: "preferred_partners",
  },
  lyft: {
    id: "lyft",
    name: "Grab a Lyft!",
    url: "lyft://partner=YOUR_CLIENT_ID",
    countries: ["INT"],
    category: "transportation",
  },
  minibar_delivery: {
    id: "minibar_delivery",
    name: "Order Alcohol deliveries!",
    url: "https://minibardelivery.com/",
    countries: ["UK"],
    category: "food_&_beverage",
  },
  mykonos_yacht_day_charter: {
    id: "mykonos_yacht_day_charter",
    name: "Mykonos Yacht Day Charter",
    url: "https://cd-yachting.checkfront.com/reserve/",
    countries: ["Greece"],
    category: "transportation",
  },
  ok2charge: {
    id: "ok2charge",
    name: "Local EV chargers",
    url: "http://www.ok2charge.com/electricvehiclechargingstations",
    countries: [],
    category: "transportation",
  },
  radical_storage: {
    id: "radical_storage",
    name: "Local luggage storage",
    url: "https://radicalstorage.com/",
    countries: [],
    category: "luggage",
  },
  rappi: {
    id: "rappi",
    name: "Local bars & restaurants",
    url: "https://www.rappi.com.mx/",
    countries: ["MX"],
    category: "food_&_beverage",
  },
  sixt_rent_a_car: {
    id: "sixt_rent_a_car",
    name: "Rent a car in Belgium",
    url: "https://www.sixt.com/car-rental/belgium/",
    countries: ["Belgium"],
    category: "transportation",
  },
  skibutler: {
    id: "skibutler",
    name: "Ski/Snowboard Equipment Rental",
    url: "https://www.skibutlers.com/",
    countries: ["Germany"],
    category: "other",
  },
  "stib/mivb": {
    id: "stib/mivb",
    name: "Take public transit",
    url: "https://www.stib-mivb.be/index.htm?l=en",
    countries: ["Belgium"],
    category: "transportation",
  },
  "taxi_&_mietwagen": {
    id: "taxi_&_mietwagen",
    name: "Taxi in Straubing",
    url: "https://taxi-ostbayern.com/",
    countries: ["Germany"],
    category: "transportation",
  },
  the_goat: {
    id: "the_goat",
    name: "A place to connect, all day",
    url: "https://www.lcgoat.com/mobile-app",
    countries: ["United States"],
    category: "food_&_beverage",
  },
  tiqets: {
    id: "tiqets",
    name: "Tickets to local events",
    url: "https://www.tiqets.com/en/",
    countries: [],
    category: "experiences",
  },
  touchstay: {
    id: "touchstay",
    name: "Learn more about your listing",
    url: "https://touchstay.com/",
    countries: [],
    category: "guidebooks",
  },
  travelpayouts: {
    id: "travelpayouts",
    name: "Book an airport transfer",
    url: "https://www.travelpayouts.com/en/offers/gettransfer-affiliate-program",
    countries: [],
    category: "transportation",
  },
  uber_eats: {
    id: "uber_eats",
    name: "Order from any restaurant!",
    url: "https://www.ubereats.com/",
    countries: ["INT"],
    category: "food_&_beverage",
  },
  viator: {
    id: "viator",
    name: "Local sightseeing & tours",
    url: "https://partnerresources.viator.com/",
    countries: ["INT"],
    category: "preferred_partners",
  },
  wind_scooters: {
    id: "wind_scooters",
    name: "Rent a Scooter",
    url: "https://wind.yango.com/",
    countries: ["INT"],
    category: "transportation",
  },
  wolt: {
    id: "wolt",
    name: "Order from any restaurant!",
    url: "https://wolt.com/en",
    countries: ["IL"],
    category: "food_&_beverage",
  },
  xplorie: {
    id: "xplorie",
    name: "Unleash local adventures",
    url: "https://xplorie.com/partner/",
    countries: [],
    category: "preferred_partners",
  },
  babyquip: {
    id: "babyquip",
    name: "Rent baby equipment",
    url: "https://www.babyquip.com",
    countries: ["INT"],
    category: "other",
  },
  blablacar: {
    id: "blablacar",
    name: "Travel how you want",
    url: "blablacar://search?from=Paris&to=Lyon&date=30/07/2018",
    countries: ["FR"],
    category: "transportation",
  },
  cabify: {
    id: "cabify",
    name: "Own the city",
    url: "https://cabify.com/en/riders",
    countries: [
      "ES",
      " EC",
      " MX",
      " PA",
      " PE",
      " UY",
      " BR",
      " CO",
      " CL",
      " DM",
    ],
    category: "transportation",
  },
  deliveroo: {
    id: "deliveroo",
    name: "Get Groceries Delivered!!",
    url: "https://deliveroo.co.uk/",
    countries: ["UK"],
    category: "food_&_beverage",
  },
  flink: {
    id: "flink",
    name: "Get Groceries Delivered!!",
    url: "https://www.goflink.com/",
    countries: ["UK"],
    category: "food_&_beverage",
  },
  instacart: {
    id: "instacart",
    name: "Get Groceries Delivered!!",
    url: "https://www.instacart.com/grocery-delivery",
    countries: ["INT"],
    category: "food_&_beverage",
  },
  stasher: {
    id: "stasher",
    name: "Safe luggage storage",
    url: "https://stasher.com?ref=ngrhndh",
    countries: [],
    category: "luggage",
  },
  hostco: {
    id: "hostco",
    name: "Buy local products & services",
    url: "https://www.thehost.co/",
    countries: ["USA"],
    category: "preferred_partners",
  },
  holibob: {
    id: "holibob",
    name: "Area activity explorer",
    url: "https://www.holibob.tech/",
    countries: [],
    category: "experiences",
  },
  luggage_hero: {
    id: "luggage_hero",
    name: "Reliable luggage storage ",
    url: "https://luggagehero.com/",
    countries: [],
    category: "luggage",
  },
  aeroport: {
    id: "aeroport",
    name: "Airport taxi & limousine service",
    url: "https://www.aeroporttaxi.com/online-booking/",
    countries: [],
    category: "transportation",
  },
  airporttransfer: {
    id: "airporttransfer",
    name: "Friendly airport transfers",
    url: "https://airporttransfer.com/",
    countries: [],
    category: "transportation",
  },
  get_your_guide: {
    id: "get_your_guide",
    name: "Memories you\u2019ll never forget",
    url: "https://www.getyourguide.com/",
    countries: [],
    category: "experiences",
  },
  lieferando: {
    id: "lieferando",
    name: "Time to order food.",
    url: "https://www.lieferando.de/",
    countries: ["Germany"],
    category: "food_&_beverage",
  },
  mevo_car_share: {
    id: "mevo_car_share",
    name: "Find & unlock cars",
    url: "https://mevo.co.nz/auckland",
    countries: [],
    category: "transportation",
  },
  mount: {
    id: "mount",
    name: "Explore local experiences",
    url: "https://www.rentmount.com/",
    countries: ["USA"],
    category: "preferred_partners",
  },
  peerspace: {
    id: "peerspace",
    name: "Find a space",
    url: "https://www.peerspace.com",
    countries: [],
    category: "other",
  },
  revyoos: {
    id: "revyoos",
    name: "Review your stay",
    url: "https://www.revyoos.com/",
    countries: [],
    category: "other",
  },
  roam_around: {
    id: "roam_around",
    name: "Your AI travel guide",
    url: "https://bit.ly/ensoconnectRA",
    countries: [],
    category: "other",
  },
  swimply: {
    id: "swimply",
    name: "Rent your own private pool",
    url: "https://swimply.com",
    countries: [],
    category: "other",
  },
  uber: {
    id: "uber",
    name: "Get a ride in minutes",
    url: "https://www.uber.com/ca/en/",
    countries: [],
    category: "transportation",
  },
  zenpark: {
    id: "zenpark",
    name: "Rent parking spaces",
    url: "https://zenpark.pro/en/",
    countries: [],
    category: "transportation",
  },
  avis: {
    id: "avis",
    name: "Find your best car rental",
    url: "https://www.avis.com/en/business-programs/affiliates",
    countries: [],
    category: "transportation",
  },
  budget: {
    id: "budget",
    name: "Car rentals that fit your budget",
    url: "https://www.budget.com/en/programs/affiliates#:~:text=The Budget affiliate program offers,cars%2C locations or driving dates",
    countries: [],
    category: "transportation",
  },
  enterprise: {
    id: "enterprise",
    name: "Reserve a vehicle",
    url: "https://www.enterprise.com/en/about/enterprise-affiliate.html",
    countries: [],
    category: "transportation",
  },
  shopify: {
    id: "shopify",
    name: "web storefront",
    url: "https://www.shopify.com/",
    countries: ["INT"],
    category: "other",
  },
  skyscanner: {
    id: "skyscanner",
    name: "Millions of cheap flights",
    url: "https://www.partners.skyscanner.net/product/affiliates",
    countries: [],
    category: "other",
  },
  tourdesk_: {
    id: "tourdesk_",
    name: "Tours and Activities ",
    url: "https://www.tourdesk.io/",
    countries: ["Iceland"],
    category: "transportation",
  },
  christy_sports: {
    id: "christy_sports",
    name: "Ski Gear Rental ",
    url: "https://www.christysports.com/",
    countries: ["USA"],
    category: "other",
  },
  "durango_ski&patio": {
    id: "durango_ski&patio",
    name: "Ski Rental",
    url: "https://durangoskiandpatio.com/",
    countries: ["USA"],
    category: "other",
  },
  lets_ride: {
    id: "lets_ride",
    name: "Airport Shuttles",
    url: "https://letsride.co/locations/steamboat/",
    countries: ["USA"],
    category: "transportation",
  },
  steamboat_babysitting: {
    id: "steamboat_babysitting",
    name: "Babysitting Company",
    url: "https://www.steamboatbabysitting.com/",
    countries: ["USA"],
    category: "other",
  },
  steamboat_mountain_express: {
    id: "steamboat_mountain_express",
    name: "Local Transportation Provider ",
    url: "https://stormmountainexpress.com/",
    countries: ["USA"],
    category: "transportation",
  },
  welcome_pickups: {
    id: "welcome_pickups",
    name: "Airport Pick up,Tours",
    url: "https://www.welcomepickups.com/",
    countries: ["Mexico"],
    category: "transportation",
  },
  wheels_bike_shop: {
    id: "wheels_bike_shop",
    name: "Bike Rental",
    url: "https://www.steamboatwheels.com/",
    countries: ["USA"],
    category: "transportation",
  },
  aha: {
    id: "aha",
    name: "Food Delivery in Iceland",
    url: "https://www.aha.is/",
    countries: ["Iceland"],
    category: "food_&_beverage",
  },
  alfred_travel: {
    id: "alfred_travel",
    name: "Luggage Delivery in Paris",
    url: "https://www.alfred.travel/paris",
    countries: ["FR"],
    category: "luggage",
  },
  beam_mobility: {
    id: "beam_mobility",
    name: "Scooter rentals",
    url: "https://www.ridebeam.com/",
    countries: [],
    category: "transportation",
  },
  gorillas: {
    id: "gorillas",
    name: "Get Groceries Delivered!!",
    url: "https://gorillas.io/",
    countries: ["DE"],
    category: "food_&_beverage",
  },
  palladium_event_management_llc: {
    id: "palladium_event_management_llc",
    name: "Private Transportation & Private Security",
    url: "https://palladiumeventmanagement.com",
    countries: [],
    category: "experiences",
  },
  tomato: {
    id: "tomato",
    name: "Order from any restaurant!",
    url: "Tomato.mx",
    countries: ["MX"],
    category: "food_&_beverage",
  },
  turo: {
    id: "turo",
    name: "Skip the rental car counter",
    url: "https://turo.com/ca/en",
    countries: [],
    category: "transportation",
  },
  trainline: {
    id: "trainline",
    name: "Explore Europe by train and bus",
    url: "https://www.shopper.com/p/lFXR/r",
    countries: [],
    category: "transportation",
  },
  atg_tickets: {
    id: "atg_tickets",
    name: "Event Tickets ",
    url: "https://www.atgtickets.com/",
    countries: ["UK"],
    category: "other",
  },
  big_city_saver: {
    id: "big_city_saver",
    name: "Big City Saver",
    url: "https://www.bigcitysaver.com/london/",
    countries: ["UK"],
    category: "transportation",
  },
  kiwi_taxi: {
    id: "kiwi_taxi",
    name: "Taxi Services ",
    url: "https://kiwitaxi.com/",
    countries: ["UK"],
    category: "transportation",
  },
  "staylux_-_comestibles": {
    id: "staylux_-_comestibles",
    name: "Grocery Delivery Service",
    url: "https://www.stayluxegroceries.com/",
    countries: ["USA"],
    category: "food_&_beverage",
  },
  "staylux_-_four_seasons_concierge_transportation": {
    id: "staylux_-_four_seasons_concierge_transportation",
    name: "Airport Transportation",
    url: "https://fspc.addons.la/m/portal/?k=2Z7PZpdsSau1EtKwQkkZ",
    countries: ["USA"],
    category: "transportation",
  },
  bach_babes: {
    id: "bach_babes",
    name: "Book your Party",
    url: "https://www.bachbabes.com/nashvilleform",
    countries: [],
    category: "experiences",
  },
  cabana_boys: {
    id: "cabana_boys",
    name: "Bartending Service",
    url: "https://fareharbor.com/embeds/book/cabana-boysscottsdale/items/?flow=829181&asn=goodnightstays&schedule-uuid=09d245de-8aa5-42eb-a0ce-faefb3016bf5&full-items=yes&ref=ASN",
    countries: [],
    category: "experiences",
  },
  class_pass: {
    id: "class_pass",
    name: "Purchase Classes",
    url: "https://classpass.com/partners?utm_feeditemid=&utm_device=c&utm_term=classpass partnerships&utm_source=google&utm_medium=cpc&utm_campaign=USCA+Brand&hsa_cam=11628688713&hsa_grp=112804450746&hsa_mt=b&hsa_src=g&hsa_ad=604035440330&hsa_acc=9403396696&hsa_net=adwords&hsa_kw=classpass partnerships&hsa_tgt=kwd-901942860866&hsa_ver=3&gad_source=1&gclid=Cj0KCQiAoKeuBhCoARIsAB4WxtekTIsNaUZ5yvw7qU--5j8CmczYr4h53Yuksm0btIZbFACsuxE2mc8aAvsTEALw_wcB",
    countries: [],
    category: "other",
  },
  cocktail_cowboys: {
    id: "cocktail_cowboys",
    name: "Bartending Service",
    url: "https://fareharbor.com/embeds/book/cocktailcowboys/items/?flow=846044&asn=goodnightstays&schedule-uuid=d013808e-ac90-4c4f-a591-e4aaeda09e60&full-items=yes&ref=ASN",
    countries: [],
    category: "experiences",
  },
  desert_dog: {
    id: "desert_dog",
    name: "Guided ATV Adventure",
    url: "https://www.azadventures.com/",
    countries: [],
    category: "experiences",
  },
  "glovo_food_delivery_-_continental_europe": {
    id: "glovo_food_delivery_-_continental_europe",
    name: "Food Delivery",
    url: "https://glovoapp.com/",
    countries: [],
    category: "",
  },
  got_your_bash: {
    id: "got_your_bash",
    name: "Get this party started",
    url: "https://gotyourbash.com/contact",
    countries: [],
    category: "experiences",
  },
  life_concierge_solutions: {
    id: "life_concierge_solutions",
    name: "Travel Planning",
    url: "https://www.lifeconciergesolutions.com/nashville-travel-planning",
    countries: [],
    category: "experiences",
  },
  luna_lotus_wellness: {
    id: "luna_lotus_wellness",
    name: "Private Yoga Sessions",
    url: "https://fareharbor.com/embeds/book/lunalotuswellness/items/?flow=1124141&asn=goodnightstays&sheet-uuid=154afab9-32da-4ded-adf7-72586218df65&full-items=yes&ref=asn",
    countries: [],
    category: "experiences",
  },
  matterport_virtual_tours: {
    id: "matterport_virtual_tours",
    name: "Virtual Tours",
    url: "https://matterport.com/capture-services?utm_source=google&utm_campaign=EN_NORAM_CA_CSOD_tROAS&utm_medium=ppc&utm_content=EN_NORAM_US_CSOD-virtual-tour-services&utm_term=virtual tour provider&matchtype=p&device=c&gad_source=1&gclid=Cj0KCQiAoKeuBhCoARIsAB4Wxtd8GMMgseVgfRW9ZlYcXTZzQfnFxjFE1uOFPqttebDCdk2YnOcxF-UaApONEALw_wcB",
    countries: [],
    category: "",
  },
  north_shore_adventures: {
    id: "north_shore_adventures",
    name: "Kayak Rental",
    url: "https://www.northshoreadventure.com/",
    countries: ["USA"],
    category: "other",
  },
  phoenix_zoo: {
    id: "phoenix_zoo",
    name: "Phoenix Zoo Tickets",
    url: "https://www.phoenixzoo.org/",
    countries: [],
    category: "experiences",
  },
  smart_vet: {
    id: "smart_vet",
    name: "Pet Telehealth",
    url: "https://smartvet.ca",
    countries: ["CA", ""],
    category: "healthcare",
  },
  take_a_chef: {
    id: "take_a_chef",
    name: "Your private chef experience is here!",
    url: "https://partners.takeachef.com/en-en/agency/homepage/em1tYnJJSW44KioqKiplaDhjV25VZmJwdHRUT1Q4aHVSRlVVS0dMSUFDa3BRT3ZjMEE4bTBQeFFEUTlwa21FeGczSzBQalhaUkEyWnJNOFFUNUI2cjJIdjFORjJpRGp1R1ZYczlFTzgyc0ZESjJKaEk0amIyZlVqR0hBMktYTG5Mc0I1NiUyQkU5YlJVN3NWeGliVW5GdHlEcEQyOFZpSGVrRVF3OFp6dnhXTnluMkVjJTNE",
    countries: [],
    category: "food_&_beverage",
  },
  waymo: {
    id: "waymo",
    name: "Autonomous ride-hailing service",
    url: "https://waymo.com/",
    countries: [],
    category: "transportation",
  },
  blys: {
    id: "blys",
    name: "Mobile Massage & Beauty",
    url: "https://getblys.com.au/",
    countries: [],
    category: "experiences",
  },
  gourmet_basket: {
    id: "gourmet_basket",
    name: "Gourmet baskets",
    url: "https://gourmetbasket.com.au/",
    countries: [],
    category: "other",
  },
  hire_a_chef: {
    id: "hire_a_chef",
    name: "Personal Chefs",
    url: "https://www.eliadonati.com/",
    countries: [],
    category: "food_&_beverage",
  },
  hire_for_baby: {
    id: "hire_for_baby",
    name: "Your complete baby equipment hire service",
    url: "https://www.hireforbaby.com/",
    countries: [],
    category: "other",
  },
  plenty_valley_florist: {
    id: "plenty_valley_florist",
    name: "Order Flowers",
    url: "https://plentyvalleyflorist.com.au/",
    countries: [],
    category: "other",
  },
  purely_gourmet: {
    id: "purely_gourmet",
    name: "Gifts Delivered",
    url: "https://purelygourmet.com.au/",
    countries: [],
    category: "other",
  },
  the_bach_bar: {
    id: "the_bach_bar",
    name: "The best bartending service for bachelorettes!",
    url: "https://fareharbor.com/embeds/book/thebachbar/items/?flow=764535&language=en-us&a=yes&full-items=yes&back=https://bachbarnash.com/",
    countries: [],
    category: "experiences",
  },
};

export const boardingPassCollectFields = {
  guest_first_name: {
    name: "First name",
  },
  guest_last_name: {
    name: "Last name",
  },
  guest_email: {
    name: "Email",
  },
  guest_phone: {
    name: "Phone number",
  },
  guest_address: {
    name: "Address",
  },
  guest_language: {
    name: "Language",
  },
  guest_dob: {
    name: "Date of birth",
  },
  num_pets: {
    name: "Number of Pets",
  },
  checkin_time: {
    name: "Check-in time",
  },
  checkout_time: {
    name: "Check-out time",
  },
};

export const collectFieldTypeEnum = {
  checkbox: "checkbox",
  text: "text",
  single_select: "single_select",
  multi_select: "multi_select",
  number: "number",
  image: "image",
  date: "date",
  time: "time",
  time_offset: "time_offset",
  traveler_input: "traveler_input",
};

export const weatherEnum = {
  sunny_hot: "sun",
  rainy: "rain",
  snowy: "snowy",
};

export const deviceTriggerEnum = {
  first_noise_loud: "noise_loud",
  second_noise_loud: "second_noise_loud",
  third_noise_loud: "third_noise_loud",
  noise_quiet: "noise_quiet",
  door_unlocked: "door_unlocked",
  door_locked: "door_locked",
  device_disconnected: "device_disconnected",
  device_connected: "device_connected",
  door_lock_error: "lock_error",
  recovery_code_viewed: "recovery_code_viewed",
  no_available_codes: "no_available_codes",
  low_battery: "low_battery",
  device_offline_no_set_codes: "device_offline_no_set_codes",
};

export const sportConditionEnum = {
  ski: "ski",
  surf: "surf",
};

export const recommendedConditions = {
  booking_channel: "BookingChannelField",
  booking_lead_time: "time_offset",
  booking_status: "BookingStatusField",
  booking_tags: "multi_select",
  total_guest_paid: "number",
  guest_tags: "multi_select",
  rating: "SentimentField",
  listing_tags: "multi_select",
  total_people_booked: "number",
  num_nights: "number",
  num_pets: "number",
};

export const experienceMessageFields = [
  "guest_first_name",
  "guest_last_name",
  "guest_name",
  "keycard_link",
  "checkin_date",
  "checkout_date",
  "checkin_time",
  "checkout_time",
  "listing_name",
  "listing_city",
  "listing_state",
  "total_people_booked",
  "booking_channel",
  "wifi_password",
  "wifi_network",
  "listing_address",
  "listing_rules",
  "checkin_directions",
  "total_guest_paid",
  "booking_status",
  "integration_booking_id",
  "guest_govt_id_back",
  "guest_govt_id_front",
  "guest_selfie",
  "guest_other_id",
  "fee",
  "deposit",
  "pet_fee",
];

export const experienceDefaultTriggers = {
  cnf: {
    trigger: "bkg_cfrmd",
  },
  pci: {
    trigger: "cin",
    time_offset: -259200000,
  },
  ci: {
    trigger: "cin",
    time_offset: -43200000,
  },
  cu: {
    trigger: "cin",
    time_offset: 86400000,
  },
  co: {
    trigger: "out",
    time_offset: -86400000,
  },
  ps: {
    trigger: "out",
    time_offset: 604800000,
  },
};
