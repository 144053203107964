import React from "react";
import PropTypes from "prop-types";
import BaseFieldComponent from "../BaseFieldComponent";
import ElapsedTime from "../../../../components/TextFields/ElapsedTime";
import {getElapsedTimeLabel} from "../../../../utilities/helperFunctions";

const BookingLeadTimeField = ({
  fieldData,
  handleValueChange,
  options,
  ...props
}) => {
  const renderInput = () => (
    <ElapsedTime
      value={fieldData.val}
      onValueChange={(newValue) => {
        handleValueChange(fieldData.type, newValue);
      }}
      edit
    />
  );

  const getOperators = () => ["is_greater_than", "is_less_than"];

  const getLabel = () => getElapsedTimeLabel(Number(fieldData?.val));

  return (
    <BaseFieldComponent
      renderInput={renderInput}
      getLabel={getLabel}
      getOperators={getOperators}
      {...props}
    />
  );
};

BookingLeadTimeField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default BookingLeadTimeField;
