import React from "react";
import PropTypes from "prop-types";
import {bookingChannelLabels} from "configuration/constants";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";
import SelectChipContainer from "../../../../connectui/component/input/SelectChipContainer";
import BaseFieldComponent from "../BaseFieldComponent";

const availableChannels = bookingChannelLabels;

const BookingChannelField = ({
  fieldData,
  handleValueChange,
  options,
  ...props
}) => {
  const renderInput = () => (
    <SelectChipContainer
      options={Object.keys(availableChannels)}
      selectedOptions={fieldData?.val}
      onChange={(newSelection) => {
        handleValueChange(
          fieldData.type,
          Array.isArray(newSelection)
            ? newSelection.filter((f) => f !== "")
            : newSelection,
        );
      }}
      multiSelect={true}
    />
  );

  const getOperators = () => [
    "contains",
    "not_contains",
    "exists",
    "not_exists",
    "is_one_of",
  ];

  return (
    <BaseFieldComponent
      renderInput={renderInput}
      getLabel={() => getDefaultFieldLabel(fieldData?.val)}
      getOperators={getOperators}
      {...props}
    />
  );
};

BookingChannelField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default BookingChannelField;
