export const onboardingContent = {
  boarding_pass: {
    buttons: [
      {
        article_id: "8813662",
        text: "What is guest verification?",
      },
      {
        article_id: "8813664",
        text: "How does guest verification work?",
      },
      {
        article_id: "8813667",
        text: "How to set up guest verification",
      },
    ],
    description:
      "Set up the Boarding Pass to collect your guests\u2019 IDs, signed rental agreements, contact details and more.\n\nStart by choosing a verification template that fits your needs, or learn more below.",
    image_url:
      "https://enso-image-assets.s3.amazonaws.com/About+Card+Images/Verification+Image.webp",
    title: null,
  },
  brand: {
    buttons: [
      {
        article_id: "8813889",
        text: "What is branding?",
      },
      {
        article_id: "8813649",
        text: "How do brand settings work?",
      },
      {
        article_id: "8813653",
        text: "How to set up brand settings",
      },
    ],
    description:
      "Customize your guest-facing Boarding Pass with your logo, brand colors, social links, and more. Establish a unique identity for your business. \n\nRead the guide below to learn more. ",
    image_url:
      "https://gcamkobrlepn-u2971.pressidiumcdn.com/wp-content/uploads/2023/01/Boarding-Pass-Security.webp",
    title: null,
  },
  checkin_steps: {
    buttons: [
      {
        article_id: "8813712",
        text: "How do check-in instructions work?",
      },
      {
        article_id: "8813713",
        text: "How to set up check-in instructions",
      },
    ],
    description:
      "Create a set of step-by-step instructions for your guests on how to check-in to the property. Add detailed descriptions and pictures for clarity.\u00a0\n\nLearn more in the guide below.",
    image_url:
      "https://enso-image-assets.s3.amazonaws.com/About+Card+Images/Check-in+Instructions+Image.webp",
    title: null,
  },
  checkout_steps: {
    buttons: [
      {
        article_id: "8813732",
        text: "How do checkout instructions work?",
      },
      {
        article_id: "8813734",
        text: "How to set up checkout instructions",
      },
    ],
    description:
      "Create a set of step-by-step instructions for your guests on how to check out from the property. Add detailed descriptions and pictures for clarity.\u00a0\n\nLearn more in the guide below.",
    image_url:
      "https://enso-image-assets.s3.amazonaws.com/About+Card+Images/Check-out+Instructions+Image.webp",
    title: null,
  },
  crm: {
    buttons: [
      {
        article_id: "8813116",
        text: "Connect your CRM",
      },
      {
        article_id: "8813637",
        text: "Export your data",
      },
      {
        article_id: "8813637",
        text: "Explore guest insights",
      },
    ],
    description:
      "Explore guest data collected in the boarding pass, learn how to send marketing campaigns by connecting your CRM and export your data to your spreadsheets.",
    image_url:
      "https://gcamkobrlepn-u2971.pressidiumcdn.com/wp-content/uploads/2023/01/CRM-Export-v1.webp",
    title: null,
  },
  experiences: {
    ci: {
      buttons: [
        {
          article_id: "8813684",
          text: "What is an automated message?",
        },
        {
          article_id: "8813688",
          text: "How to set up automated messages",
        },
      ],
      description:
        "Create automated messages for various stages of the guest journey.\n\nRead the guide below to learn more. ",
      image_url:
        "https://enso-image-assets.s3.amazonaws.com/About+Card+Images/Messages+Image.webp",
      title: null,
    },
    cnf: {
      buttons: [
        {
          article_id: "8813684",
          text: "What is an automated message?",
        },
        {
          article_id: "8813688",
          text: "How to set up automated messages",
        },
      ],
      description:
        "Create automated messages for various stages of the guest journey.\n\nRead the guide below to learn more. ",
      image_url:
        "https://enso-image-assets.s3.amazonaws.com/About+Card+Images/Messages+Image.webp",
      title: null,
    },
    co: {
      buttons: [
        {
          article_id: "8813684",
          text: "What is an automated message?",
        },
        {
          article_id: "8813688",
          text: "How to set up automated messages",
        },
      ],
      description:
        "Create automated messages for various stages of the guest journey.\n\nRead the guide below to learn more. ",
      image_url:
        "https://enso-image-assets.s3.amazonaws.com/About+Card+Images/Messages+Image.webp",
      title: null,
    },
    cu: {
      buttons: [
        {
          article_id: "8813684",
          text: "What is an automated message?",
        },
        {
          article_id: "8813688",
          text: "How to set up automated messages",
        },
      ],
      description:
        "Create automated messages for various stages of the guest journey.\n\nRead the guide below to learn more. ",
      image_url:
        "https://enso-image-assets.s3.amazonaws.com/About+Card+Images/Messages+Image.webp",
      title: null,
    },
    pci: {
      buttons: [
        {
          article_id: "8813684",
          text: "What is an automated message?",
        },
        {
          article_id: "8813688",
          text: "How to set up automated messages",
        },
      ],
      description:
        "Create automated messages for various stages of the guest journey.\n\nRead the guide below to learn more. ",
      image_url:
        "https://enso-image-assets.s3.amazonaws.com/About+Card+Images/Messages+Image.webp",
      title: null,
    },
    ps: {
      buttons: [
        {
          article_id: "8813735",
          text: "What is a review reminder?",
        },
        {
          article_id: "8813739",
          text: "How to set review reminders up",
        },
        {
          article_id: "8813741",
          text: "How to get more great reviews",
        },
      ],
      description:
        "Prompt your guests to leave a review, using sentiment analysis on guest messages.\n\nRead the guide below to learn more.",
      image_url:
        "https://enso-image-assets.s3.amazonaws.com/About+Card+Images/Messages+Image.webp",
      title: null,
    },
  },
  fees: {
    buttons: [
      {
        article_id: "8813123",
        text: "How do fees work?",
      },
      {
        article_id: "8813681",
        text: "How to create a fee",
      },
      {
        article_id: "8813683",
        text: "Examples of fees",
      },
    ],
    description:
      "Set up the Boarding Pass to add mandatory fees, such as pet fees, safety deposits, damage waivers and others. \n\nStart by choosing a suitable fee template, create your own from scratch, or learn more below.",
    image_url:
      "https://enso-image-assets.s3.amazonaws.com/About+Card+Images/Fees+Image.webp",
    title: null,
  },
  gje: {
    buttons: [
      {
        article_id: "8808117",
        text: "What is the Guest Journey all about?",
      },
      {
        article_id: "8806925",
        text: "How to use the Guest Journey Editor",
      },
    ],
    description: [
      "Create unique guest flows for your listings. Set up automated messages and steps of your Boarding Pass, such as verification, check-in, and more.",
      " \n\n",
      "Start by following the steps in the Editor.\u00a0Learn more below.",
    ],
    image_url:
      "https://gcamkobrlepn-u2971.pressidiumcdn.com/wp-content/uploads/2023/01/Boarding-Pass-Security.webp",
    title: null,
  },
  guidebooks: {
    buttons: [
      {
        article_id: "8813724",
        text: "What is a guidebook?",
      },
      {
        article_id: "8813726",
        text: "How do guidebooks work?",
      },
      {
        article_id: "8813730",
        text: "How to set up a guidebook",
      },
    ],
    description:
      "Provide guests with curated key information about the property, neighborhood, local attractions, and more\u2014all accessible through the Boarding Pass.\u00a0\n\nLearn more in the guide below.",
    image_url:
      "https://enso-image-assets.s3.amazonaws.com/About+Card+Images/Guidebook+Image.webp",
    title: null,
  },
  listing_group: {
    buttons: [
      {
        article_id: "8808131",
        text: "What is a listing group?",
      },
      {
        article_id: "8808175",
        text: "How to set up a listing group",
      },
    ],
    description:
      "Create a listing group to apply specific rules and conditions exclusively to the listings within this group.\n\nRead the guide below to learn more. \n",
    image_url:
      "https://gcamkobrlepn-u2971.pressidiumcdn.com/wp-content/uploads/2023/01/Boarding-Pass-Security.webp",
    title: null,
  },
  local_services: {
    buttons: [
      {
        article_id: "8813702",
        text: "What is a local service?",
      },
      {
        article_id: "8813706",
        text: "How do local services work?",
      },
      {
        article_id: "8813708",
        text: "How to set up local services",
      },
      {
        article_id: "8813709",
        text: "Example of local services",
      },
    ],
    description:
      "Set up the Boarding Pass to include affiliate links for local services and programs by Uber, Viator, etc.\n\nStart by choosing a suitable local service template, create your own, or learn more below.",
    image_url:
      "https://enso-image-assets.s3.amazonaws.com/About+Card+Images/Local+Service+Image.webp",
    title: null,
  },
  main_cta: {
    main: {
      buttons: [
        {
          article_id: "8854656",
          text: "Get Started",
        },
      ],
      description:
        "Digitize and monetize your guest journey in a few simple steps.",
      image_url:
        "https://enso-image-assets.s3.amazonaws.com/CTA+Images-Listings/OnBoarding+Welcome+Thumbnail.jpg",
      title: "Your Guest Experience",
    },
    step1: {
      buttons: [
        {
          article_id: "8808175",
          text: "Create listing groups",
        },
      ],
      description:
        "Organize your listings into groups and apply rules and conditions to each.",
      image_url:
        "https://enso-image-assets.s3.amazonaws.com/CTA+Images-Listings/Create+listing+group.webp",
      title: "Create your listing groups",
    },
    step2: {
      buttons: [
        {
          article_id: "8813746",
          text: "Add teammates",
        },
      ],
      description:
        "Add all the members of your team, set their roles, permissions, and notifications.",
      image_url:
        "https://enso-image-assets.s3.amazonaws.com/CTA+Images-Listings/Add+your+Team.webp",
      title: "Invite your teammates",
    },
    step3: {
      buttons: [
        {
          article_id: "8806925",
          text: "Complete setup",
        },
      ],
      description:
        "Complete the settings in the Guest Journey Editor to go live with your listings.",
      image_url:
        "https://enso-image-assets.s3.amazonaws.com/CTA+Images-Listings/Build+your+guest+journey.webp",
      title: "Build your guest journey",
    },
  },
  upsells: {
    buttons: [
      {
        article_id: "8813695",
        text: "What is a vacation rental upsell?",
      },
      {
        article_id: "8813697",
        text: "How do upsells work?",
      },
      {
        article_id: "8813698",
        text: "How to create an upsell",
      },
      {
        article_id: "8813699",
        text: "Maximize upsell conversion - popcorn pricing",
      },
    ],
    description:
      "Set up the Boarding Pass to offer upsells such as early check-in, late check-out, or extra coffee pods.\n\nStart by choosing a suitable upsell template, create your own from scratch, or learn more below.",
    image_url:
      "https://enso-image-assets.s3.amazonaws.com/About+Card+Images/Upsell+Image.webp",
    title: null,
  },
  wifi: {
    buttons: [
      {
        article_id: "8813716",
        text: "Where does the Wi-Fi appear?",
      },
      {
        article_id: "8813721",
        text: "How to add Wi-Fi to your listings",
      },
    ],
    description:
      "Add your properties\u2019 wifi information to your Boarding Pass.\n\nRead the guide below to learn more. ",
    image_url:
      "https://enso-image-assets.s3.amazonaws.com/About+Card+Images/Wifi+Image.webp",
    title: null,
  },
};

export const defaultKeycard = {
  experience_type: "key",
  connected_to: "ALL",
  enabled: true,
  name: "Default Keycard",
  actions: [
    {
      trigger: {
        trigger: "key",
      },
      actions: [
        {
          action_id: "keycard",
          name: null,
          repeat_until: null,
          delay: 0,
          conditions: [],
          response_actions: [],
          invoke_async: true,
          manual_release_checkin: false,
          restrict_checkin: 1440,
          restrict_checkout: 86400000,
          restrict_verification: -1,
          restrict_address: -1,
          restrict_codes: 7200000,
          show_info_before_verification: false,
          enter_details: [
            {
              field_type: "text",
              field: "guest_first_name",
              required: true,
              field_options: [],
              field_label: null,
              validation: null,
            },
            {
              field_type: "text",
              field: "guest_last_name",
              required: true,
              field_options: [],
              field_label: null,
              validation: null,
            },
            {
              field_type: "text",
              field: "guest_email",
              required: true,
              field_options: [],
              field_label: null,
              validation: null,
            },
            {
              field_type: "text",
              field: "guest_phone",
              required: false,
              field_options: [],
              field_label: null,
              validation: null,
            },
          ],
          id_upload: {
            govt_id_front: false,
            govt_id_back: false,
            selfie: false,
          },
          verification_service: null,
          agreement: [],
          last_step_completed: null,
        },
      ],
    },
  ],
};

export const fieldParams = {
  sent: {
    path: ["notification", "sent"],
    type: "number",
    hidden: true,
    field_type: "number",
  },
  booking_nightly_rate: {
    path: ["booking", "payment", "nightly_rate"],
    type: "number",
    field_type: "number",
  },
  booking_payment_amount_remaining: {
    path: ["booking", "payment", "payment_amount_remaining"],
    field_type: "number",
    type: "number",
  },
  start: {
    name: "Start",
    path: ["responses", "start"],
    hidden: true,
  },
  page: {
    name: "Page",
    path: ["responses", "page"],
    hidden: true,
  },
  guest_group_id: {
    name: "Guest listing groups",
    path: ["guest", "listing_groups"],
    type: "keyword",
    filter_available: true,
    field_type: "GuestGroupIdField",
  },
  guest_updated_at: {
    name: "Guest updated at",
    path: ["guest", "updated_at"],
    hidden: true,
    type: "date",
  },
  booking_created_at: {
    name: "Booking created at",
    field_type: "date",
    path: ["booking", "created_at"],
    hidden: false,
    type: "date",
  },
  booking_lead_time: {
    name: "Booking lead time",
    field_type: "time_offset",
    path: ["booking"],
    type: "number",
  },
  booking_id: {
    name: "Enso booking id",
    path: ["booking", "booking_id"],
    hidden: true,
  },
  booking_listing_group_id: {
    name: "Booking listing group",
    path: ["booking", "listing_group_id"],
    field_type: "text",
    type: "keyword",
  },
  guest_dob_dt: {
    name: "Guest DOB - datetime",
    path: ["guest", "guest_metadata", "date_of_birth"],
    hidden: true,
  },
  guest_age: {
    name: "Guest Age",
    field_type: "number",
    path: ["guest", "guest_metadata", "date_of_birth"],
  },
  guest_selfie: {
    name: "Guest selfie URL",
    path: ["guest", "guest_metadata", "identity_docs"],
    hidden: true,
  },
  guest_govt_id_front: {
    name: "Guest government ID URL - front of ID",
    path: ["guest", "guest_metadata", "identity_docs"],
    hidden: true,
  },
  guest_govt_id_back: {
    name: "Guest government ID URL - back of ID",
    path: ["guest", "guest_metadata", "identity_docs"],
    hidden: true,
  },
  guest_other_id: {
    name: "Guest ID URls - unknown type",
    path: ["guest", "guest_metadata", "identity_docs"],
    hidden: true,
  },
  deposit: {
    name: "Deposit Transaction ID",
    path: ["booking"],
    hidden: true,
  },
  fee: {
    name: "Fee transaction ID",
    path: ["booking"],
    hidden: true,
  },
  pet_fee: {
    name: "Pet fee transaction ID",
    path: ["booking"],
    hidden: true,
  },
  integration_booking_id: {
    name: "PMS Booking ID",
    path: ["booking", "integration_booking_id"],
    field_type: "text",
  },
  guest_id: {
    name: "Guest Id",
    path: ["guest", "guest_id"],
    type: "keyword",
    hidden: true,
  },
  guest_address: {
    name: "Address",
    path: ["guest", "guest_metadata", "address"],
    type: "keyword",
    field_type: "text",
  },
  guest_dob: {
    name: "Date of birth",
    field_type: "date",
    path: ["guest", "guest_metadata", "date_of_birth"],
    type: "keyword",
  },
  guest_travel_reason: {
    name: "Travel reason",
    path: ["guest", "tags"],
    type: "keyword",
    hidden: true,
  },
  wifi_network: {
    name: "Wifi Network",
    path: ["listing", "listing_content", "policy", "wifi_info", "ssid"],
    reverse_path: ["policy", "wifi_info", "ssid"],
    field_type: "text",
    type: "keyword",
  },
  wifi_password: {
    name: "Wifi Password",
    path: ["listing", "listing_content", "policy", "wifi_info", "password"],
    field_type: "text",
    reverse_path: ["policy", "wifi_info", "password"],
    type: "keyword",
  },
  messages_guest_id: {
    name: "Message Guest Id",
    path: ["message", "guest_id"],
    type: "text",
    hidden: true,
  },
  guest_email: {
    name: "Email",
    path: ["guest", "email"],
    type: "text",
    field_type: "text",
  },
  listing_tags: {
    name: "Listing Tags",
    field_type: "multi_select",
    path: ["listing", "tags"],
    type: "keyword",
    append_keyword: true,
  },
  listing_num_bedrooms: {
    name: "# bedrooms",
    path: ["listing", "listing_content", "rooms"],
    field_type: "number",
    type: "keyword",
    hidden: false,
  },
  booking_post_stay_gap: {
    field_type: "time_offset",
    name: "Post-checkout gap until next booking",
    path: ["booking"],
    hidden: true,
  },
  listing_num_bathrooms: {
    name: "# bathrooms",
    field_type: "number",
    path: ["listing", "listing_content", "rooms"],
    type: "keyword",
    hidden: false,
  },
  listing_id: {
    name: "Listing Id",
    path: ["listing", "listing_id"],
    field_type: "text",
    type: "text",
  },
  device_id: {
    name: "Device Id",
    path: ["device", "device_id"],
    type: "keyword",
    hidden: true,
  },
  month: {
    name: "Current month",
    field_type: "number",
    path: [],
    options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  time_of_day: {
    name: "Current time of day",
    field_type: "time",
    path: ["listing"],
    mock: "2:00",
  },
  notification_type: {
    name: "Notification type",
    path: ["notification", "notification_type"],
    type: "keyword",
    hidden: true,
  },
  checkin_date: {
    name: "Check-in date",
    field_type: "date",
    path: ["booking"],
    mock: "Jan 1st, 2021",
    filter_available: true,
    type: "date",
  },
  checkout_date: {
    name: "Check-out date",
    field_type: "date",
    path: ["booking"],
    mock: "Jan 6th, 2021",
    filter_available: true,
    type: "date",
  },
  listing_rules: {
    name: "Listing rules",
    path: ["listing", "listing_content", "rules"],
    mock: "488 Wellington St West, Toronto, ON",
    type: "text",
    hidden: true,
  },
  checkin_time: {
    name: "Check-in time",
    field_type: "time",
    path: [],
    mock: 1723151402000,
    type: "number",
  },
  checkout_time: {
    name: "Check-out time",
    field_type: "time",
    path: [],
    mock: 1723237802000,
    type: "number",
  },
  max_kids: {
    name: "Max # Children",
    description: "Max # of Children",
    path: ["listing", "max_guests", "children"],
    mock: "4 kids",
    type: "number",
    field_type: "number",
  },
  max_adults: {
    name: "Max # Adults",
    path: ["listing", "max_guests", "persons"],
    mock: "4 adults",
    type: "number",
    field_type: "number",
  },
  zip_code: {
    name: "Listing zip code",
    path: ["listing", "address", "zip_code"],
    mock: "M3F 2V5",
    type: "text",
    field_type: "text",
  },
  listing_name: {
    name: "Listing name",
    path: ["listing"],
    mock: "Beautiful House on the Water - Premium Suite",
    type: "text",
    field_type: "text",
  },
  listing_currency: {
    name: "Listing currency",
    path: ["listing", "currrency"],
    mock: "USD",
    type: "text",
    field_type: "text",
  },
  listing_nickname: {
    name: "Listing nickname",
    path: ["listing", "nickname"],
    mock: "LUX-APT 1",
    type: "text",
    field_type: "text",
  },
  question_type: {
    name: "Question type",
    path: ["message", "tags", "question_type"],
    mock: "pool",
    type: "keyword",
    append_keyword: true,
    hidden: true,
  },
  listing_address: {
    name: "Listing address",
    path: ["listing", "address", "formatted"],
    mock: "488 Wellington St West, Toronto, ON",
    type: "text",
    field_type: "text",
  },
  listing_latitude: {
    name: "Listing latitude",
    path: ["listing", "address", "lat"],
    type: "text",
    field_type: "text",
  },
  listing_longitude: {
    name: "Listing longitude",
    path: ["listing", "address", "lng"],
    type: "text",
    field_type: "text",
  },
  country: {
    name: "Listing country",
    field_type: "text",
    path: ["listing", "address", "country"],
    mock: "CA",
    type: "keyword",
    append_keyword: true,
  },
  listing_state: {
    name: "Listing state",
    path: ["listing", "address", "state"],
    mock: "ON",
    type: "text",
    field_type: "text",
  },
  listing_city: {
    name: "Listing city",
    path: ["listing", "address", "city"],
    mock: "Toronto",
    type: "text",
    field_type: "text",
  },
  listing_street: {
    name: "Listing street",
    path: ["listing", "address", "street"],
    field_type: "text",
  },
  checkin_directions: {
    name: "Check-in directions",
    path: ["listing", "listing_content", "access_method", "description"],
    reverse_path: ["access_method", "description"],
    mock: "Please inquire at the reception about how to check in",
    type: "text",
    hidden: true,
  },
  checkout_directions: {
    name: "Check-out directions",
    path: [
      "listing",
      "listing_content",
      "access_method",
      "checkout_description",
    ],
    type: "text",
    hidden: true,
  },
  smoking_policy: {
    name: "Smoking policy",
    field_type: "checkbox",
    path: ["listing", "listing_content", "policy", "smoking_allowed"],
    mock: "Smoking is now allowed",
    type: "boolean",
    hidden: true,
  },
  num_pets: {
    name: "Number of Pets",
    field_type: "number",
    path: ["booking", "num_pets"],
    mock: "Pets in booking",
    type: "number",
    hidden: false,
  },
  booking_tags: {
    name: "Booking Tags",
    field_type: "multi_select",
    type: "keyword",
    path: ["booking", "tags"],
    options: [
      "Early check-in",
      "Late check-out",
      "Revoked",
      "Verified",
      "Deposit collected",
      "Deposit paid",
      "Last Minute",
      "Inquiry",
      "Bypass Checkin Restrictions",
      "Bypass Checkout Restrictions",
      "Boarding Pass Opened",
      "Checked-In Through Boarding Pass",
      "Automatic Verification",
      "Manual Verification",
    ],
    filter_available: true,
    append_keyword: true,
  },
  booking_action: {
    name: "Booking actions",
    field_type: "multi_select",
    path: ["booking", "actions"],
    type: "keyword",
    hidden: true,
  },
  pet_policy: {
    name: "Pets allowed",
    path: [
      "listing",
      "listing_content",
      "policy",
      "pet_policy",
      "allowed_pets",
    ],
    mock: "Pets are not allowed",
    type: "boolean",
    hidden: true,
  },
  guest_first_name: {
    name: "First name",
    path: ["guest", "name"],
    mock: "Johhny",
    type: "text",
    field_type: "text",
  },
  guest_name: {
    name: "Guest name",
    path: ["guest", "name"],
    mock: "Johhny",
    type: "text",
    field_type: "text",
  },
  guest_last_name: {
    name: "Last name",
    path: ["guest", "name"],
    mock: "Johnny Appleseed",
    type: "text",
    field_type: "text",
  },
  guest_destination: {
    name: "Guest destination",
    path: ["guest", "destination"],
    mock: "Ontario",
    type: "text",
    hidden: true,
  },
  guest_language: {
    name: "Language",
    path: ["guest", "language"],
    mock: "ab",
    type: "keyword",
    field_type: "text",
  },
  guest_phone: {
    name: "Phone number",
    path: ["guest", "phone"],
    mock: "647-333-2773",
    type: "text",
    field_type: "text",
    hidden: false,
  },
  reply_phone: {
    name: "Reply phone #",
    path: ["guest", "phone"],
    mock: "647-771-3557",
    type: "text",
    field_type: "text",
    hidden: true,
  },
  unread_messages: {
    name: "Unread Messages",
    path: ["guest", "unread_messages"],
    mock: "647-771-3557",
    type: "boolean",
    field_type: "checkbox",
  },
  unanswered_messages: {
    name: "Is Message Answered or not",
    field_type: "checkbox",
    path: ["guest", "unanswered_messages"],
    mock: "647-771-3557",
    type: "boolean",
  },
  assigned_to: {
    name: "Assigned property manager name",
    field_type: "AssignedToField",
    path: ["enso_user"],
    mock: "Jane Doe",
    type: "text",
    filter_available: true,
  },
  listing_photo: {
    name: "Listing main photo",
    path: ["listing", "picture"],
    mock: "https://torontolife.com/wp-content/uploads/2019/10/HZIsuuNp-1832x1374.jpg",
    type: "text",
    hidden: true,
  },
  listing_channels: {
    name: "Listing channels",
    path: ["listing", "channels"],
    mock: "Airbnb",
    type: "keyword",
    hidden: true,
  },
  listing_active: {
    name: "Listing active",
    path: ["listing", "active"],
    field_type: "checkbox",
    mock: false,
    type: "boolean",
  },
  listing_checkin_time: {
    name: "Listing check-in time",
    field_type: "time",
    path: ["listing", "check_times", "check_in_time"],
  },
  listing_checkout_time: {
    name: "Listing check-out time",
    field_type: "time",
    path: ["listing", "check_times", "check_out_time"],
  },
  listing_type: {
    name: "Listing type",
    path: ["listing", "type"],
    mock: "apart_hotel",
    options: [
      "apart_hotel",
      "apartment",
      "bed_and_breakfast",
      "boatel",
      "boutique",
      "bungalow",
      "cabin_or_bungalow",
      "campground",
      "castle",
      "chalet",
      "charm_hotel",
      "condominium",
      "cottage",
      "cruise",
      "double_room",
      "efficiency_or_studio",
      "family",
      "ferry",
      "guest_farm",
      "guest_house_limited_service",
      "holiday_resort",
      "hostel",
      "hotel",
      "inn",
      "lodge",
      "log_cabin",
      "manor",
      "meeting_resort",
      "mobile_home",
      "monastery",
      "motel",
      "narrow_boat",
      "pension",
      "penthouse",
      "private_room",
      "quadruple",
      "ranch",
      "recreational_vehicle_park",
      "residential_apartment",
      "resort",
      "riad",
      "sailing_ship",
      "self_catering_accommodation",
      "shared_room",
      "shepherd_hut",
      "single_room",
      "ski_chalet",
      "studio",
      "suite",
      "tent",
      "tipi",
      "tower",
      "townhome",
      "tree_house",
      "triple_room",
      "trullo",
      "twin",
      "twin_or_double",
      "vacation_home",
      "villa",
      "watermill",
      "wildlife_reserve",
      "windmill",
      "yacht",
      "yurt",
      "barn",
      "boat",
      "building",
      "cabin",
      "caravan",
      "chacara",
      "chateau",
      "corporate_apartment",
      "estate",
      "farmhouse",
      "guesthouse",
      "house",
      "house_boat",
      "mas",
      "mill",
      "recreational_vehicle",
      "serviced_apartment",
    ],
    type: "keyword",
    hidden: true,
  },
  branding_logo: {
    name: "Branding logo",
    path: ["branding", "logo"],
    mock: "https://enso-image-assets.s3.amazonaws.com/enso_logo.png",
    type: "text",
    hidden: true,
  },
  keycard_link: {
    name: "Guest-specific boarding pass link",
    path: ["guest"],
    mock: "https://google.com",
    type: "text",
    field_type: "text",
  },
  keycard_code: {
    name: "Guest-specific boarding pass code",
    path: ["guest"],
    mock: "https://google.com",
    type: "text",
    hidden: true,
  },
  listing_keycard_link: {
    name: "Listing boarding pass link",
    path: ["listing", "listing_id"],
    mock: "https://google.com",
    type: "text",
    field_type: "text",
  },
  listing_group_id: {
    name: "Listing group",
    path: ["listing", "group_id"],
    type: "keyword",
    field_type: "text",
  },
  listing_errors: {
    name: "Listing errors",
    path: ["listing", "errors"],
    type: "keyword",
    field_type: "text",
  },
  booking_channel: {
    name: "Booking channel",
    path: ["booking", "channel"],
    mock: "Unknown",
    field_type: "BookingChannelField",
    options: [
      "Airbnb",
      "VRBO",
      "Expedia",
      "Abritel",
      "Agoda",
      "Atraveo",
      "Best Fewo",
      "Booking.com",
      "Casamundo",
      "E-Domizil",
      "Flipkey",
      "Google",
      "Holiday Lettings",
      "Happy Stay",
      "Rentals United",
      "Home Away",
      "Veepee",
      "Simplissimmo",
      "Hotel Tonight",
      "Facebook Messenger",
      "NineFlats",
      "Trip Advisor",
      "Direct Booking",
      "other",
    ],
    type: "keyword",
    filter_available: true,
  },
  time_until_checkin: {
    name: "Time until check-in",
    field_type: "time_offset",
    path: ["booking"],
  },
  time_until_checkout: {
    name: "Time until check-out",
    field_type: "time_offset",
    path: ["booking"],
  },
  booking_purchased_upsells: {
    name: "Booking purchased upsells",
    path: ["booking"],
    options: [
      "custom_upsell",
      "checkin",
      "checkout",
      "local_service",
      "gap_night",
      "viator",
      "ALL",
    ],
    field_type: "multi_select",
  },
  booking_purchased_fees: {
    name: "Booking purchased fees",
    path: ["booking"],
    options: ["flex", "deposit", "fee", "pet_fee", "custom_fee"],
    field_type: "multi_select",
  },
  booking_status: {
    name: "Booking status",
    field_type: "BookingStatusField",
    path: ["booking", "status"],
    options: [
      "inquiry",
      "pending",
      "confirmed",
      "declined",
      "checked_in",
      "checked_out",
      "cancelled",
      "expired_guest",
      "expired_host",
      "pending_guest",
    ],
    filter_available: true,
    mock: "confirmed",
    type: "keyword",
    append_keyword: true,
  },
  guest_status: {
    name: "Guest booking status",
    path: ["guest", "booking_status"],
    mock: "confirmed",
    type: "keyword",
    hidden: true,
  },
  booking_star_rating: {
    name: "Booking star rating",
    path: ["booking", "booking_metadata", "custom"],
    field_type: "number",
  },
  booking_payment_currency: {
    name: "Booking payment currency",
    path: ["booking", "payment", "currency"],
    mock: "CAD",
    type: "keyword",
    append_keyword: true,
    hidden: true,
  },
  booking_payment_status: {
    name: "Booking payment status",
    path: ["booking", "payment", "status"],
    mock: "paid",
    type: "keyword",
    append_keyword: true,
    field_type: "multi_select",
  },
  booking_payout: {
    name: "Booking payout amount",
    path: ["booking", "payment", "host_payout_amount"],
    mock: "0",
    type: "number",
    hidden: true,
  },
  payment: {
    name: "Booking Payment",
    path: ["booking", "payment"],
    type: "object",
    hidden: true,
  },
  total_guest_paid: {
    name: "Booking total guest paid",
    field_type: "number",
    path: ["booking", "payment", "total_guest_paid"],
    mock: "0",
    type: "number",
  },
  lock_access_code: {
    name: "Booking lock access code",
    path: ["booking", "booking_metadata", "lock_access", "access_code"],
    mock: "1252432",
    type: "number",
    hidden: true,
  },
  booking_guest_name: {
    name: "Booking guest name",
    path: ["booking", "name"],
    mock: "Guest Name",
    type: "name",
    hidden: true,
  },
  booking_listing: {
    name: "Booked listing",
    path: ["booking", "listing_id"],
    mock: "Listing ID",
    type: "text",
    hidden: true,
  },
  booking_listing_name: {
    name: "Booked listing",
    path: ["booking", "listing"],
    mock: "Listing name",
    type: "text",
    hidden: true,
  },
  num_child: {
    name: "# child in booking",
    path: ["booking", "num_child"],
    mock: "Children in booking",
    type: "number",
    hidden: true,
  },
  num_adult: {
    name: "# adults in booking",
    path: ["booking", "num_adult"],
    mock: "Adult in booking",
    type: "number",
    hidden: true,
  },
  total_people_booked: {
    name: "Min # of people in booking",
    field_type: "number",
    mock: "total_people_booked",
    path: ["booking"],
    type: "custom",
  },
  num_nights: {
    name: "Number of nights in booking",
    field_type: "number",
    path: ["booking", "num_nights"],
    mock: 3,
    type: "number",
  },
  sent_at: {
    name: "Sent time",
    path: ["message", "sent"],
    mock: "Jan 1st, 2021",
    type: "date",
    hidden: true,
  },
  message: {
    name: "Guest Message",
    path: ["message", "message"],
    mock: "Message Content",
    type: "text",
    hidden: true,
  },
  message_channel: {
    name: "Message channel",
    path: ["message", "channel"],
    mock: "SMS",
    type: "keyword",
    hidden: true,
  },
  message_topics: {
    name: "Message Topics",
    path: ["guest", "message_types"],
    append_keyword: true,
    type: "keyword",
    options: [
      "Complaint",
      "Amenity Question",
      "Refund Request",
      "Check-in/out Request",
      "Check-in/Check-out question",
      "Special Accommodations Request",
      "Stay Alteration Request",
      "Positive Feedback",
      "Transportation",
      "Local Recommendations",
      "Verification Question",
      "Other",
    ],
    field_type: "text",
    filter_available: true,
    hidden: false,
  },
  message_sender: {
    name: "Message sender",
    path: ["message", "sender_type"],
    mock: "SMS",
    type: "keyword",
    hidden: true,
  },
  message_sent_at: {
    name: "Message sent at",
    path: ["message", "sent"],
    type: "date",
    hidden: true,
  },
  sentiment: {
    name: "Guest satisfaction",
    path: ["message", "tags", "sentiment"],
    type: "keyword",
    append_keyword: true,
    hidden: true,
  },
  attachments: {
    name: "Message Attachments",
    path: ["message", "attachments"],
    mock: false,
    type: "boolean",
    hidden: true,
  },
  delivered: {
    name: "Message Delivered",
    path: ["message", "delivered"],
    mock: false,
    type: "boolean",
    hidden: true,
  },
  guest_integration: {
    name: "Guest integration type",
    path: ["guest", "integration"],
    mock: "ab",
    type: "keyword",
    hidden: true,
  },
  guest_stage: {
    name: "Guest Stage",
    path: ["guest", "stage"],
    mock: "lead",
    type: "keyword",
    hidden: true,
  },
  guest_channel: {
    name: "Guest Channel",
    path: ["guest", "channels"],
    mock: ["Airbnb"],
    type: "keyword",
    append_keyword: true,
    hidden: true,
  },
  guest_bookings: {
    name: "# of bookings",
    field_type: "number",
    path: ["guest", "bookings"],
    mock: "booking",
    type: "list",
  },
  guest_listing: {
    name: "Listings",
    path: ["guest", "listings"],
    type: "keyword",
    append_keyword: true,
    hidden: true,
  },
  num_listings: {
    name: "# of Listings visited",
    path: ["guest", "listings"],
    mock: "Cottage Ave",
    type: "list",
    hidden: true,
  },
  guest_location: {
    name: "Guest Booking Location",
    path: ["guest", "guest_location"],
    mock: "San Francisco",
    type: "text",
    hidden: true,
  },
  guest_country: {
    name: "Guest Country",
    path: ["guest", "country"],
    mock: "Algeria",
    type: "keyword",
    options: [
      "AF",
      "AX",
      "AL",
      "DZ",
      "AS",
      "AD",
      "AO",
      "AI",
      "AQ",
      "AG",
      "AR",
      "AM",
      "AW",
      "AU",
      "AT",
      "AZ",
      "BS",
      "BH",
      "BD",
      "BB",
      "BY",
      "BE",
      "BZ",
      "BJ",
      "BM",
      "BT",
      "BO",
      "BQ",
      "BA",
      "BW",
      "BV",
      "BR",
      "IO",
      "BN",
      "BG",
      "BF",
      "BI",
      "KH",
      "CM",
      "CA",
      "CV",
      "KY",
      "CF",
      "TD",
      "CL",
      "CN",
      "CX",
      "CC",
      "CO",
      "KM",
      "CG",
      "CD",
      "CK",
      "CR",
      "CI",
      "HR",
      "CU",
      "CW",
      "CY",
      "CZ",
      "DK",
      "DJ",
      "DM",
      "DO",
      "EC",
      "EG",
      "SV",
      "GQ",
      "ER",
      "EE",
      "ET",
      "FK",
      "FO",
      "FJ",
      "FI",
      "FR",
      "GF",
      "PF",
      "TF",
      "GA",
      "GM",
      "GE",
      "DE",
      "GH",
      "GI",
      "GR",
      "GL",
      "GD",
      "GP",
      "GU",
      "GT",
      "GG",
      "GN",
      "GW",
      "GY",
      "HT",
      "HM",
      "VA",
      "HN",
      "HK",
      "HU",
      "IS",
      "IN",
      "ID",
      "IR",
      "IQ",
      "IE",
      "IM",
      "IL",
      "IT",
      "JM",
      "JP",
      "JE",
      "JO",
      "KZ",
      "KE",
      "KI",
      "KR",
      "KP",
      "KW",
      "KG",
      "LA",
      "LV",
      "LB",
      "LS",
      "LR",
      "LY",
      "LI",
      "LT",
      "LU",
      "MO",
      "MK",
      "MG",
      "MW",
      "MY",
      "MV",
      "ML",
      "MT",
      "MH",
      "MQ",
      "MR",
      "MU",
      "YT",
      "MX",
      "FM",
      "MD",
      "MC",
      "MN",
      "ME",
      "MS",
      "MA",
      "MZ",
      "MM",
      "NA",
      "NR",
      "NP",
      "NL",
      "NC",
      "NZ",
      "NI",
      "NE",
      "NG",
      "NU",
      "NF",
      "MP",
      "NO",
      "OM",
      "PK",
      "PW",
      "PS",
      "PA",
      "PG",
      "PY",
      "PE",
      "PH",
      "PN",
      "PL",
      "PT",
      "PR",
      "QA",
      "RE",
      "RO",
      "RU",
      "RW",
      "BL",
      "SH",
      "KN",
      "LC",
      "MF",
      "PM",
      "VC",
      "WS",
      "SM",
      "ST",
      "SA",
      "SN",
      "RS",
      "SC",
      "SL",
      "SG",
      "SX",
      "SK",
      "SI",
      "SB",
      "SO",
      "ZA",
      "GS",
      "SS",
      "ES",
      "LK",
      "SD",
      "SR",
      "SJ",
      "SZ",
      "SE",
      "CH",
      "SY",
      "TW",
      "TJ",
      "TZ",
      "TH",
      "TL",
      "TG",
      "TK",
      "TO",
      "TT",
      "TN",
      "TR",
      "TM",
      "TC",
      "TV",
      "UG",
      "UA",
      "AE",
      "GB",
      "US",
      "UM",
      "UY",
      "UZ",
      "VU",
      "VE",
      "VN",
      "VG",
      "VI",
      "WF",
      "EH",
      "YE",
      "ZM",
      "ZW",
    ],
    field_type: "multi_select",
  },
  guest_inquiry: {
    name: "# of inquiries",
    path: ["guest", "inquiries"],
    mock: "inquiries",
    type: "object",
    sub_object: "listing_id",
    filter_available: true,
    field_type: "number",
    hidden: true,
  },
  rating: {
    name: "Guest Sentiment",
    field_type: "SentimentField",
    path: ["guest", "rating"],
    mock: "rating",
    filter_available: true,
    type: "number",
  },
  response_time: {
    name: "Guest Response Time",
    path: ["guest", "response_time"],
    mock: "response_time",
    type: "number",
    hidden: true,
  },
  total_rev: {
    name: "Money spent by Guest",
    path: ["guest", "total_rev"],
    mock: "total_rev",
    type: "number",
    field_type: "number",
    filter_available: true,
    hidden: true,
  },
  num_messages: {
    name: "# of Messages Sent By Guest",
    path: ["guest", "num_messages"],
    mock: "num_messages",
    type: "number",
    hidden: true,
  },
  guest_tags: {
    name: "Guest Tags",
    field_type: "multi_select",
    path: ["guest", "tags"],
    type: "keyword",
    filter_available: true,
    options: ["last_minute", "holiday", "thanksgiving"],
    append_keyword: true,
  },
  guest_type: {
    name: "Guest Type",
    path: ["guest", "guest_type"],
    mock: "guest",
    options: ["last_minute", "holiday", "thanksgiving"],
    type: "keyword",
  },
  guest_bill_title: {
    name: "Guest Bill Title",
    path: ["guest_bill", "title"],
  },
  guest_bill_total: {
    name: "Upsell Price Paid",
    path: ["guest_bill", "total"],
  },
  guest_bill_session_id: {
    name: "Stripe Payment Intent ID",
    path: ["guest_bill", "session_id"],
  },
  guest_bill_booking_id: {
    name: "Booking ID",
    path: ["guest_bill", "booking_id"],
    type: "keyword",
  },
  guest_bill_listing_group_id: {
    name: "Listing Group ID",
    path: ["guest_bill", "listing_group_id"],
    type: "keyword",
  },
  guest_bill_created_at: {
    name: "Created At",
    path: ["guest_bill", "created_at"],
    type: "date",
  },
  guest_bill_updated_at: {
    name: "Updated At",
    path: ["guest_bill", "updated_at"],
    type: "date",
  },
  guest_bill_status: {
    name: "Guest Bill Status",
    path: ["guest_bill", "status"],
    type: "keyword",
  },
  guest_bill_listing_resource: {
    name: "Guest Bill Listing Resource",
    path: ["guest_bill", "listing_resource"],
    type: "keyword",
  },
  guest_bill_product_type: {
    name: "Guest Bill Product Type",
    path: ["guest_bill", "product_type"],
    type: "keyword",
  },
  guest_bill_currency: {
    name: "Guest Bill Currency",
    path: ["guest_bill", "currency"],
    type: "keyword",
  },
  notification_message: {
    name: "Notification Message",
    path: ["notification", "message"],
    mock: "Notification Content",
    type: "text",
    hidden: true,
  },
  viewed: {
    name: "Notification Viewed",
    path: ["notification", "viewed"],
    mock: false,
    type: "boolean",
    hidden: true,
  },
  is_error: {
    name: "Notification error",
    path: ["notification", "errors"],
    mock: false,
    type: "boolean",
    hidden: true,
  },
  device_trigger: {
    name: "Device Trigger",
    path: ["notification", "device_trigger"],
    mock: false,
    type: "list",
    hidden: true,
  },
  message_stage_tags: {
    name: "Inquiring Guest Stage",
    path: ["message", "stage_tags"],
    mock: "closed_won",
    type: "keyword",
    append_keyword: true,
    hidden: true,
  },
  sort_id: {
    name: "Listing Resource Sort Id",
    path: ["listing_resource", "sort_id"],
    mock: "ALL#gb",
    type: "keyword",
    hidden: true,
  },
  booking_checkin_dt: {
    name: "Booking checkin datetime",
    path: ["booking", "checkin_raw"],
    hidden: true,
  },
  booking_checkout_dt: {
    name: "Booking checkout datetime",
    path: ["booking", "checkout_raw"],
    hidden: true,
  },
  checkin_raw: {
    name: "Check-in date",
    field_type: "date",
    path: ["booking", "checkin_raw"],
    type: "number",
    hidden: true,
  },
  checkout_raw: {
    name: "Check-out date",
    field_type: "date",
    path: ["booking", "checkout_raw"],
    type: "number",
    hidden: true,
  },
  enso_total_revenue: {
    name: "Total Upsell Revenue",
    path: ["guest", "enso_total_revenue"],
    field_type: "number",
    mock: "0",
    type: "number",
    filter_available: true,
    hidden: false,
  },
  guest_purchased_product_names: {
    name: "Purchased Product Names",
    path: ["guest", "product_names"],
    mock: "0",
    type: "keyword",
    field_type: "ProductNamesField",
    filter_available: true,
    append_keyword: true,
    hidden: false,
  },
  guest_purchased_product_types: {
    name: "Purchased Product Types",
    path: ["guest", "product_types"],
    mock: "0",
    type: "keyword",
    options: [
      "flex",
      "deposit",
      "fee",
      "pet_fee",
      "custom_fee",
      "custom_upsell",
      "checkin",
      "checkout",
      "local_service",
      "gap_night",
      "viator",
    ],
    field_type: "multi_select",
    filter_available: true,
    hidden: false,
  },
  guest_ai_summary: {
    name: "Guest AI Summary",
    path: ["guest", "guest_metadata", "ai_summary"],
    mock: "AI Summary",
    type: "text",
    hidden: true,
  },
};

export const triggerParams = {
  guest: [
    {
      trigger: "msg",
      text: "sends a message",
      enable_user_notification: true,
    },
    {
      trigger: "inq",
      text: "inquires",
      enable_user_notification: true,
    },
    {
      trigger: "bkg_cfrmd",
      text: "booking is confirmed",
      enable_user_notification: true,
    },
    {
      trigger: "bkg_pndg",
      text: "booking is pending",
      enable_user_notification: true,
    },
    {
      trigger: "bkg_cncld",
      text: "booking is cancelled",
      enable_user_notification: true,
    },
    {
      trigger: "bkg",
      text: "places a booking (confirmed, cancelled or pending)",
      enable_user_notification: true,
    },
    {
      trigger: "cin",
      text: "checks in",
      enable_user_notification: false,
    },
    {
      trigger: "out",
      text: "checks out",
      enable_user_notification: false,
    },
    {
      trigger: "pr_prch",
      text: "purchases/pays for",
      trigger_value: "fees_upsells",
      enable_user_notification: true,
    },
    {
      trigger: "pr_pndg",
      text: "requests an upsell that requires approval",
      trigger_value: "upsells",
      enable_user_notification: true,
    },
    {
      trigger: "kcc",
      text: "completes verification steps",
      enable_user_notification: true,
    },
    {
      trigger: "ver",
      text: "is verified automatically or manually by host",
      enable_user_notification: true,
    },
    {
      trigger: "need_bp_ver",
      text: "is flagged for additional verification",
      enable_user_notification: true,
    },
    {
      trigger: "pr_err",
      text: "experiences a product error",
      enable_user_notification: true,
    },
    {
      trigger: "pr_action",
      text: "purchase requires 3DS verification",
      enable_user_notification: true,
    },
  ],
  host: [
    {
      trigger: "pr_cncld",
      text: "denies a guest upsell purchase",
      trigger_value: "upsells",
      enable_user_notification: true,
    },
    {
      trigger: "pr_cfmrd",
      text: "approves a guest upsell purchase",
      trigger_value: "upsells",
      enable_user_notification: true,
    },
    {
      trigger: "pr_refd",
      text: "refunds a guest purchase",
      trigger_value: "fees_upsells",
      enable_user_notification: true,
    },
    {
      trigger: "dep_clct",
      text: "collects a guest safety deposit",
      enable_user_notification: true,
    },
    {
      trigger: "re_ver",
      text: "requests guest re-verification",
      enable_user_notification: true,
    },
    {
      trigger: "man_ver",
      text: "manually verifies guest",
      enable_user_notification: true,
    },
    {
      trigger: "deny_ver",
      text: "denies guest verification",
      enable_user_notification: true,
    },
    {
      trigger: "kc_bypass",
      text: "manually bypasses guest verification step",
      enable_user_notification: true,
    },
  ],
  device: [
    {
      trigger: "device_event",
      trigger_value: "device_trigger",
      enable_user_notification: true,
    },
  ],
};

export const triggerLabels = {
  lgr_rmv: {
    name: "Listing removed from group",
  },
  new_device: {
    name: "Device added",
  },
  message_error: {
    name: "Error sending message",
  },
  upd_booking: {
    name: "Listing removed from group",
  },
  lgr_upd: {
    name: "Listing added to group",
  },
  l_upd: {
    name: "Listing added to group",
  },
  action_err: {
    name: "Action failed",
  },
  action_skp: {
    name: "Action skipped",
  },
  derr: {
    name: "Device error",
  },
  "derr.update_code": {
    name: "Failed to update lock code",
  },
  wh: {
    name: "Webhook received",
  },
  key: {
    name: "Boarding Pass viewed",
  },
  pr_err: {
    name: "Purchase failed: Payment error",
  },
  pr_action: {
    name: "Purchase failed: Guest 3DS Verification required",
  },
  pr_cfmrd: {
    name: "Upsell approved",
  },
  pr_cncld: {
    name: "Upsell denied",
  },
  pr_pndg: {
    name: "Upsell requested",
  },
  pr_prch: {
    name: "Purchase successful",
  },
  pr_refd: {
    name: "Product Refunded",
  },
  dep_clct: {
    name: "Deposit Collected",
  },
  lr_err: {
    name: "Listing Resource failed to render",
  },
  now: {
    name: "Trigger now",
  },
  daily: {
    name: "Daily events",
  },
  specific_time: {
    name: "Scheduled event",
  },
  deny_ver: {
    name: "Verification denied",
  },
  ver_err: {
    name: "Verification Error",
  },
  kcc: {
    name: "Keycard verification complete",
  },
  kc_bypass: {
    name: "Verification step bypassed",
  },
  re_ver: {
    name: "Re-verification",
  },
  man_ver: {
    name: "Manual verification",
  },
  call: {
    name: "Guest phone call",
  },
  need_bp_ver: {
    name: "Boarding pass manual review required",
  },
  inq: {
    name: "Inquiry",
  },
  bkg: {
    name: "Booking",
  },
  cin: {
    name: "Check-in",
  },
  out: {
    name: "Check-out",
  },
  ver: {
    name: "Verification",
  },
  msg: {
    name: "Guest Message",
  },
  msg_upd: {
    name: "Message Update",
  },
  "derr.failed_to_generate_common_code": {
    name: "Failed to generate common code",
  },
  "derr.failed_to_generate_booking_code": {
    name: "Failed to generate booking access code",
  },
  device_event: {
    name: "Device Event",
  },
  bkg_cfrmd: {
    name: "Booking Confirmed",
  },
  bkg_pndg: {
    name: "Booking Pending",
  },
  bkg_cncld: {
    name: "Booking Cancelled",
  },
  l_del: {
    name: "Listing Deleted",
  },
  l_new: {
    name: "Listing Created",
  },
  exp_triggered: {
    name: "Experience Triggered",
  },
  host_msg: {
    name: "Host Message Sent",
  },
  msg_read: {
    name: "Guest Message Read",
  },
};

export const actionParams = {
  verification_report: {
    service: "ver",
    name: "Get verification report",
    triggers: [],
    optional_args: [],
    required_args: ["booking", "root", "guest", "listing"],
    skip_scheduling_time: 21600000,
    service_account: "ver",
  },
  unrevoke_guest: {
    service: "ver",
    name: "Unrevoke guest",
    triggers: [],
    optional_args: [],
    required_args: ["booking"],
    skip_scheduling_time: 21600000,
    service_account: "ver",
  },
  increment_billing_usage: {
    service: "billing",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "billing",
  },
  create_guest: {
    service: "bk",
    name: "Create guest",
    triggers: [],
    optional_args: [],
    required_args: ["root"],
    skip_scheduling_time: 21600000,
    service_account: "bk",
  },
  create_booking: {
    service: "bk",
    name: "Create booking",
    triggers: [],
    optional_args: [],
    required_args: ["booking"],
    skip_scheduling_time: 21600000,
    service_account: "bk",
  },
  update_inquiry: {
    service: "bk",
    name: "Update inquiry",
    triggers: [],
    optional_args: [],
    required_args: [],
    skip_scheduling_time: 21600000,
    service_account: "bk",
  },
  update_guest: {
    service: "bk",
    name: "Update guest",
    triggers: [],
    optional_args: [],
    required_args: ["guest"],
    skip_scheduling_time: 21600000,
    service_account: "bk",
  },
  update_booking: {
    service: "bk",
    name: "Update booking",
    triggers: [],
    optional_args: [],
    required_args: ["booking"],
    skip_scheduling_time: 21600000,
    service_account: "bk",
  },
  update_listing: {
    service: "ls",
    name: "Update booking",
    triggers: [],
    required_args: ["listing"],
    skip_scheduling_time: 21600000,
    mock_action_options: {
      body: {},
    },
    service_account: "ls",
  },
  create_listing: {
    service: "ls",
    name: "Update listing",
    triggers: [],
    required_args: ["listing"],
    skip_scheduling_time: 21600000,
    mock_action_options: {
      body: {},
    },
    service_account: "ls",
  },
  delete_listing: {
    service: "ls",
    name: "Delete listing",
    triggers: [],
    required_args: ["listing"],
    skip_scheduling_time: 21600000,
    mock_action_options: {
      body: {},
    },
    service_account: "ls",
  },
  keycard: {
    service: "kc",
    name: "Guest boarding pass",
    description: "",
    triggers: [
      {
        trigger: "key",
      },
    ],
    required_args: ["guest"],
    optional_args: ["booking", "listing"],
    skip_scheduling_time: 21600000,
    service_account: "kc",
  },
  create_listing_resource: {
    service: "ls",
    name: "Create listing resource",
    description: "",
    triggers: [],
    required_args: [],
    optional_args: [],
    skip_scheduling_time: 21600000,
    service_account: "ls",
  },
  update_listing_resource: {
    service: "ls",
    name: "Update listing resource",
    description: "",
    triggers: [],
    required_args: [],
    optional_args: [],
    skip_scheduling_time: 21600000,
    service_account: "ls",
  },
  delete_listing_resource: {
    service: "ls",
    name: "Delete listing resource",
    description: "",
    triggers: [],
    required_args: [],
    optional_args: [],
    skip_scheduling_time: 21600000,
    service_account: "ls",
  },
  airbnb: {
    service: "msg",
    name: "Airbnb Message",
    description: "Send an airbnb message",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: ["guest"],
    optional_args: ["booking", "listing"],
    mock_action_options: {
      body: [
        {
          language: "en",
          value: "Hey there",
          auto_translate: false,
          keycard: false,
        },
      ],
    },
    skip_scheduling_time: 21600000,
    service_account: "msg",
  },
  SMS: {
    service: "msg",
    name: "SMS Message",
    description: "Send an SMS message",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: ["guest"],
    optional_args: ["booking", "listing"],
    mock_action_options: {
      body: [
        {
          language: "en",
          value: "Hey there",
          auto_translate: false,
          keycard: false,
        },
      ],
    },
    skip_scheduling_time: 21600000,
    service_account: "msg",
  },
  email: {
    service: "msg",
    name: "Email",
    description: "Send a templated email",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: [],
    optional_args: ["booking", "listing"],
    mock_action_options: {
      auto_translate: false,
      keycard: false,
      subject: [
        {
          language: "en",
          value: "Hey there",
        },
      ],
      header: [
        {
          language: "en",
          value: "Hey there",
        },
      ],
      body: [
        {
          language: "en",
          value: "Hey there",
        },
      ],
    },
    skip_scheduling_time: 21600000,
    service_account: "msg",
  },
  ota_email: {
    service: "msg",
    name: "OTA Message",
    description: "Send a templated email to an OTA channel",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: [],
    optional_args: ["booking", "listing"],
    mock_action_options: {
      auto_translate: false,
      keycard: false,
      subject: [
        {
          language: "en",
          value: "Hey there",
        },
      ],
      header: [
        {
          language: "en",
          value: "Hey there",
        },
      ],
      body: [
        {
          language: "en",
          value: "Hey there",
        },
      ],
    },
    skip_scheduling_time: 21600000,
    service_account: "msg",
  },
  whatsapp: {
    service: "msg",
    name: "Whatsapp Message",
    description: "Send an whatsapp message",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: ["guest"],
    optional_args: ["booking", "listing"],
    mock_action_options: {
      template:
        "voluptatibus itaque repellat nesciunt tempore unde repellendus mollitia aperiam similique",
    },
    skip_scheduling_time: 21600000,
    service_account: "msg",
  },
  webhook: {
    service: "msg",
    name: "Webhook Message",
    description: "Send an webhook",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: ["guest"],
    optional_args: ["booking", "listing"],
    skip_scheduling_time: 21600000,
    service_account: "msg",
  },
  soap_api: {
    service: "msg",
    name: "Call a SOAP API",
    required_args: [],
    triggers: [],
    service_account: "msg",
  },
  create_messaging_account: {
    service: "msg",
    name: "Create a messaging account",
    triggers: [],
    required_args: ["guest"],
    optional_args: ["booking", "listing"],
    skip_scheduling_time: 21600000,
    service_account: "msg",
  },
  update_messaging_account: {
    service: "msg",
    name: "Create a messaging account",
    triggers: [],
    required_args: ["guest"],
    optional_args: ["booking", "listing"],
    skip_scheduling_time: 21600000,
    service_account: "msg",
  },
  update_message: {
    service: "msg",
    name: "Update a message",
    triggers: [],
    required_args: ["guest"],
    optional_args: ["booking", "listing"],
    skip_scheduling_time: 21600000,
    service_account: "msg",
  },
  create_message: {
    service: "msg",
    name: "Create a message",
    triggers: [],
    required_args: ["guest"],
    optional_args: ["booking", "listing"],
    skip_scheduling_time: 21600000,
    service_account: "msg",
  },
  respond: {
    service: "phn",
    name: "Respond to Call",
    description: "Respond to an inbound phone call",
    triggers: [
      {
        trigger: "call",
      },
    ],
    required_args: [],
    optional_args: ["enso_user", "guest"],
    mock_action_options: {
      dial: "ABC",
      voice_message: "Hi there this is a robot voice message",
      redirect: null,
    },
    skip_scheduling_time: 21600000,
    service_account: "phn",
  },
  call: {
    service: "phn",
    name: "Phone call",
    description: "Call the guest",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: [],
    optional_args: ["enso_user", "guest"],
    mock_action_options: {
      destination: "guest_phone",
      specific_destination_number: null,
      voice_message: "Hi there this is a robotic voice message",
      dial: "ABC",
      redirect: null,
      specific_redirect_number: null,
    },
    skip_scheduling_time: 21600000,
    service_account: "phn",
  },
  generate_permanent_codes: {
    service: "sl",
    name: "Generate Device Codes",
    triggers: [],
    required_args: ["device"],
    mock_action_options: null,
    skip_scheduling_time: 21600000,
    service_account: "sl",
  },
  retrieve_device_signup_config: {
    service: "sl",
    name: "Generate Permanent Codes",
    triggers: [],
    required_args: [],
    mock_action_options: null,
    skip_scheduling_time: 21600000,
    service_account: "sl",
  },
  create_device_account: {
    service: "sl",
    name: "Create device account",
    triggers: [],
    required_args: [],
    mock_action_options: null,
    skip_scheduling_time: 21600000,
    service_account: "sl",
  },
  create_device: {
    service: "sl",
    name: "Create device account",
    triggers: [],
    required_args: [],
    mock_action_options: null,
    skip_scheduling_time: 21600000,
    service_account: "sl",
  },
  delete_device: {
    service: "sl",
    name: "Delete device",
    triggers: [],
    required_args: [],
    mock_action_options: null,
    skip_scheduling_time: 21600000,
    service_account: "sl",
  },
  update_lock_code: {
    service: "sl",
    name: "Update lock code",
    triggers: [],
    required_args: [],
    mock_action_options: null,
    skip_scheduling_time: 21600000,
    service_account: "sl",
  },
  update_device: {
    service: "sl",
    name: "Delete device",
    triggers: [],
    required_args: [],
    mock_action_options: null,
    skip_scheduling_time: 21600000,
    service_account: "sl",
  },
  generate_temporary_codes: {
    service: "sl",
    name: "Generate Temporary Codes",
    triggers: [],
    required_args: [],
    mock_action_options: null,
    skip_scheduling_time: 7776000000,
    service_account: "sl",
  },
  retrieve_recovery_code: {
    service: "sl",
    name: "Retrieve Recovery Code",
    triggers: [],
    required_args: [],
    mock_action_options: null,
    skip_scheduling_time: 21600000,
    service_account: "sl",
  },
  delete_temporary_codes: {
    service: "sl",
    name: "Delete Temporary Codes",
    triggers: [],
    required_args: [],
    mock_action_options: null,
    skip_scheduling_time: 86400000,
    service_account: "sl",
  },
  unlock_door: {
    service: "sl",
    name: "Unlock",
    description: "Unlocks a smart lock",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: ["guest", "device"],
    mock_action_options: null,
    skip_scheduling_time: 21600000,
    service_account: "sl",
  },
  lock_door: {
    service: "sl",
    name: "Lock",
    description: "Locks a smart lock",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: ["guest", "device"],
    mock_action_options: null,
    skip_scheduling_time: 21600000,
    service_account: "sl",
  },
  reconnect: {
    service: "sl",
    name: "Reconnect device",
    triggers: [],
    required_args: ["guest", "device"],
    mock_action_options: null,
    skip_scheduling_time: 21600000,
    service_account: "sl",
  },
  delete_permanent_code: {
    service: "sl",
    name: "Delete Permanent Code",
    triggers: [],
    required_args: ["device"],
    mock_action_options: null,
    skip_scheduling_time: 21600000,
    service_account: "sl",
  },
  update_booking_status: {
    service: "bk",
    name: "Update Booking Status",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: ["booking", "connected_account"],
    mock_action_options: null,
    skip_scheduling_time: 21600000,
    service_account: "bk",
  },
  update_inquiry_status: {
    service: "bk",
    name: "Update Inquiry Status",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: ["booking", "connected_account"],
    mock_action_options: null,
    skip_scheduling_time: 21600000,
    service_account: "bk",
  },
  verify_guest: {
    service: "ver",
    name: "Verify Guest",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: ["guest", "booking", "listing"],
    skip_scheduling_time: 21600000,
    service_account: "ver",
  },
  create_verification_account: {
    service: "ver",
    name: "Create verification account",
    triggers: [],
    required_args: [],
    skip_scheduling_time: 21600000,
    service_account: "ver",
  },
  update_verification_account: {
    service: "ver",
    name: "Update verification account",
    triggers: [],
    required_args: [],
    skip_scheduling_time: 21600000,
    service_account: "ver",
  },
  collect_deposit: {
    service: "pr",
    name: "Collect deposit",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: ["listing", "booking", "guest"],
    skip_scheduling_time: 21600000,
    service_account: "pr",
  },
  pending_checkin: {
    service: "pr",
    name: "Approve early-checkin request",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: ["listing", "guest", "booking"],
    skip_scheduling_time: 21600000,
    service_account: "pr",
  },
  pending_upsell: {
    service: "pr",
    name: "Approve custom upsell request",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: ["listing", "guest", "booking"],
    skip_scheduling_time: 21600000,
    service_account: "pr",
  },
  pending_checkout: {
    service: "pr",
    name: "Approve late-checkout request",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: ["listing", "guest", "booking"],
    skip_scheduling_time: 21600000,
    service_account: "pr",
  },
  refund: {
    service: "pr",
    name: "Refund a purchase",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: ["listing", "guest", "booking"],
    skip_scheduling_time: 21600000,
    service_account: "pr",
  },
  product_details: {
    service: "pr",
    name: "Load product details",
    triggers: [],
    required_args: ["listing", "guest", "booking"],
    skip_scheduling_time: 21600000,
    service_account: "pr",
  },
  deposit_place_hold: {
    service: "pr",
    name: "Place a hold on a deposit",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: ["listing", "guest", "booking", "guest_bill"],
    mock_action_options: {
      sku_id: "deposit_1",
    },
    skip_scheduling_time: 259200000,
    service_account: "pr",
  },
  deposit_cancel_hold: {
    service: "pr",
    name: "Cancels a hold on a deposit",
    triggers: [
      {
        trigger: "bkg",
      },
      {
        trigger: "inq",
      },
      {
        trigger: "cin",
      },
      {
        trigger: "out",
      },
      {
        trigger: "ver",
      },
      {
        trigger: "re_ver",
      },
      {
        trigger: "deny_ver",
      },
      {
        trigger: "device_event",
      },
      {
        trigger: "bkg_cfrmd",
      },
      {
        trigger: "bkg_cncld",
      },
      {
        trigger: "bkg_pndg",
      },
    ],
    required_args: ["listing", "guest", "booking", "guest_bill"],
    skip_scheduling_time: 259200000,
    service_account: "pr",
  },
  purchase: {
    service: "pr",
    name: "Confirms a product purchase",
    triggers: [],
    required_args: [],
    skip_scheduling_time: 21600000,
    service_account: "pr",
  },
  payment_url: {
    service: "pr",
    name: "Returns a link to pay for a specific product",
    triggers: [],
    required_args: [],
    skip_scheduling_time: 21600000,
    service_account: "pr",
  },
  update_bill_status_from_third_party: {
    service: "pr",
    name: "Update Viator bill status for a manual booking",
    triggers: [],
    required_args: [],
    skip_scheduling_time: 21600000,
    service_account: "pr",
  },
  generate_text: {
    service: "cb",
    name: "Generate Text",
    required_args: ["root", "listing", "guest", "booking"],
    triggers: [],
    service_account: "cb",
  },
  predict_response: {
    service: "cb",
    name: "Predict Response",
    required_args: ["root", "message", "guest", "listing", "booking"],
    triggers: [],
    service_account: "cb",
  },
  update_knowledge_base: {
    service: "cb",
    name: "Predict Response",
    required_args: ["root", "message", "guest", "listing", "booking"],
    triggers: [],
    service_account: "cb",
  },
  analyze_message: {
    service: "cb",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "cb",
  },
  create_chatbot_account: {
    service: "cb",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "cb",
  },
  delete_group: {
    service: "groups",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "groups",
  },
  update_group: {
    service: "groups",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "groups",
  },
  write_update_group: {
    service: "groups",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "groups",
  },
  create_enso_user: {
    service: "act",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "act",
  },
  update_user_codes: {
    service: "sl",
    name: "Update User Codes",
    required_args: [],
    triggers: [],
    service_account: "sl",
  },
  update_enso_user: {
    service: "act",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "act",
  },
  delete_enso_user: {
    service: "act",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "act",
  },
  create_enso_account: {
    service: "act",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "act",
  },
  pms_map_listings: {
    service: "pms",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "pms",
  },
  pms_sync_bookings: {
    service: "pms",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "pms",
  },
  pms_sync_inquiries: {
    service: "pms",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "pms",
  },
  pms_sync_listings: {
    service: "pms",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "pms",
  },
  create_pms_account: {
    service: "pms",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "pms",
  },
  update_pms_account: {
    service: "pms",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "pms",
  },
  create_root_bill: {
    service: "billing",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "billing",
  },
  update_guest_bill: {
    service: "billing",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "billing",
  },
  suspend_subscription: {
    service: "billing",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "billing",
  },
  write_billing_usage: {
    service: "billing",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "billing",
  },
  load_object: {
    service: "obj",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "obj",
  },
  loop_action: {
    service: "obj",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "obj",
  },
  trigger_experience: {
    service: "exp",
    name: "Predict Response",
    required_args: [],
    triggers: [],
    service_account: "exp",
  },
  delete_device_account: {
    service: "sl",
    name: "Delete Smart Device Account",
    required_args: [],
    triggers: [],
    service_account: "sl",
  },
  export_guests: {
    service: "bk",
    name: "Export Guests",
    required_args: [],
    triggers: [],
    service_account: "bk",
  },
  reassign_guest_destinations: {
    service: "bk",
    name: "Reassign Guest(s) destinations",
    required_args: [],
    triggers: [],
    service_account: "bk",
  },
  archive_guests: {
    service: "bk",
    name: "Archive Guests",
    required_args: [],
    triggers: [],
    service_account: "bk",
  },
};

export const objectFilter = {
  guest: [
    "guest_updated_at",
    "guest_group_id",
    "assigned_to",
    "guest_channel",
    "response_time",
    "guest_bookings",
    "guest_status",
    "rating",
    "guest_inquiry",
    "guest_country",
    "guest_name",
    "guest_id",
    "guest_tags",
    "guest_stage",
    "guest_destination",
    "guest_language",
    "total_rev",
    "unanswered_messages",
    "unread_messages",
    "num_listings",
    "guest_listing",
    "guest_phone",
    "guest_email",
    "guest_type",
    "message_topics",
    "guest_purchased_product_types",
    "guest_purchased_product_names",
    "enso_total_revenue",
  ],
  booking: [
    "booking_listing_group_id",
    "booking_tags",
    "booking_action",
    "booking_guest_name",
    "booking_created_at",
    "listing_id",
    "booking_listing",
    "booking_channel",
    "booking_status",
    "booking_payment_currency",
    "booking_payout",
    "total_guest_paid",
    "checkin_date",
    "checkout_date",
    "guest_id",
  ],
  listing: [
    "listing_group_id",
    "country",
    "listing_tags",
    "listing_name",
    "listing_id",
    "listing_state",
    "listing_city",
    "listing_channels",
    "listing_type",
    "listing_active",
    "listing_errors",
  ],
  message: [
    "sent_at",
    "assigned_to",
    "question_type",
    "message_channel",
    "message_sender",
    "device_id",
    "notification_type",
    "sentiment",
    "rating",
    "message_stage_tags",
    "messages_guest_id",
    "message_sent_at",
  ],
};

export const suggestedPrompts = {
  guidebook: {
    title:
      "Fill in your prompt for our AI generator to create your guidebook content. The generated content will be automatically added to your input field.",
    prompts: [
      "Write me a guidebook about the garbage policy. Garbage is collected every tuesday",
    ],
  },
  upsell: {
    title:
      "Fill in your prompt for our AI generator to create your upsell content. The generated content will be automatically added to your input field.",
    prompts: ["Write me a description for this upsell"],
  },
  fee: {
    title:
      "Fill in your prompt for our AI generator to create your fee content. The generated content will be automatically added to your input field.",
    prompts: [],
  },
  checkin_step: {
    title:
      "Fill in your prompt for our AI generator to create your check-in step content. The generated content will be automatically added to your input field.",
    prompts: [],
  },
  checkout_step: {
    title:
      "Fill in your prompt for our AI generator to create your checkout step content. The generated content will be automatically added to your input field.",
    prompts: [],
  },
  experience: {
    title:
      "Fill in your prompt for our AI generator to create your experience content. The generated content will be automatically added to your input field.",
    prompts: [],
  },
  agreement: {
    title:
      "Fill in your prompt for our AI generator to create your agreement content. The generated content will be automatically added to your input field.",
    prompts: [],
  },
  refine_chat: {
    title: "Enter instructions for our AI to write or edit your message.",
    prompts: [
      "Translate to French",
      "Rephrase this message politely",
      "Tell this guest to...",
    ],
  },
};

export const deviceList = {
  access: [
    {
      provider: "salto",
      service: "sl",
      device_type: "sl",
      picture: "https://enso-image-assets.s3.amazonaws.com/devices/salto.png",
      text: "Connect locks via your Salto account",
      name: "Salto",
      UI_category: "access",
      enabled: true,
    },
    {
      provider: "yale",
      service: "sl",
      device_type: "sl",
      picture: "https://enso-image-assets.s3.amazonaws.com/devices/yale.png",
      text: "Connect locks via your Yale account",
      name: "Yale",
      UI_category: "access",
      enabled: true,
    },
    {
      provider: "igloo",
      service: "sl",
      device_type: "sl",
      picture: "https://enso-image-assets.s3.amazonaws.com/devices/igloo.png",
      text: "Connect locks via your Igloohome account",
      name: "Igloohome",
      UI_category: "access",
      enabled: true,
    },
    {
      provider: "nuki",
      service: "sl",
      device_type: "sl",
      picture: "https://enso-image-assets.s3.amazonaws.com/devices/nuki.png",
      text: "Connect locks via your Nuki account",
      name: "Nuki",
      UI_category: "access",
      enabled: true,
    },
    {
      provider: "schlage",
      service: "sl",
      device_type: "sl",
      picture: "https://enso-image-assets.s3.amazonaws.com/devices/schlage.png",
      text: "Connect locks via your Schlage account",
      name: "Schlage",
      UI_category: "access",
      enabled: true,
    },
    {
      provider: "august",
      service: "sl",
      device_type: "sl",
      picture: "https://enso-image-assets.s3.amazonaws.com/devices/august.png",
      text: "Connect locks via your August account",
      name: "August",
      UI_category: "access",
      enabled: true,
    },
    {
      provider: "smart",
      service: "sl",
      device_type: "sl",
      picture:
        "https://enso-image-assets.s3.amazonaws.com/devices/smartthings.png",
      text: "Connect locks via your Smartthings account",
      name: "Smartthings",
      UI_category: "access",
      enabled: true,
    },
    {
      provider: "ttlock",
      service: "sl",
      device_type: "sl",
      picture: "https://enso-image-assets.s3.amazonaws.com/devices/ttlock.webp",
      text: "Connect locks via your TTLock account",
      name: "TTLock",
      UI_category: "access",
      enabled: true,
    },
    {
      provider: "kwikset",
      service: "sl",
      device_type: "sl",
      picture:
        "https://enso-image-assets.s3.amazonaws.com/devices/kwikset.webp",
      text: "Connect locks via your Kwikset account",
      name: "Kwikset",
      UI_category: "access",
      enabled: true,
    },
    {
      provider: "4suites",
      service: "sl",
      device_type: "sl",
      picture: "https://enso-image-assets.s3.amazonaws.com/devices/4suites.png",
      text: "Connect locks via your 4Suites account",
      name: "4Suites",
      UI_category: "access",
      enabled: true,
    },
    {
      provider: "wyze",
      service: "sl",
      device_type: "sl",
      picture: "https://enso-image-assets.s3.amazonaws.com/devices/wyze.png",
      text: "Connect locks via your Wyze account",
      name: "Wyze",
      UI_category: "access",
      enabled: true,
    },
    {
      provider: "brivo",
      service: "sl",
      device_type: "sl",
      picture: "https://enso-image-assets.s3.amazonaws.com/devices/brivo.webp",
      text: "Connect locks via your Brivo account",
      name: "Brivo",
      UI_category: "access",
      enabled: true,
    },
    {
      provider: "tedee",
      service: "sl",
      device_type: "sl",
      picture: "https://enso-image-assets.s3.amazonaws.com/devices/tedee.webp",
      text: "Connect locks via your Tedee account",
      name: "Tedee",
      UI_category: "access",
      enabled: true,
    },
    {
      provider: "latch",
      service: "sl",
      device_type: "sl",
      picture: "https://enso-image-assets.s3.amazonaws.com/devices/latch.webp",
      text: "Connect locks via your Latch account",
      name: "Latch",
      UI_category: "access",
      enabled: true,
    },
    {
      provider: "dormakaba_oracode",
      service: "sl",
      device_type: "sl",
      picture:
        "https://enso-image-assets.s3.amazonaws.com/devices/dormakaba_oracode.jpg",
      text: "Connect locks via your Dormakaba Oracode account",
      name: "Dormakaba Oracode",
      UI_category: "access",
      enabled: true,
    },
    {
      provider: "lockly",
      service: "sl",
      device_type: "sl",
      picture: "https://enso-image-assets.s3.amazonaws.com/devices/lockly.webp",
      text: "Connect locks via your Lockly account",
      name: "Lockly",
      UI_category: "access",
      enabled: true,
    },
  ],
  sensor: [
    {
      provider: "minut",
      service: "sl",
      device_type: "ns",
      picture: "https://enso-image-assets.s3.amazonaws.com/devices/minut.png",
      text: "Connect locks via your Minut account",
      name: "Minut",
      UI_category: "sensor",
      enabled: true,
    },
    {
      provider: "noiseaware",
      service: "sl",
      device_type: "ns",
      picture:
        "https://enso-image-assets.s3.amazonaws.com/devices/noiseaware.jpg",
      text: "Connect locks via your NoiseAware account",
      name: "NoiseAware",
      UI_category: "sensor",
      enabled: true,
    },
  ],
};

export const pmsConfig = {
  ab: {
    "24_hr_listing_poll": true,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": false,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": false,
    ab_msg: true,
    conf_deny_booking: true,
    time_window_booking_poll: false,
  },
  avt: {
    "24_hr_listing_poll": true,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": true,
    "2_way_guest_sync": true,
    "2_way_msg_sync": false,
    "5_min_booking_poll": true,
    ab_msg: false,
    conf_deny_booking: true,
    time_window_booking_poll: true,
  },
  b24: {
    "24_hr_listing_poll": false,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": false,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": false,
    ab_msg: false,
    conf_deny_booking: false,
    time_window_booking_poll: false,
  },
  bks: {
    "24_hr_listing_poll": false,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": false,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": false,
    ab_msg: true,
    conf_deny_booking: true,
    time_window_booking_poll: false,
  },
  cb: {
    "24_hr_listing_poll": false,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": false,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": false,
    ab_msg: false,
    conf_deny_booking: false,
    time_window_booking_poll: false,
  },
  gy: {
    "24_hr_listing_poll": false,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": true,
    "2_way_check_time_sync": true,
    "2_way_guest_sync": true,
    "2_way_msg_sync": true,
    "5_min_booking_poll": false,
    ab_msg: true,
    conf_deny_booking: false,
    time_window_booking_poll: false,
  },
  ha: {
    "24_hr_listing_poll": true,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": true,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": false,
    ab_msg: true,
    conf_deny_booking: false,
    time_window_booking_poll: false,
  },
  hfl: {
    "24_hr_listing_poll": false,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": false,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": false,
    ab_msg: false,
    conf_deny_booking: false,
    time_window_booking_poll: false,
  },
  htf: {
    "24_hr_listing_poll": true,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": true,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": false,
    ab_msg: false,
    conf_deny_booking: false,
    time_window_booking_poll: true,
  },
  lg: {
    "24_hr_listing_poll": true,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": false,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": false,
    ab_msg: false,
    conf_deny_booking: false,
    time_window_booking_poll: false,
  },
  lmpm: {
    "24_hr_listing_poll": false,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": false,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": true,
    ab_msg: false,
    conf_deny_booking: false,
    time_window_booking_poll: true,
  },
  lt: {
    "24_hr_listing_poll": false,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": false,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": false,
    ab_msg: false,
    conf_deny_booking: false,
    time_window_booking_poll: false,
  },
  mews: {
    "24_hr_listing_poll": false,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": true,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": false,
    ab_msg: false,
    conf_deny_booking: false,
    time_window_booking_poll: false,
  },
  ownrz: {
    "24_hr_listing_poll": false,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": false,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": false,
    ab_msg: false,
    conf_deny_booking: false,
    time_window_booking_poll: false,
  },
  ru: {
    "24_hr_listing_poll": true,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": false,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": true,
    ab_msg: false,
    conf_deny_booking: false,
    time_window_booking_poll: true,
  },
  st: {
    "24_hr_listing_poll": true,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": false,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": true,
    ab_msg: false,
    conf_deny_booking: false,
    time_window_booking_poll: true,
  },
  stay: {
    "24_hr_listing_poll": false,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": false,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": false,
    ab_msg: false,
    conf_deny_booking: false,
    time_window_booking_poll: false,
  },
  trk: {
    "24_hr_listing_poll": true,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": true,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": true,
    ab_msg: false,
    conf_deny_booking: false,
    time_window_booking_poll: false,
  },
  v365: {
    "24_hr_listing_poll": true,
    "2_way_booking_tag_sync": false,
    "2_way_cf_sync": false,
    "2_way_check_time_sync": false,
    "2_way_guest_sync": false,
    "2_way_msg_sync": false,
    "5_min_booking_poll": true,
    ab_msg: false,
    conf_deny_booking: true,
    time_window_booking_poll: false,
  },
};
